:root {
    --app-height: 100%;
}

.invalid-icon{
    background-image: url("data:image/svg+xml,%0A%3Csvg width='40' height='38' viewBox='0 0 40 38' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M30 37.0892L40 18.9939L30 0.898682H10L0 18.9939L10 37.0892H30ZM17.1429 8.51773H22.8571L21.9048 19.9463H18.0952L17.1429 8.51773ZM22.8572 26.6129C22.8572 28.1908 21.578 29.47 20 29.47C18.4221 29.47 17.1429 28.1908 17.1429 26.6129C17.1429 25.0349 18.4221 23.7557 20 23.7557C21.578 23.7557 22.8572 25.0349 22.8572 26.6129Z' fill='%23FC3653'/%3E%3C/svg%3E%0A");
    width: 40px;
    height: 38px;
    display: inline-block;
}

.successfully-icon{
    background-image: url("data:image/svg+xml,%0A%3Csvg width='36' height='37' viewBox='0 0 36 37' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18 36.8987C27.9411 36.8987 36 28.8398 36 18.8987C36 15.1813 34.8731 11.7272 32.9424 8.85918L17.3054 25.278L15.9245 26.7279L14.4762 25.3454L7.61905 18.7999L10.381 15.9065L15.7897 21.0695L30.3387 5.79303C27.1166 2.75836 22.7755 0.898682 18 0.898682C8.05887 0.898682 0 8.95756 0 18.8987C0 28.8398 8.05887 36.8987 18 36.8987Z' fill='%233C8A65'/%3E%3C/svg%3E%0A");
    width: 36px;
    height: 37px;
    display: inline-block;
}
  
.onboarder{
    &-main{
        background: $darkBlue;
        width: 100%;
        display: flex;
        padding: 0 0 30px;
        flex-direction: column;
        min-height: var(--app-height);
        
        .container{
            max-width: 1244px;
            padding: 0 22px;
            margin: 0 auto;
        }
    }
    &-head{
        padding: 45px 0 15px;
        &_logo{
            display: table;
            margin: 0 auto;
            img{
                min-width: calcRem(150px);
                @media(max-width:479px){
                    min-width: max-content;
                }
            }
        }
    }
    &-footer{
        padding: 50px 0 20px;
        .container{
            max-width: 700px;
        }
        ul{
            display: flex;
            justify-content: space-between;
        }
        a{
            font-size: calcRem(16px);
            color: #FEFEFE;
            text-decoration: underline;
            &:hover{
                text-decoration: none;
            }
        }
    }
    &-title{
        margin: calcRem(15px) 0;
        font-size: calcRem(18px);
        text-align: center;
        color: #FEFEFE;
    }
    &-form{
        padding: 50px 40px;
        margin: 0 auto;
        max-width: calcRem(500px);
        background: #FCFDFE;
        border-radius: 20px;
        &.login-form{
            padding: 84px 56px 50px;
            color:$baseColor;
            .login-form-server-error{
                font-size: 16px;
                padding-right: 15px;
                line-height: 1.6;
                margin-top: -50px;
                margin-bottom: 50px;
            }
            .invalid-icon{
                margin-bottom: 15px;
            }
            form{
                max-width: none;
                .btn-model{
                    margin-top: 10px;
                    min-width: 205px;
                    margin-bottom: 65px;
                    border-width: 3px;
                }
            }
            .login-form-error{
                padding: 15px 0;
                min-height: auto;
            }
            .input-wrap{
                position: relative;
                .password-view{
                    .rs-icon{
                        &::before{
                            content: '\f070';
                        }
                    }
                }
            }
        }
        .rs-input[disabled]{
            background: #FCFDFE;
            border-color: transparent;
        }
        .input-wrap{
            margin-bottom: calcRem(45px);
            .rs-form-control-message-wrapper{
                width: 100%;
            }
            .anim-placeholder.focus.error label,
            input::-webkit-input-placeholder{
                opacity: 1!important;
            }
            input:focus{
                box-shadow: none;
            }
            // .rs-form-error-message-wrapper,& .rs-form-error-message{
            //     position: static!important;
            // }
            &.input-password{
                margin-bottom: 90px;
            }
        }
        .checkbox-wrapper{
            font-size: 16px;
            cursor: pointer;
            padding-left: 0;
            .rs-checkbox-wrapper{
                top:3px;
                left: 2px;
            }
            .rs-checkbox-wrapper::before, .rs-checkbox-wrapper .rs-checkbox-inner::before{
                width: 18px;
                height: 18px;
            }
            .rs-checkbox-wrapper .rs-checkbox-inner::after{
                width: 7px;
                height: 11px;
            }
        }
        
        .rs-form-control-wrapper > .rs-input-number,
        .rs-form-control-wrapper > .rs-input,
        .rs-form-vertical .rs-form-group .rs-form-control-wrapper{
            display: block;
            width: 100%;
        }
        .btn-model{
            display: table;
            margin: 40px auto 20px;
        }
        .invalid-text{
            margin: 24px auto 30px;
            text-align: center;
            p{
                font-size: 16px;
            }
            a{
                color: #325F97;
                text-decoration: underline;
                &:hover{
                    text-decoration: none;
                }
            }
        }
        .invalid-title{
            text-align: center;
            font-size: 12px;
            font-weight: 600;
            margin-bottom: 20px;
        }
        .invalid-icon, .successfully-icon{
            margin: 10px auto 25px;
            display: table;
        }
        .form-onboarding-uploader{
            margin: 0;
            padding: 16px;
            border: 2px dashed #325F97;
            width: auto;
            box-shadow: 0px 0px 10px 4px rgba(36, 47, 80, 0.03);
            border-radius: 4px;
            transition: background .15s ease-out;
            text-align: center;
            .rs-uploader-text .rs-uploader-file-item-status{
                display: none;
            }
            .rs-uploader-picture {
                width: 360px;
                .rs-uploader-trigger{
                    width: 100%;
                    &-btn{
                        width: 100%;
                        &::after{
                            border-radius: 4px;
                        }
                    }
                }
                .rs-uploader-file-item{
                    width: 100%;
                    margin-right: 0;
                    img{
                        object-fit: contain;
                    }
                }
            }
            .rs-uploader-text .rs-uploader-file-item-has-error .rs-uploader-file-item-status{
                font-size: 16px;
                line-height: 1.4;
                display: block;
                pointer-events: none;
                text-decoration: none;
                text-transform: none;
                color: #FC3653;
                font-family: 'Poppins', sans-serif;
                position: absolute;
                right: 0%;
                width: 60%;
                top:50%;
                transform: translateY(-50%);
                a::after{
                    display: none;
                }
                span{
                    color: #FC3653;
                    text-transform: lowercase;
                    display: block;
                }
            }
            .rs-uploader-text .rs-uploader-file-item-btn-remove::before{
                width: 32px;
                height: 41px;
                display: inline-block;
                font-size: 0;
                vertical-align: middle;
                position: relative;
                top:-2px;
                background-image: url("data:image/svg+xml,%0A%3Csvg width='32' height='41' viewBox='0 0 32 41' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 28.3987C8 30.6078 9.79086 32.3987 12 32.3987H20C22.2091 32.3987 24 30.6078 24 28.3987V17.5415C24 15.3324 22.2091 13.5415 20 13.5415H16H12C9.79086 13.5415 8 15.3324 8 17.5415L8 28.3987Z' fill='%23325F97'/%3E%3Cpath d='M10.9092 28.2429C10.9092 28.6445 11.2348 28.9701 11.6365 28.9701C12.0381 28.9701 12.3637 28.6445 12.3637 28.2429V19.4117C12.3637 19.01 12.0381 18.6844 11.6365 18.6844C11.2348 18.6844 10.9092 19.01 10.9092 19.4117V28.2429Z' fill='%23FEFEFE'/%3E%3Cpath d='M15.2737 28.2429C15.2737 28.6445 15.5993 28.9701 16.001 28.9701C16.4026 28.9701 16.7282 28.6445 16.7282 28.2429V19.4117C16.7282 19.01 16.4026 18.6844 16.001 18.6844C15.5993 18.6844 15.2737 19.01 15.2737 19.4117V28.2429Z' fill='%23FEFEFE'/%3E%3Cpath d='M19.6355 28.2429C19.6355 28.6445 19.9611 28.9701 20.3628 28.9701C20.7644 28.9701 21.09 28.6445 21.09 28.2429V19.4117C21.09 19.01 20.7644 18.6844 20.3628 18.6844C19.9611 18.6844 19.6355 19.01 19.6355 19.4117V28.2429Z' fill='%23FEFEFE'/%3E%3Cpath d='M21.0908 10.3987C21.0908 9.29411 20.1954 8.39868 19.0908 8.39868H12.909C11.8044 8.39868 10.909 9.29411 10.909 10.3987V11.8273H21.0908V10.3987Z' fill='%23325F97'/%3E%3C/svg%3E%0A");
            }
            .rs-uploader-text .rs-uploader-file-item-has-error{
                color: #FC3653;
            }
            .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:hover{
                background: none;
            }
            &:hover{
                background: #e5e5ea;
            }
            .rs-uploader-text .rs-uploader-file-item-btn-remove::after{
                content: "delete file";
                font-size: 16px;
                line-height: 1.4;
                letter-spacing: 0.04em;
                text-decoration-line: underline;
                color: #325F97;
                font-family: 'Poppins', sans-serif;
            }
            .rs-uploader-text .rs-uploader-file-item-has-error {
                .rs-uploader-file-item-btn-remove::before{
                    display: none;
                }
                .rs-uploader-file-item-btn-remove{
                    position: absolute;
                    right: 0%;
                    top:50%;
                    transform: translateY(-50%);
                    &::after{
                        content: "retry upload";
                        color: #325F97;
                        font-size: 16px;
                        text-decoration: underline;
                    }
                }
            }
            .rs-uploader-trigger-btn{
                padding: 16px;
                min-height: 114px;
                color: #242F50;
                font-size: 12px;
                text-align: center;
                background: none!important;
                span{
                    color: #242F50;
                }
                &:hover{
                    background: none!important;
                }
                .icon-download{
                    background-image: url("data:image/svg+xml,%0A%3Csvg width='88' height='38' viewBox='0 0 88 38' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M44.2884 0.545128C44.0931 0.349867 43.7765 0.349867 43.5813 0.545128L40.3993 3.72711C40.204 3.92237 40.204 4.23895 40.3993 4.43422C40.5945 4.62948 40.9111 4.62948 41.1064 4.43422L43.9348 1.60579L46.7632 4.43422C46.9585 4.62948 47.2751 4.62948 47.4703 4.43422C47.6656 4.23895 47.6656 3.92237 47.4703 3.72711L44.2884 0.545128ZM44.4348 29.8987L44.4348 0.898682L43.4348 0.898682L43.4348 29.8987L44.4348 29.8987Z' fill='%23325F97'/%3E%3Cpath d='M1.3313 21.8987V32.8987C1.3313 35.1078 3.12216 36.8987 5.3313 36.8987H82.5384C84.7475 36.8987 86.5384 35.1078 86.5384 32.8987V21.8987' stroke='%23325F97'/%3E%3C/svg%3E%0A");
                    width: 88px;
                    height: 38px;
                    display: table;
                    margin: 0 auto 15px;
                }
                &:after{
                    width: 360px;
                }
            }
            .rs-uploader-text .rs-uploader-file-item-has-error .rs-uploader-file-item-status{
                pointer-events: none;
            }
            .rs-uploader-text .rs-uploader-file-item .rs-uploader-file-item-status a:after{
                content: "retry upload";
                color: #325F97;
                font-size: 16px;
                text-decoration: underline;
            }
            .rs-uploader-text .rs-uploader-file-item{
                background: none;
                border:0;
            }
            .rs-uploader-text .rs-uploader-file-item-has-error .rs-uploader-file-item-content,
            .rs-uploader-text .rs-uploader-file-item-has-error .rs-uploader-file-item-title{
                color: #FC3653;
            }
            .rs-uploader-text .rs-uploader-file-item{
                .rs-uploader-file-item-content{
                    flex-direction: row;
                    justify-content: space-between;
                    .rs-uploader-file-item-title{
                        width: auto;
                        flex:none;
                    }
                }
                .rs-uploader-file-item-status a{
                    padding-top: 0;
                }
            }
        }
        &_org{
            max-width: 1018px;
            .rs-btn-toolbar{
                justify-content: center;
                margin-top: 30px;
                margin-bottom: 30px;
                .btn-model{
                    margin-left: 0;
                    margin-right: 0px;
                    border-width: 1px;
                    &.btn-close{
                        margin-right: 20px;
                    }
                }
                & > :not(:first-child):not(.rs-btn-block){
                    margin-left: 20px;
                }
            }
        }
    }
}


.onboarder-main{
    .form-text{
        margin-bottom: 60px;
        font-size: 12px;
        color:rgba(#1C243C,0.75);
        line-height: 1.4;
        font-family: 'Poppins', sans-serif;
        a{
            text-decoration: underline;
            color: rgba(#325F97,0.75);
            &:hover{
                text-decoration: none;
            }
        }
    }
    .form-row-link{
        a{
            text-decoration: underline;
            color:#325F97;
            &:hover{
                text-decoration: none;
            }
        }
        a + a{
            &::before{
                content: "•";
                margin: 0 25px;
                font-size: 16px;
                color:#1C243C;
                display: inline-block;
                vertical-align: middle;
            }
        }
    }
    .loader-block {
        position: fixed;
        top:0;
        left: 0;
        width: 100%;
        z-index: 9;
        height: 100%;
        margin: 0;
        background-color: #F2F6FE;
        .rs-loader-wrapper,.rs-loader{
            width: 63px;
            height: 63px;
            margin: 0 auto 15px;
        }
        .rs-loader{
            margin-bottom: 0;
        }
        .rs-loader-spin{
            width: 63px;
            height: 63px;
        }
        .rs-loader-spin::after{
            border:0;
            animation: loaderSpin 1s infinite linear;
        }
        .rs-loader-spin::before{
            border:0;
        }
        .rs-loader .rs-loader-spin::after{
            width: 63px;
            height: 63px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='63' height='62' viewBox='0 0 63 62' fill='none'%3E%3Ccircle cx='31.5' cy='30.7365' r='21' transform='rotate(-33.7065 31.5 30.7365)' stroke='%2397ADE4' stroke-width='2' stroke-linecap='round' stroke-dasharray='100 75' style='stroke-dasharray: 16;'/%3E%3C/svg%3E");
            @media(min-width:$macBookPro){
                width: 93px;
                height: 93px;
                background-size: 93px 92px;
            }
        }
    }
}

.forgot-form{
    padding:50px 56px;
    > .title{
        margin-bottom: 36px;
        font-size: 16px;
        color: #1C243C;
        font-family: 'Poppins', sans-serif;
    }
    .input-wrap {
        margin-bottom: 30px;
    }
    .rs-btn-model{
        min-width: 303px;
        margin-top: 0px;
        width: 100%;
        margin-bottom: 50px;
    }
    .invalid-text{
        margin-bottom: 50px;
        font-size: 16px;
    }
}

.forgot-password-form .btn-model{
    margin: 70px auto 40px;
}
.forgot-password-form{
    padding-top: 50px;
    .input-wrap{
        margin-bottom: 35px;
    }
    .btn-model{
        margin: 70px auto 60px;
    }
    .password-valid-list{
        li{
            flex:0 0 50%;
            max-width: 50%;
        }
    }
}

.patient-panel{
    .btn-edit,.btn-remove{
        overflow: visible;
        font-size: calcRem(14px);
    }
    .btn-edit + .btn-remove{
        position: absolute;
        top:60px;
        right: 16px;
        &::after{
            content: "OR";
            font-size: calcRem(12px);
            right: 8px;
            top: -13px;
            left: auto;
            height: auto;
            line-height: 18px;
            color: #8C919D;
            width: auto;
            background: none;
        }
    }
}


.pageInvalid{
    padding: 75px 40px;
    display: flex;
    align-items: center;
    height: 100%;
    flex-direction: column;
    background: #FCFDFE;
    border-radius: 16px;
    .title{
        margin-bottom: 50px;
        font-weight: 600;
        font-size: 24px;
        line-height: 1.8;
        color: #1C243C;
    }
    .text{
        font-size: 16px;
        line-height: 1.4;
        text-align: center;
        color: #1C243C;
        margin-bottom: 80px;
    }
}