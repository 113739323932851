$browser-size: 18px; // Default

@function calcRem($pixels, $context: $browser-size) {
  $remSize: calc($pixels / $context);
  @return #{$remSize}rem;
}

$black: #000;
$blackBlue:#040508;
$white: #fefefe;
$baseColor: #1c243c;
$red: #fc3653;
$blackHead: #1c243c;
$lightBlue: #325f97;
$green: #3c8a65;
$darkBlue: #242f50;
$lightGray: #f2f6fe;
$ligthRed: #fd5e79;
$error: rgba(194, 0, 43, 1);

$fontNormal: 400;
$fontMedium: 500;
$fontBold: 600;

$brs: 4px;
$lts: 0.04em;
$trs: 0.3s;

$desktop: 1024px;
$macBook15: 2150px;
$macBookPro: 2400px; 