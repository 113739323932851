.billing {
  &-content {
    display: flex;
    flex: 1 1;
    flex-wrap: wrap;
    margin-bottom: -12px;
    padding-right: 30px;
  }
  &-panel {
    flex: 100%;
    height: 100%;
    &_wrap {
      background: #fcfdfe;
      padding: 0 0 20px;
      position: relative;
      height: 100%;
      .btn-view {
        font-weight: 500;
        font-size: calcRem(12px);
        line-height: 1.2;
        text-decoration-line: underline;
        color: #325f97;
        @media(min-width:$macBookPro){
          font-size: calcRem(14px);
        }
        &:hover{
          text-decoration: none;
        }
        &::before{
          content: "";
          width: 24px;
          height: 24px;
          background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.2159 4.03854C13.0578 3.85712 14.9056 4.31999 16.4446 5.34826C17.9835 6.37653 19.1183 7.90659 19.6555 9.67774C20.1928 11.4489 20.0993 13.3515 19.391 15.0615C18.6828 16.7714 17.4035 18.1829 15.7712 19.0554C14.1389 19.9279 12.2546 20.2074 10.4393 19.8463C8.624 19.4852 6.99008 18.5059 5.81592 17.0752C4.64175 15.6444 4 13.8509 4 12H2C2 14.3136 2.80219 16.5555 4.2699 18.3439C5.7376 20.1323 7.78 21.3565 10.0491 21.8079C12.3182 22.2592 14.6736 21.9098 16.714 20.8192C18.7543 19.7286 20.3534 17.9643 21.2388 15.8268C22.1242 13.6894 22.241 11.3111 21.5694 9.09717C20.8978 6.88324 19.4794 4.97066 17.5557 3.68532C15.6321 2.39998 13.3222 1.8214 11.0198 2.04817C8.7808 2.26869 6.6835 3.23756 5.06521 4.79524L5.06294 4.79297L4.42796 5.42796L2 3V9H8L5.84218 6.84218L6.34216 6.34218L6.34315 6.34316C7.65189 5.03442 9.37393 4.21995 11.2159 4.03854ZM13 6H11V13H18V11H13V6Z' fill='%23325F97'/%3E%3C/svg%3E%0A");
          display: inline-block;
          vertical-align: middle;
          margin-right: 8px;
        }
      }
      .payment-row{
        margin-bottom: 25px;
        padding-bottom: 25px;
        border-bottom: 1px solid #DDDFE4;
        .col{
          max-width: 322px;
          flex:1;
          & + .col{
            margin-left: 22px;
          }
          .title{
            color:rgba(#040508,0.5);
            margin-bottom: 8px;
            font-size: calcRem(16px);
          }
        }
      }
      .charges {
        overflow-y: auto;
        max-height: calc(100% - 40px);
        .item {
          display: flex;
          flex-wrap: wrap;
          font-size: calcRem(16px);
          & + .item {
            margin-top: 20px;
          }
          .cost {
            margin-bottom: 8px;
            b {
              margin-right: 8px;
              font-weight: 600;
              font-size: 20px;
            }
            .old-price {
              text-decoration: line-through;
            }
          }
          .cost,
          .date {
            flex: 0 0 100%;
          }
        }
      }
      .title {
        font-weight: 500;
        font-size: calcRem(20px);
        line-height: 1.4;
        margin-bottom: calcRem(24px);
        color: $baseColor;
      }
      .btn-edit {
        position: absolute;
        top: 10px;
        right: 0;
        font-weight: 500;
        font-size: calcRem(12px);
        @media(min-width:$macBookPro){
          font-size: calcRem(14px);
        }
        .icon-edit {
          background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22.5853 7.22117C23.3664 6.44012 23.3664 5.17377 22.5853 4.39272L19.6069 1.41426C18.8259 0.6332 17.5596 0.633199 16.7785 1.41426L15.4055 2.78735L21.2123 8.59427L22.5853 7.22117Z' fill='%23325F97'/%3E%3Cpath d='M18.8685 10.9374C19.6496 10.1564 19.6496 8.89002 18.8685 8.10896L15.8901 5.1305C15.1091 4.34945 13.8428 4.34945 13.0617 5.1305L4.74108 13.4513C3.96004 14.2324 3.96004 15.4987 4.74108 16.2798L6.23027 17.769L7.71947 19.2583C8.50051 20.0393 9.76683 20.0393 10.5479 19.2583L18.8685 10.9374Z' fill='%23325F97'/%3E%3Cpath d='M3.62143 21.4286C3.02933 21.459 2.54089 20.9706 2.57137 20.3785L2.76412 16.6329C2.77302 16.4599 2.98276 16.3793 3.10527 16.5018L7.49803 20.8946C7.62054 21.0172 7.53992 21.2269 7.36689 21.2358L3.62143 21.4286Z' fill='%23325F97'/%3E%3C/svg%3E%0A");
          width: 24px;
          height: 24px;
          display: inline-block;
          vertical-align: middle;
          margin-right: 5px;
        }
      }
      .period {
        font-size: calcRem(16px);
        margin-bottom: 24px;
        .title {
          font-size: calcRem(12px);
          margin-bottom: 8px;
        }
      }
      .payer {
        .item {
          display: flex;
          align-items: center;
          & + .item {
            margin-top: 16px;
          }
        }
        &-info {
          font-size: calcRem(16px);
          color:#1C243C;
          p {
            margin: 0;
          }
          .card {
            font-size: calcRem(16px);
            font-weight: 600;
            color:#1C243C;
          }
        }
        &-logo {
          min-width: 60px;
          min-height: 40px;
          background: #E9EAED;
          border-radius: 4px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 24px;
          padding: 2px;
          img{
            max-width: 56px;
          }
        }
      }
      .contacts {
        .item {
          display: flex;
          align-items: center;
        }
        &-info {
          .name {
            font-weight: 600;
            font-size: calcRem(12px);
            line-height: 1.4;
            margin-bottom: 8px;
          }
          .email {
            color: $baseColor;
            font-size: calcRem(16px);
            line-height: 1.4;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        &-logo {
          color: #1c243c;
          margin-right: 24px;
          border-radius: 50%;
          font-size: calcRem(12px);
          font-weight: 600;
          padding: 0 10px;
          overflow: hidden;
          display: flex;
          align-items: center;
          text-transform: uppercase;
          width: 40px;
          height: 40px;
          flex: 0 0 40px;
          background: #fcfdfe;
          border: 2px solid #242f50;
          box-shadow: 0px 0px 10px 4px rgba(36, 47, 80, 0.06);
        }
      }
      .history-list {
        overflow: hidden;
        max-height: calc(100% - 85px);
        padding-bottom: 10px;
        &_item {
          display: flex;
          justify-content: space-between;
          font-size: calcRem(16px);
          color: $baseColor;
          flex-wrap: wrap;
          .free-trial {
            flex: 0 0 100%;
            text-align: right;
            padding-bottom: 16px;
          }
          & + .history-list_item {
            margin-top: 8px;
          }
        }
      }
    }
  }
}

.history-modal {
  .btn-close {
    top: 16px;
    left: 16px;
    position: absolute;
    font-size: calcRem(16px);
    .rs-icon {
      font-size: 18px;
      margin-right: 6px;
    }
  }
  .rs-modal-content {
    padding-top: 68px;
  }
  .rs-modal-header {
    background: #f2f6fe;
    border-radius: 4px;
    color: #1c243c;
    border: 0;
    margin: 0 16px 16px;
    .rs-modal-title {
      line-height: 1.4;
      font-weight: 500;
      font-size: 16px;
    }
  }
  .rs-modal-body {
    padding-top: 0;
  }
  &_list {
    max-height: 370px;
    overflow-x: auto;
    .item {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      font-size: 16px;
      padding: 0 45px 0 8px;
      & + .item {
        margin-top: 10px;
      }
      .card-ending {
        font-size: 12px;
        flex: 0 0 100%;
        padding: 8px 0 0 16px;
        max-width: 100%;
      }
    }
  }
}

.chargebee {
  margin-top: -71px;
  @media (max-width: 1190px) {
    > .row {
      flex-wrap: wrap;
    }
  }
  .btn {
    height: 56px;
    color: #fff;
    width: 100%;
    padding:16px;
    line-height: 24px;
    overflow: hidden;
    white-space: nowrap;
    background: #242F4F;
    border-radius: 46px;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    text-transform: capitalize;
    transition: opacity 0.3s ease-in;
    &:hover {
      opacity: 0.7;
    }
    &-white {
      background: #f2f2f4;
      color: #040508;
    }
    @media (max-width: 1630px) {
      font-size: 18px;
      height: 61px;
      padding: 15px;
      line-height: 31px;
    }
  }
  ul + .btn{
    margin-top: 6px;
  }
  &-access-plan{
    padding-bottom: 28px;
    font-size: 16px;
    margin-bottom: 28px;
    border-bottom: 1px solid #DDDFE4;
    .expired{
      font-size: 24px;
      color:#C2002B;
      font-weight: 600;
    }
    b{
      font-size: 24px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 7px;
    }
  }
  &-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-right: 30px;
    h3 {
      margin-bottom: 24px;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.2;
    }
    @media (max-width: 1279px) {
      padding-right: 20px;
    }
  }
  &-promocode {
    max-width: 500px;
    padding: 90px 16px 0;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    .rs-form-control-wrapper > .rs-input {
      width: 100%;
    }
    .title,
    .price {
      font-size: 36px;
      line-height: 1;
      color: #1c243c;
      margin-bottom: 20px;
    }
    .price {
      font-weight: 700;
      margin-bottom: 60px;
    }
    .iframe {
      margin-bottom: 45px;
    }
  }
  &-plan {
    .row{
      margin: 0 -12px;
      @media (max-width: 1279px) {
        flex-wrap: wrap;
      }
    }
    .col {
      flex: 0 0 50%;
      max-width: 50%;
      display: flex;
      flex-direction: column;
      padding: 0 12px;
      @media (max-width: 1279px) {
        flex:0 0 100%;
        max-width: 100%;
      }
    }
    &_col {
      padding: 32px 24px 24px;
      font-size: 16px;
      color: #1C243C;
      background: #FEFEFE;
      border: 1px solid #DDDFE4;
      border-radius: 4px;
      height: 100%;
      @media (max-width: 1630px) {
        padding: 24px;
      }
      @media (max-width: 1279px) {
        &:first-child{
          margin-bottom: 24px;
        }
      }
      &.dark {
        background: #E9EBED;
        border: 1px solid #E9EBED;
        flex: 1;
      }
      p {
        margin-bottom: 16px;
      }
      .title {
        font-weight: 600;
        font-size: 24px;
        line-height: 1.2;
        margin-bottom: 32px;
        color: #040508;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .chargebee-plan_price {
          color: #1C243C;
          font-size: 16px;
          font-weight: 400;
          span{
            font-weight: 500;
            color: #1C243C;
          }
        }
        @media (max-width: 1630px) {
          font-size: 22px;
        }
        @media (max-width: 1279px) {
          font-size: 20px;
          margin-bottom: 24px;
        }
        span {
          font-weight: 500;
          font-size: 40px;
          line-height: 1.2;
          color: rgba(#1C243C,0.5);
          @media (max-width: 1440px) {
            font-size: 32px;
          }
        }
      }
      ul {
        li {
          padding: 0 0 0 40px;
          position: relative;
          margin-bottom: 20px;
          
          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: -2px;
            width: 24px;
            height: 24px;
            background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='10' fill='%2326292F'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.4141 9.00015L12.0606 15.3537C11.4748 15.9395 10.525 15.9395 9.93925 15.3537L6.58569 12.0002L7.99991 10.5859L10.9999 13.5859L16.9999 7.58594L18.4141 9.00015Z' fill='%23E9EBED'/%3E%3C/svg%3E%0A");
          }
          & + li {
            margin-top: 12px;
            @media (max-width: 1370px) {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
  &-sidebar {
    max-width: calcRem(342px);
    margin-left: 18px;
    flex: 1;
    background: #FEFEFE;
    border: 1px solid #DDDFE4;
    border-radius: 4px;
    padding: 24px;
    height: 100%;
    a{
      color:#325F97;
      text-decoration: underline;
      &:hover{
        text-decoration: none;
      }
    }
    .title{
      font-weight: 500;
      font-size: calcRem(20px);
      margin-bottom: calcRem(8px);
      line-height: 1.4;
      color: #040508;
    }
    @media (max-width: 1630px) {
      max-width: 360px;
    }
    @media (max-width: 1370px) {
      max-width: 280px;
    }
  }
  > .row {
    display: flex;
  }
  &-comment {
    margin-top: 24px;
    padding-top: 32px;
    border-top: 1px solid #DDDFE4;
    .rs-btn{
      width: 100%;
      font-size: calcRem(16px);
    }
    .title {
      font-weight: 500;
      font-size: calcRem(16px);
      line-height: 1.4;
      color: #7C8295;
      margin-bottom: calcRem(8px);
    }
    textarea {
      height: 152px;
      padding: 16px 8px;
      display: block;
      border: 1px solid #DDDFE4;
      border-radius: 4px;
      width: 100%;
      font-size:calcRem(16px);
      margin-bottom: calcRem(16px);
      @include placeholder {
        font-size: calcRem(16px);
        color: rgba(#1C243C, 0.5);
      }
    }
  }
  &-unlimited {
    padding: 0 50px 0 16px;
    display: flex;
    flex-direction: column;
    .btn {
      max-width: 444px;
    }
    h2 {
      text-align: left;
      margin-bottom: 60px;
    }
    .powered {
      font-size: calcRem(18px);
      margin-left: auto;
      p {
        margin-bottom: 14px;
      }
    }
  }
}
.chargebee-infoModel-hint {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  .modal {
    max-width: 826px;
    width: 100%;
    background: #fcfdfe;
    border: 9px solid #242f50;
    box-sizing: border-box;
    border-radius: 16px;
    padding: 110px 30px;
    position: relative;
    font-size: 36px;
    text-align: center;
    @media (max-width: 1630px) {
      font-size: 24px;
    }
    p {
      margin-bottom: 0;
    }
    .btn-close {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 30px;
      display: flex;
      align-items: center;
      padding: 0;
      justify-content: center;
      height: 30px;
      .rs-icon {
        line-height: 30px;
        font-size: 30px;
      }
    }
  }
}
