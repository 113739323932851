.dashboard {
  display: flex;
  flex-direction: column;
  height: 100%;
  // max-height: calc(100vh - 170px);
  min-height: 600px;
  canvas {
    max-height: 150px;
  }
  h2 {
    margin-bottom: 24px;
    margin-top: 0;
    font: 500 calcRem(20px) 'Poppins';
    line-height: 28px;
  }
  > .row {
    max-height: calc(100% - 96px);
    flex: 1;
    @media (max-width: 1379px) {
      flex-direction: column;
      height: calc(100% - 170px);
    }
  }
  @media (max-width: 1379px) {
    max-height: max-content;
  }
  .input-search {
    margin: 0 30px 0 0;
    max-width: calcRem(733px);
    @media (max-width: 1023px) {
      max-width: 100%;
      flex: 0 0 100%;
      margin-bottom: 20px;
    }
  }
  .bulletin-panel {
    flex: 1;
  }
  .table-nav {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    border-bottom: 1px solid #e9eaed;
  }
  .activity-panel {
    margin-bottom: 0;
    flex: 1;
  }
  .sr-data-panel {
    flex: 1;
    display: flex;
    flex-direction: column;
    .number {
      color: #1c243c;
      font-size: 64px;
      font-weight: 400;
    }
    .rs-panel-body {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .task-panel {
    margin-bottom: 0;
  }
  .rs-panel-header {
    font-size: 16px;
    @media (min-width: $desktop) {
      font-size: 20px;
    }
  }
  &-list {
    height: calc(100vh - 344px);
    overflow-y: auto;
    padding-right: 6px;
    @media (max-height: 750px) {
      height: 555px;
    }
    &_item {
      font-size: 14px;
      color: #1c243c;
      display: flex;
      padding-top: 16px;
      padding-bottom: 16px;
      box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.12);
      @media (min-width: $desktop) {
        font-size: calcRem(16px);
      }
      a {
        color: #325f97;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
      .icon {
        margin-right: 12px;
        margin-top: 0;
        width: 24px;
        height: 24px;
        flex: 0 0 24px;
        position: relative;
        top: -1px;
        background: url(../assets/svg/sprite-stete-dashboard.svg);
        &-comment {
          background-position: 0 0;
        }
        &-cancelled {
          background-position: 0 -48px;
        }
        &-file {
          background-position: 0 -24px;
        }
        &-maintenance {
          background-position: 0 -96px;
        }
        &-blog {
          background-position: 0 -72px;
        }
      }
    }
  }
  .rs-panel {
    display: flex;
    flex-direction: column;
  }
  &-header {
    align-items: center;
    margin: 0 0 28px;
    @media (max-width: 1023px) {
      flex-wrap: wrap;
      .rs-btn {
        margin-left: auto;
      }
    }
  }
  &-content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  &-sidebar {
    flex: 0 0 342px;
    max-width: 342px;
    .rs-panel-body {
      padding-top: 0;
    }
    .rs-panel-header {
      padding-bottom: 10px;
      padding-top: 24px;
    }
    .rs-panel {
      box-shadow: none;
    }
    @media (max-width: 1379px) {
      flex: 1;
      max-width: 100%;
      margin-top: 20px;
      .dashboard-list {
        height: 300px;
      }
    }
  }
  &-nav-row {
    flex: 1;
    margin: 0 -20px;
    @media(max-width:1600px){
      margin: 0 -10px;
    }
    @media(max-width:1279px){
      margin: 0 -5px;
    }
    @media(max-width:939px){
      flex-wrap: wrap;
    }
  }
  .search-dropdown {
    min-height: 92px;
  }
  &-nav {
    display: flex;
    margin-bottom: 24px;
    width: 100%;
    &_col {
      color: #242F50;
      padding: 0 20px;
      flex: 1;
      &:first-child {
        min-width: 260px;
      }
      .wrap{
        padding: 32px 26px;
        display: flex;
        flex-direction: column;
        min-height: 216px;
        align-items: center;
        font-weight: 700;
        width: 100%;
        justify-content: space-between;
        background: #FFFFFF;
        border-radius: 20px;
      }
      .title {
        font-size: calcRem(14px);
        line-height: 1.5;
        text-align: center;
        letter-spacing: -0.1px;
      }
      .num {
        text-align: center;
        padding: 41px 10px 10px;
        font-size: 36px;
      }
      @media (min-width: $macBookPro) {
        .title {
          font-size: calcRem(18px);
        }
      }
      @media (max-width: 1600px) {
        padding: 0px 10px;
        .num{
          padding-top: 31px;
        }
        .wrap{
          padding: 20px 7px;
          min-height: 200px;
        }
      }
      @media (max-width: 1500px) {
        flex: 1;
      }
      @media (max-width: 1279px) {
        padding: 0px 5px;
      }
      @media (max-width: 1023px) {
        .title {
          font-size: 14px;
        }
      }
      @media(max-width:767px){
        flex:0 0 50%;
        max-width: 50%;
        margin-bottom: 15px;
        .num{
          padding: 5px 10px;
        }
        .wrap{
          min-height: 130px;
        }
      }
    }
    &_chart {
      position: relative;
      .wrap{
        padding-top: 12px;
      }
      canvas {
        position: relative;
        z-index: 1;
        margin-bottom: -30px;
      }
      .title {
        @media (max-width: 1279px) {
          font-size: 16px;
        }
      }
      &::before {
        content: '';
        margin: 0 auto;
        width: 116px;
        border-radius: 50%;
        height: 116px;
        left: 0;
        right: 0;
        z-index: 0;
        position: absolute;
        bottom: 72px;
        box-shadow: 0px 0px 16px rgba(41, 61, 102, 0.08), 0px 0px 8px rgba(41, 61, 102, 0.08);
      }
      @media(max-width:1600px){
        &::before {
          bottom: 55px;
        }
      }
      @media (max-width: 1279px) {
        .title {
          font-size: 16px;
        }
      }
      @media (max-width: 1023px) {
        .title {
          font-size: 14px;
        }
      }
      @media(max-width:939px){
        margin-right: 60%;
        margin-bottom: 20px;
      }
      @media(max-width:767px){
        max-width: 100%;
        flex:0 0 100%;
        .wrap{
          min-height: 200px;
        }
      }
    }
    @media(max-width:767px){
      margin-bottom: 12px;
    }
  }
  &-table {
    box-shadow: none;
    border-radius: 0;
    flex: 1;
    margin-bottom: 0;
    .no-data {
      margin: 0 auto;
      padding-top: 60px;
      text-align: center;
      color: rgba(#1c243c, 0.5);
      img {
        margin: 0 0 16px;
      }
      @media (max-width: 1279px) {
        padding-top: 30px;
      }
    }
    .rs-panel-body {
      padding-top: 24px;
      padding-bottom: 0;
      @media(max-width:949px){
        padding-top: 40px;
      }
    }
    .table-component {
      @media (min-width: 1479px) {
        margin: 0;
      }
    }
    &_nav {
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: inset 0px -1px 0px rgba(#8C919D, 0.5);
      &_links{
        position: absolute;
        top:18px;
        right: 23px;
        @media(max-width:1023px){
          a{
            font-size: 14px;
          }
        }
      }
      a {
        color: #325f97;
        text-decoration: underline;
        font-weight: 500;
        &:hover {
          text-decoration: none;
        }
      }
      .rs-nav-item {
        display: inline-block;
        vertical-align: bottom!important;
        background: none;
        border:0;
        padding: 5px 16px;
        font-weight: 500;
        font-size: calcRem(20px);
        line-height: 1.4;
        text-align: center;
        min-width: 240px;
        color: #1c243c;
        text-decoration: none;
        border-radius:0!important;
        &:hover{
          background: #e9eaed;
        }
        &:hover{
          background: none;
        }
        @media(max-width:1279px){
          padding: 5px;
        }
        @media(max-width:1023px){
          padding: 5px 2px;
        }
        & + .rs-nav-item{
          margin-left: 60px;
        }
        &.rs-nav-item-active{
          border: 0!important;
          display: inline-block;
          box-shadow: inset 0px -2px 0px #242F50;
        }
        .num {
          text-align: center;
          background: #e9eaed;
          border-radius: 50px;
          padding: 1px 2px;
          min-width: calcRem(30px);
          margin-left: 8px;
          line-height: calcRem(28px);
          display: inline-block;
          vertical-align: middle;
          font-size: calcRem(16px);
          &_small {
            background: none;
            font-size: 10px;
          }
        }
        @media (max-width: 1600px) {
          & + .rs-nav-item{
            margin-left: 25px;
          }
        }
        @media(max-width:1279px){
          padding: 5px;
          font-size: 16px;
          min-width: 160px;
          & + .rs-nav-item{
            margin-left: 10px;
          }
        }
        @media(max-width:1023px){
          padding: 5px 2px;
          font-size: 14px;
          min-width: 110px;
        }
      }
    }
  }
}

.notifications-panel {
  overflow: visible;
  .btn-add {
    top: 18px;
  }
}
.notifications-panel .rs-btn-ghost {
  position: absolute;
  top: -40px;
  right: -10px;
}
.notifications-panel_text {
  font-size: 24px;
  text-align: center;
  margin: 7px 0 30px;
  color: rgba(57, 72, 116, 0.45);
}
.scan-request-list-panel {
  min-height: 260px;
  flex: 1;
  padding-bottom: 30px;
  margin-bottom: 0;
}
.scan-request-list-panel .rs-picker-select {
  position: absolute;
  top: 16px;
  right: 16px;
}

.scan-request-list-text {
  font-size: 24px;
  text-align: center;
  margin: 80px 0;
  color: rgba(57, 72, 116, 0.45);
}
.scan-request-list-text .rs-btn-ghost {
  margin-top: 32px;
}

.patient-panel.rs-panel {
  margin-bottom: 0;
  position: relative;
  background: none;
  display: flex;
  flex-direction: column;
  box-shadow: none;
  overflow: visible;
  height: 100%;
  .rs-dropdown-toggle,
  .rs-dropdown-toggle.rs-btn {
    border: 1px solid #1c243c;
    color: #1c243c;
    font-size: calcRem(16px);
    min-width: calcRem(267px);
    text-align: left;
    padding: 6px 12px;
    border-radius: 4px;
    .rs-dropdown-toggle-caret {
      font-size: 28px;
      top: 2px;
      height: 28px;
      right: 2px;
    }
    @media (min-width: 2400px) {
      padding:7px 12px;
    }
    @media (max-width: 1279px) {
      padding: 6px 12px 6px 8px;
      font-size: 15px;
      min-width: 235px;
      .rs-dropdown-toggle-caret {
        font-size: 24px;
        right: 6px;
        top: 1px;
      }
    }
    @media (max-width: 1023px) {
      font-size: 14px;
      min-width: 220px;
      .rs-dropdown-toggle-caret {
        font-size: 20px;
      }
    }
  }
  .rs-dropdown {
    .rs-dropdown-menu {
      width: 100%;
      .rs-dropdown-item-active {
        background: #d6d8df;
      }
    }
    .rs-dropdown-menu > .rs-dropdown-item-active,
    .rs-dropdown-menu > .rs-dropdown-item-active:hover,
    .rs-dropdown-menu > .rs-dropdown-item-active:focus {
      color: #1c243c;
    }
    .rs-dropdown-menu > .rs-dropdown-item:hover{
      text-decoration: none;
      color: #575757;
      background-color: #f2faff;
    }
  }
  .table-component {
    background: #fcfdfe;
    .rs-table-cell{
      border-bottom: 0;
    }
  }
  .rs-panel-body {
    display: flex;
    flex: 1;
    padding-bottom: 0;
    background: #fcfdfe;
    padding-right: 24px;
    flex-direction: column;
    .loader-block{
      height: 100%;
    }
  }
  @media (max-width: 639px) {
    .justify-content-end {
      flex-direction: column;
      align-items: flex-end;
      .number-pages {
        margin: 15px 0;
      }
    }
  }
}
.patient-panel .btn-add {
  position: static;
  margin-right: -20px;
}
.patient-panel .input-search {
  max-width: 652px;
}
.patient-panel .btn-add .rs-btn-ghost {
  text-transform: uppercase;
}
.input-search {
  position: relative;
  max-width: 733px;
  width: 100%;
  .rs-input-group {
    z-index: 6;
  }
}
.search-dropdown {
  position: absolute;
  top: 100%;
  display: flex;
  flex-direction: column;
  left: 16px;
  min-width: 130%;
  max-width: 144%;
  z-index: 7;
  border-radius: 4px;
  min-height: 96px;
  max-height: 470px;
  background: #fcfdfe;
  box-shadow: 0px 0px 8px rgba(35, 44, 81, 0.15);
  border: 1px solid #dddfe4;
  @media (max-width: 1279px) {
    width: 98%;
  }
  .rs-table-hover .rs-table-row:not(.rs-table-row-header):hover,
  .rs-table-hover .rs-table-row:not(.rs-table-row-header):hover .rs-table-cell-group,
  .rs-table-hover .rs-table-row:not(.rs-table-row-header):hover .rs-table-cell {
    background: rgba(151, 173, 228, 0.5);
  }
  .rs-table-row {
    border-bottom: 1px solid #f2f3f5;
    .rs-table-cell-first .rs-table-cell-content {
      padding-left: 16px;
    }
  }
  .rs-table-cell-header .rs-table-cell-content {
    font-style: italic;
    font-weight: 400;
  }
  .rs-table-cell-content {
    font-size: 14px;
    padding: 16px;
    @media (max-width: 1379px) {
      font-size: 16px;
    }
  }
  .table-component {
    margin: 0;
  }
  .text {
    letter-spacing: 0.04em;
    font: italic 600 10px/1.6 'Montserrat', sans-serif;
    color: rgba($blackHead, 0.5);
    i {
      font-weight: 400;
    }
  }
  .title {
    letter-spacing: 0.04em;
    font: italic 600 10px/1.6 'Montserrat', sans-serif;
    color: rgba($blackHead, 0.5);
  }
  .delete {
    letter-spacing: 0.04em;
    color: #325f97;
    padding: 5px;
    background: none;
    line-height: 1.4;
    margin: 28px auto 26px;
    display: table;
    font-size: 20px;
    cursor: pointer;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
    @media (max-width: 1279px) {
      font-size: 16px;
    }
  }
  .search-line-result {
    color: #8c919d;
    padding: 16px;
    font-style: italic;
    font-size: 20px;
    font-weight: 500;
    border-bottom: 1px solid #f2f3f5;
    @media (max-width: 1379px) {
      font-size: 16px;
    }
  }
  .search-line-nohistory {
    padding: 32px 16px;
    color: #8c919d;
    font-size: calcRem(20px);
    font-style: italic;
    @media (max-width: 1379px) {
      font-size: 16px;
      padding: 22px 10px;
    }
  }
  &-noresult {
    > div {
      border-bottom: 1px solid #f2f3f5;
      font-size: 20px;
      line-height: 1.4;
      padding: 8px 16px;
      font-style: italic;
      color: #8c919d;
    }
  }
  @media (max-width: 1279px) {
    min-height: 70px;
    .rs-table-cell-content {
      font-size: 16px;
      padding: 12px;
    }
  }
}
.search-dropdown-list {
  overflow-x: auto;
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font: 400 12px/1.6 'Montserrat', sans-serif;
    color: rgba($blackHead, 0.75);
    & + .row {
      margin-top: 10px;
    }
    .btn-plus {
      padding-right: 15px;
    }
  }
}
.input-search {
  position: relative;
}
.input-search .icon-search .rs-icon {
  font-size: 22px;
}
.input-search .btn-cancel-search {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  z-index: 3;
  text-decoration: underline;
  color: #325f97;
  &:hover {
    text-decoration: none;
  }
  @media (max-width: 1479px) {
    font-size: 13px;
    right: 10px;
  }
}
.input-search .rs-input-group:not(.rs-input-group-inside):not(.rs-input-group-disabled):hover {
  border-color: #dddfe4;
}
.input-search .rs-input-group-focus .icon-search-btn.rs-input-group-addon {
  display: flex;
}
.input-search .rs-input {
  height: 56px;
  transition: all 0.2s linear;
  padding-left: 42px;
  border-radius: 30px !important;
  padding-right: 160px;
  color: #1c243c;
  line-height: 1.6;
  font-size: calcRem(16px);
  background: #fcfdfe;
  @media (max-width: 1479px) {
    padding-right: 125px;
  }
}
.input-search .rs-input-group:not(.rs-input-group-inside):not(.rs-input-group-disabled).rs-input-group-focus {
  border: 2px solid #325f97;
  .icon-search.rs-input-group-addon {
    color: #242f50;
    opacity: 1;
  }
}
.input-search .rs-input-group {
  border-radius: 30px;
  background: #fcfdfe;
  border: 2px solid #dddfe4;
  position: relative;
}
.input-search .rs-input {
  @include placeholder {
    font: 400 16px/24 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: calcRem(16px);
    line-height: 24px;
    color: #1c243c;
    opacity: 0.5;
    @media (max-width: 1279px) {
      font-size: 14px;
    }
  }
}
.input-search .rs-input-group:not(.rs-input-group-inside) > :first-child,
.input-search .rs-input-group:not(.rs-input-group-inside) .rs-auto-complete:first-child .rs-input,
.input-search .rs-input-group:not(.rs-input-group-inside) .rs-picker-date:first-child .rs-picker-toggle,
.input-search .rs-input-group:not(.rs-input-group-inside) .rs-form-control-wrapper:first-child > .rs-input {
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
}
.input-search .icon-search.rs-input-group-addon {
  justify-content: center;
  position: absolute;
  font-size: 16px;
  color: #242f50;
  padding: 0;
  opacity: 0.5;
  width: 26px;
  background: #fcfdfe;
  left: 10px !important;
  top: 50%;
  z-index: 3;
  transform: translateY(-50%);
}

.input-search .icon-search-btn.rs-input-group-addon {
  background: #242f50;
  border-radius: 0 30px 30px 0;
  color: #fcfdfe;
  margin-right: -1px;
  display: none;
  width: 70px;
  justify-content: center;
  font-size: 20px;
}
/* patient */
.patient-info-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  .patient-panel .btn-add {
    position: absolute;
    margin: 0;
    top:14px;
  }
}
.patient-info-content .btn-edit:hover {
  text-decoration: none;
}
.patient-info-content .rs-table-row-header {
  background: none;
}
.patient-info-content .rs-panel-header {
  padding: 30px 16px;
}
.patient-info-content .rs-table,
.patient-info-content .rs-table-cell {
  background: #fcfdfe;
}
.patient-info-content .rs-table-row-header .rs-table-cell {
  color: #1c243c;
  opacity: 0.5;
  font-weight: 400;
}
.patient-info-content .rs-table-row {
  border-bottom: 0;
}
.patient-info-content .patient-panel {
  background: #fcfdfe;
  box-shadow: 0px 0px 10px 4px rgba(36, 47, 80, 0.03);
  border-radius: 10px;
  margin-bottom: 27px;
  height: auto;
}
.patient-info-content .patient-panel:last-child {
  margin-bottom: 0;
  flex: 1;
}
.patient-info-content .patient-panel .rs-panel-body {
  padding-bottom: 20px;
}
.patient-info-content .patient-panel:nth-child(1) .rs-panel-header {
  padding: 20px 16px 5px;
}
.patient-info-content-item{
  .patient-table-info .rs-table-cell-content {
    color: #1c243c;
    font-size: calcRem(14px);
    padding: 4px 10px;
  }
}
.patient-table-info .rs-table-cell-header .rs-table-cell-content {
  font-size: calcRem(14px);
}
.patient-table-info .table-component {
  margin: 0;
}
.patient-table-info + .patient-table-info {
  border-top: 1px solid #dddfe4;
}
.patient-info-content-item:nth-child(1) {
  max-width: 60%;
  flex: 0 0 60%;
  padding-right: 30px;
  border-right: 1px solid #dddfe4;
  @media (max-width: 1279px) {
    padding-right: 20px;
  }
}
.patient-info-content-item:nth-child(2) {
  padding: 0 0 0 18px;
  flex: 0 0 40%;
  max-width: 40%;
}
.patient-user-list .row {
  width: 100%;
  align-items: center;
  .rs-btn{
    font-size: calcRem(14px);
  }
  @media (max-width: 1279px) {
    flex-wrap: wrap;
  }
}

.patient-user-list .row .col {
  & + .col {
    padding-left: 50px;
  }
  @media (max-width: 1279px) {
    flex: 0 0 100%;
    width: 100%;
    & + .col {
      padding: 15px 0 0;
    }
  }
}
.patient-user-list .row .col .title {
  color: #1c243c;
  opacity: 0.5;
  font-size: calcRem(14px);
}
.patient-user-list .rs-btn-link {
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  text-align: left;
}
.patient-user-list .title {
  display: block;
  margin-bottom: 8px;
}
.patient-user-list .title svg {
  margin-right: 5px;
  position: relative;
  top:2px;
}
.patient-user-list .text {
  font-size:  calcRem(15px);
  line-height: 25px;
  color: #1c243c;
}
.patient-panel-into .btn-edit {
  position: absolute;
  top: 8px;
  right: 16px;
}
.patient-panel-into .btn-edit:hover {
  text-decoration: none;
}
.patient-panel-into .rs-panel-body{
  padding-left: 16px;
  > .row {
    padding-top: 16px;
  }
}
.patient-panel-into .rs-panel-body {
  @media (max-width: 1279px) {
    padding-left: 15px;
  }
}
.patient-model_mask {
  position: absolute;
  min-height: 970px;
}

/* model user */

.invite-user-modal .rs-form-control-wrapper > .rs-input {
  width: 100%;
  border: 0;
  height: 54px;
}
.invite-user-modal .rs-form-group .rs-form-control-wrapper {
  flex: 1;
  width: 100%;
}

.invite-user-modal .rs-form-group .rs-form-control-wrapper input {
  @include placeholder {
    color: #1c243c;
    opacity: 0.25 !important;
  }
}
.invite-user-modal .rs-form-group.error .rs-form-control-wrapper input {
  @include placeholder {
    color: $red;
    opacity: 0.5;
  }
}

.invite-user-modal .rs-form-group .input-wrap {
  margin-bottom: 0;
}

.invite-user-modal .rs-picker-select.error .rs-btn {
  border: 1px solid $red !important;
}
.invite-user-modal .rs-form-group.error {
  border-color: $red;
  position: relative;
}
.invite-user-modal .rs-form-group .error-text,
.invite-user-modal .rs-picker-select + .select-error {
  display: none;
}
.invite-user-modal .rs-form-group {
  &.error,
  &.error-user-role {
    .error-text {
      display: block;
      position: absolute;
      bottom: 100%;
      top: auto;
      left: 0;
      font-size: 12px;
      line-height: 1.4;
      margin-bottom: 9px;
      color: $red;
      background: #fff;
      z-index: 4;
      font-weight: 600;
      &-user-role {
        right: 0;
        left: auto;
      }
    }
  }
  &.error-user-role {
    .input-wrap.error {
      .rs-picker-toggle {
        box-shadow: 0 0 0 1px $red;
      }
    }
  }
}
.invite-user-modal .error.rs-picker-select + .select-error {
  display: block;
  position: absolute;
  bottom: 100%;
  right: 0;
  font-size: 12px;
  line-height: 1.4;
  margin-bottom: 12px;
  color: $red;
}
.invite-user-modal .error.rs-picker-select .rs-picker-toggle-placeholder {
  color: $red;
  opacity: 0.5;
}
.invite-user-modal .rs-form-group {
  border: 1px solid #dddfe4;
  position: relative;
  width: 100%;
  border-radius: 4px;
  display: flex;
  padding: 4px 8px;
  align-items: center;
  justify-content: space-between;
  .input-wrap {
    flex: 1;
    & + .input-wrap {
      flex: none;
    }
    .rs-form-error-message-wrapper{
      left: 0;
      bottom: -8px;
    }
  }
  .input-wrap {
    .rs-form-group {
      border: 0;
      padding-right: 0;
      &.error {
        .rs-form-error-message {
          left:-10px;
          font-weight: 600;
        }
        .rs-input {
          padding-right: 5px;
          border: 0;
        }
      }
      &:focus:not(.error) input{
        border: 0;
      }
      .rs-input {
        padding-right: 0;
        padding-left: 0px;
        &:focus{
          border:0;
          outline: none;
        }
      }
    }
  }
  .rs-form-control-label {
    transform: translateY(-12px);
    opacity: 1 !important;
    color: #325f97;
    font-weight: 600;
    font-size: 12px;
    cursor: pointer;
    top: -18px;
    left: -8px;
    height: 24px;
    position: absolute;
  }
}
.invite-user-modal .rs-picker-toggle {
  padding-left: 16px;
}
.invite-user-modal .rs-form-group .rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn {
  background: #f2f6fe !important;
  border-radius: 4px;
  font-size: 16px;
  border: 0;
  height: 54px;
  width: 100%;
  display: flex;
  align-items: center;
}
.invite-user-modal .rs-picker-toggle .rs-picker-toggle-placeholder {
  font-size: 16px;
}
.invite-user-modal .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  transform: translateY(-50%);
  top: 50%;
  right: 20px;
}
.invite-user-modal .rs-picker-toggle.rs-btn.active .rs-picker-toggle-caret {
  transform: translateY(-50%) rotate(180deg);
  top: 50%;
}
.invite-user-modal .rs-form-group .rs-picker-select {
  width: 211px;
}
.invite-user-modal .rs-modal-body {
  margin-top: 60px;
  margin-bottom: 20px;
  overflow: visible !important;
}
.invite-user-modal.rs-modal .rs-form-group.error .error-text::after,
.invite-user-modal.rs-modal .rs-form-group.error .error-text::before {
  display: none;
}

.location-model.rs-modal .rs-form-group {
  flex: 0 0 calcRem(396px);
  max-width: calcRem(396px);
}
.location-model.rs-modal .rs-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.location-model.rs-modal .rs-form-control-wrapper > .rs-input {
  width: 100%;
  padding: 16px 10px;
}
.rs-btn-toolbar {
  margin-top: 8px;
  flex: 0 0 100%;
  max-width: 100%;
  justify-content: flex-end;
  display: flex;
}
.location-model.rs-modal .rs-form .input-wrap {
  flex: 0 0 100%;
  &:last-of-type {
    margin-bottom: 0;
  }
}
.location-model.rs-modal .rs-form-group .rs-form-control-wrapper,
.location-model.rs-modal .rs-form-group .rs-input-group {
  width: 100%;
}
.location-model.rs-modal {
  .rs-form .state,
  .department .rs-picker-toggle.rs-btn {
    max-width: calcRem(276px);
    width: calcRem(276px);
    height: 56px;
    line-height: 38px;
    @media (min-width: $macBookPro){
      height: 64px; 
      line-height: 46px;
    }
  }
}
.location-model.rs-modal .rs-form .zip-code input,
.location-model.rs-modal .rs-form .state .rs-input-group .location-model.rs-modal .rs-form .state input,
.location-model.rs-modal .rs-form .zip-code .rs-input-group,
.location-model.rs-modal .rs-form .phone input,
.location-model.rs-modal .rs-form .phone .rs-input-group {
  width: 100%;
}
.location-model.rs-modal .rs-form .phone {
  max-width: calcRem(276px);
}
.location-model.rs-modal .rs-form .ext {
  max-width: calcRem(112px);
}
.location-model.rs-modal .rs-form .zip-code .rs-form-group,
.location-model.rs-modal .rs-form .state .rs-form-group {
  flex: 0 0 100%;
  max-width: 100%;
}
.rs-form .wrap {
  display: flex;
  width: 100%;
}
.rs-form .row {
  flex: 0 0 100%;
  max-width: 100%;
}
.rs-btn-toolbar > :not(:first-child):not(.rs-btn-block) {
  margin-left: 20px;
}
.location-model.rs-modal .rs-modal-body {
  padding-bottom: 25px;
  margin-top: 0;
  padding-top: calcRem(25px);
  @media (min-width:$macBookPro){
    padding-top: calcRem(35px);
  }
}

.scan-info {
  &-list {
    justify-content: space-between;
    &_item {
      max-width: 338px;
      padding: 0 25px;
      margin-bottom: 35px;
      width: 100%;
      input {
        width: 100%;
      }
      &.col100 {
        margin-right: 20%;
        max-width: 640px;
      }
    }
  }
  &-title {
    font-size: 16px;
    margin-bottom: 16px;
  }
}

// g-setting
.g-setting-panel {
  padding-top: 25px;
  position: relative;
  min-height: calc(100% + 101px);
  margin-top: -101px;
  .rs-form-group .rs-form-control-label {
    color: rgba($blackBlue, 0.5);
    font-weight: 400;
    font-size: calcRem(16px);
  }
  .checkbox-row {
    padding-right: 50px;
    padding-left: 15px;
    .box {
      font-size: 16px;
      font-weight: 400;
      color: #8c919d;
      @media (max-width: 1023px) {
        padding-right: 20px;
      }
    }
    .title {
      font-size: 16px;
      color: #8c919d;
      font-weight: 500;
      display: block;
      margin-bottom: 16px;
    }
    @media (max-width: 1023px) {
      padding-left: 0;
    }
  }
  .input-wrap {
    margin-bottom: 26px;
    padding-left: 15px;
    // .rs-input {
    //   background: transparent;
    //   border-color: transparent;
    // }
  }
  &_title {
    margin-bottom: 50px;
    position: relative;
    span {
      color: rgba($blackHead, 0.5);
      font-size: 16px;
      display: inline-block;
      margin-left: 8px;
    }
  }
}
.organization-opt {
  flex-wrap: wrap;
  padding-bottom: 24px;
  border-bottom: 1px solid #dddfe4;
  &_logo {
    width: 30%;
    .col {
      flex: 0 0 50%;
      max-width: 100%;
      padding-right: 70px;
      .g-setting-panel_title {
        font-weight: 400;
        font-size: calcRem(16px);
        margin-bottom: 8px;
        line-height: 1.4;
        color: rgba($blackBlue, 0.5);
      }
      &:nth-child(2) {
        padding-right: 0;
        .g-setting-panel_title {
          margin-bottom: 25px;
        }
      }
    }
    .desk {
      color: rgba($blackHead, 0.5);
      font-size: 16px;
      display: inline-block;
    }
    .organization-logo {
      min-height: calcRem(80px);
      max-height: calcRem(260px);
      max-width: calcRem(260px);
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (min-width:$macBookPro){
        max-height: calcRem(310px);
        max-width: calcRem(310px);
      }
      img {
        max-height: 100%;
        object-fit: cover;
      }
    }
  }
}
.g-setting-panel .btn-edit {
  position: absolute;
  top: 24px;
  font-size: calcRem(16px);
  right: 24px;
  .icon-lock {
    background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 11.7241V8C16 5.79086 14.2091 4 12 4V4C9.79086 4 8 5.79086 8 8V11.7241' stroke='%23325F97' stroke-width='3'/%3E%3Cpath d='M4 11.062C4 9.94186 4 9.38181 4.21799 8.95399C4.40973 8.57766 4.71569 8.2717 5.09202 8.07996C5.51984 7.86197 6.0799 7.86197 7.2 7.86197H16.8C17.9201 7.86197 18.4802 7.86197 18.908 8.07996C19.2843 8.2717 19.5903 8.57766 19.782 8.95399C20 9.38181 20 9.94186 20 11.062V16.7999C20 17.92 20 18.4801 19.782 18.9079C19.5903 19.2842 19.2843 19.5902 18.908 19.7819C18.4802 19.9999 17.9201 19.9999 16.8 19.9999H7.2C6.0799 19.9999 5.51984 19.9999 5.09202 19.7819C4.71569 19.5902 4.40973 19.2842 4.21799 18.9079C4 18.4801 4 17.92 4 16.7999V11.062Z' fill='%23325F97'/%3E%3Cellipse cx='12' cy='13.9314' rx='4.57143' ry='4.41379' fill='%23325F97'/%3E%3Cpath d='M14 17.2757C13.3919 17.6147 12.7021 17.7931 12 17.7931C11.2979 17.7931 10.6081 17.6147 10 17.2757' stroke='%23325F97' stroke-width='4' stroke-linecap='round'/%3E%3Cpath d='M12.8572 14.6952V15.3103C12.8572 15.3103 12.8572 16.1379 12 16.1379C11.1429 16.1379 11.1429 15.3103 11.1429 15.3103V14.695C11.1429 14.5594 11.0856 14.4314 10.9966 14.3291C10.6811 13.9665 10.5715 13.6039 10.5715 13.2414C10.5715 12.8276 10.7143 12.5517 11 12.2759C11.2858 12 11.7143 12 12 12C12.2858 12 12.7143 12 13 12.2759C13.2858 12.5517 13.4286 12.8276 13.4286 13.2414C13.4286 13.6039 13.3189 13.9665 13.0035 14.3291C12.9145 14.4314 12.8572 14.5596 12.8572 14.6952Z' fill='white'/%3E%3Crect x='6.85718' y='6.75912' width='2.28571' height='1.10345' fill='%23325F97'/%3E%3Crect x='14.8572' y='6.75912' width='2.28571' height='1.10345' fill='%23325F97'/%3E%3C/svg%3E%0A");
    width: 24px;
    height: 24px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }
  .icon-edit {
    background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22.5853 7.22117C23.3664 6.44012 23.3664 5.17377 22.5853 4.39272L19.6069 1.41426C18.8259 0.6332 17.5596 0.633199 16.7785 1.41426L15.4055 2.78735L21.2123 8.59427L22.5853 7.22117Z' fill='%23325F97'/%3E%3Cpath d='M18.8685 10.9374C19.6496 10.1564 19.6496 8.89002 18.8685 8.10896L15.8901 5.1305C15.1091 4.34945 13.8428 4.34945 13.0617 5.1305L4.74108 13.4513C3.96004 14.2324 3.96004 15.4987 4.74108 16.2798L6.23027 17.769L7.71947 19.2583C8.50051 20.0393 9.76683 20.0393 10.5479 19.2583L18.8685 10.9374Z' fill='%23325F97'/%3E%3Cpath d='M3.62143 21.4286C3.02933 21.459 2.54089 20.9706 2.57137 20.3785L2.76412 16.6329C2.77302 16.4599 2.98276 16.3793 3.10527 16.5018L7.49803 20.8946C7.62054 21.0172 7.53992 21.2269 7.36689 21.2358L3.62143 21.4286Z' fill='%23325F97'/%3E%3C/svg%3E%0A");
    width: 24px;
    height: 24px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }
}
.g-setting-panel_title {
  font-weight: 500;
  line-height: 1.4;
  margin-bottom: 26px;
  font-size: calcRem(20px);
}

.setting-password {
  flex: 0 0 33.333%;
  max-width: 33.333%;
  position: relative;
  margin-bottom: 26px;
  height: 56px;
  @media (max-width: 1023px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .g-setting-panel_title {
    color: rgba(4, 5, 8, 0.5);
    font-weight: 400;
    font-size: calcRem(16px);
    cursor: pointer;
    top: -10px;
    left: 0px;
    margin: 0;
    height: 24px;
    transform: translateY(-50%);
    position: absolute;
  }
  .btn-edit {
    position: static;
  }
}
.g-setting-panel_title .btn-edit {
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
.form-edit-data {
  padding-top: 30px;
  .input-wrap {
    max-width: calcRem(476px);
    margin-bottom: 40px;
    position: relative;
  }
  .rs-form-control-wrapper > .rs-input,
  .rs-form-control-wrapper {
    width: 100%;
  }
}
.form-profile-info {
  padding-top: 20px;
  .rs-form-group.anim-placeholder.show input.rs-input {
    border-color: transparent;
  }
  .input-wrap {
    flex: 0 0 33.333%;
    padding-right: 30px;
    padding-left: 0;
    .rs-input,
    .rs-form-control-wrapper {
      width: 100%;
      padding-left: 0;
    }
    .rs-input.login {
      padding-left: 0;
      color: #1c243c;
      opacity: 1;
      font-size: 16px;
      padding-top: 11px;
    }
    @media (max-width: 1023px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  &-org {
    width: calc(70% - 40px);
    padding: 0 30px 0 0;
    .input-wrap{
      .textarea{
        margin-top: 0;
      }
      textarea{
        height: 106px;
        resize: none;
      }
      @media (max-width: 1023px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    .organization-field {
      &-label {
        left: 0;
      }
      .textarea{
        margin-top: 36px;
        textarea {
          height: 146px;
          resize: none;
          min-height: 56px;
        }
      }
    }
    @media(max-width:1399px){
      width: 100%;
      padding: 0px 30px 15px 0;
    }
  }
  &-main {
    flex-wrap: wrap;
    margin-bottom: 24px;
    padding-bottom: 14px;
    border-bottom: 1px solid #dddfe4;
    .input-wrap {
      margin-bottom: 40px;
    }
    .input-wrap:nth-child(2) {
      margin-right: 20%;
      @media (max-width: 1023px) {
        margin-right: 0;
      }
    }
    .input-wrap:nth-child(3),
    .setting-password {
      margin-bottom: 0;
    }
  }
}

.textfiled-col{
  & + .textfiled-col{
    margin-top: 12px;
  }
  &_desk{
    .textfiled-col_content{
      max-height: 136px;
      overflow-y: auto;
    }
  }
  &_label{
    color: rgba(4, 5, 8, 0.5);
    font-weight: 400;
    margin-bottom: 8px;
    font: 400 calcRem(16px)/1.5 "Poppins", sans-serif;
  }
  &_content{
    color: #1c243c;
    padding: 0px 11px 7px 0;
    font-size: calcRem(16px);
    word-break: break-word;
  }
}

.model-change-password {
  .input-wrap {
    margin-bottom: 64px;
    &:nth-child(1),&:nth-child(3){
      margin-bottom: 36px;
    }
  }
}
.organization-edit-modal {
  .textarea {
    margin-top: 40px;
  }
  textarea {
    resize: none;
    height: calcRem(110px) !important;
  }
  .rs-uploader-file-items {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    .rs-uploader-file-item {
      position: static;
    }
  }
  .rs-uploader-picture .rs-uploader-file-item-btn-remove {
    display: block;
    opacity: 1;
    visibility: visible;
    width: calcRem(130px);
    color: #325f97;
    top: 50%;
    transform: translateY(-50%);
    &:hover {
      &::after {
        text-decoration: none;
      }
    }
    svg{
      display: none;
    }
    &::after {
      content: 'delete file';
      font-size: calcRem(16px);
      line-height: 1.4;
      letter-spacing: 0.04em;
      text-decoration-line: underline;
      color: #325f97;
    }
    &::before {
      content: "";
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 16 16' fill='currentColor' aria-hidden='true' focusable='false' class='rs-icon' aria-label='trash' data-category='action' style='font-size: 200px;'%3E%3Cpath d='M5.5 7a.5.5 0 01.5.5v4a.5.5 0 01-1 0v-4a.5.5 0 01.5-.5zM7.5 7a.5.5 0 01.5.5v4a.5.5 0 01-1 0v-4a.5.5 0 01.5-.5zM9.5 7a.5.5 0 01.5.5v4a.5.5 0 01-1 0v-4a.5.5 0 01.5-.5zM1.5 3h12a.5.5 0 010 1h-12a.5.5 0 010-1z' fill='%23325f97'%3E%3C/path%3E%3Cpath d='M3 4v10a1 1 0 001 1h7a1 1 0 001-1V4H3zM2 3h11v11a2 2 0 01-2 2H4a2 2 0 01-2-2V3z' fill='%23325f97'%3E%3C/path%3E%3Cpath d='M5.281 3l.311-1.243A1 1 0 016.562 1h1.877a1 1 0 01.97.757L9.72 3H5.282zM4 4h7l-.621-2.485A2.001 2.001 0 008.439 0H6.562a2 2 0 00-1.94 1.515L4.001 4z' fill='%23325f97'%3E%3C/path%3E%3C/svg%3E");
      margin-right: 4px;
      width: 20px;
      height: 20px;
      display: inline-block;
      vertical-align: sub;
      z-index: 23232332;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  .rs-uploader {
    max-width: calcRem(544px);
    width: 100%;
    &.uploaded {
      padding: 20px 8px;
      .rs-uploader-trigger {
        display: none;
      }
    }
  }
  .form-edit-data .input-wrap {
    max-width: calcRem(544px);
  }
  .rs-uploader-trigger-btn {
    max-width: 480px;
    height: 38px;
    line-height: 22px;
    width: 100%;
    margin: 0;
  }
  .rs-uploader {
    width: 100%;
    border: 2px dashed #325f97;
    box-shadow: 0px 0px 10px 4px rgba(36, 47, 80, 0.03);
    border-radius: 4px;
    padding: 20px 36px;
  }
  .rs-uploader-trigger {
    float: none;
  }
  .rs-uploader-file-items {
    .rs-uploader-file-item {
      float: none;
      width: calcRem(254px);
      border-radius: 0;
      margin: 0;
    }
  }
  &_api{
    .form-edit-data .input-wrap{
      max-width: 100%;
    }
    .hint-copied{
      color:#3f8a68;
      font-size: 16px;
      position: absolute;
      left: 16px;
      bottom: 7px;
      .successfully-icon{
        width:21px;
        vertical-align: text-top;
        margin-right: 10px;
        height: 22px;
        background-size: cover;
      }
    }
  }
}
.organization-file-field {
  margin-bottom: 20px;
  & .rs-uploader-file-item-preview > img {
    object-fit: contain !important;
  }
}
.ReactVirtualized__Grid__innerScrollContainer {
  .rs-picker-select-menu-item {
    padding: 9px 16px;
    border-bottom: 0;
    box-shadow: 1px 0 0 1px #dddfe4;
    &:last-child {
      box-shadow: 0;
    }
  }
}
.task-status-button {
  border-radius: 4px;
  padding: calcRem(2px) 5px;
  font-size: calcRem(14px);
  background-color: #e4ebf7;
  color: #325f97;
  &.close {
    background: #f2f2f4;
    color: #505972;
  }
  &.completed {
    background: #e4ede8;
    color: #244f33;
  }
  @media (max-width: 1479px) {
    font-size: 12px;
  }
}

.scan-count {
  position: absolute;
  top: 41%;
  left: 50%;
  font-size: 26px;
  line-height: 26px;
  transform: translate(-50%, -50%);
  @media (max-width: 1600px) {
    top:45%;
  }
}

// checkbox setting nav

.checkbox-setting-nav {
  flex-wrap: wrap;
  margin-bottom: 20px;
  &_item {
    height: 100%;
    flex: 0 0 42%;
    max-width: 42%;
    margin-top: 32px;
    display: flex;
    &:first-child,
    &:nth-child(2) {
      margin-top: 0;
    }
    &.checkbox-row {
      padding: 0 30px 0 8px;
      flex-wrap: wrap;
    }
    .flex-container {
      padding-right: 20px;
      .wrap {
        padding-top: 4px;
        position: relative;
        display: inline-flex;
        padding-right: 34px;
        p {
          font-weight: 500;
          font-size: calcRem(16px);
          line-height: 1.4;
          color: #7c8295;
        }
      }
      .tooltip {
        position: absolute;
        top: 0px;
        width: calcRem(24px);
        height: calcRem(24px);
        background-color: #8C919D;
        cursor: pointer;
        right: 0;
        background-size: calcRem(24px);
        &:hover{
          background-color: transparent;
          background-size: calcRem(24px);
        }
      }
    }
    .rs-toggle {
      height: calcRem(28px);
      min-width: calcRem(52px);
      margin-right: calcRem(16px);
      &.rs-toggle-checked::after {
        margin-left: calcRem(24px);
      }
      .rs-toggle-inner {
        font-weight: 500;
        text-transform: uppercase;
        font-size: calcRem(12px);
      }
      .rs-toggle-presentation::after {
        width: calcRem(20px);
        left: calcRem(4px);
        top: calcRem(4px);
        height: calcRem(20px);
      }
    }
    @media (max-width: 1379px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    @media (max-width: 1079px) {
      flex: 0 0 100%;
      max-width: 100%;
      margin-top: 30px;
      &.checkbox-row {
        padding-right: 30px;
      }
      &:nth-child(2) {
        margin-top: 30px;
      }
    }
  }
}

.org-setting-panel{
  .checkbox-setting-nav_item{
    height: auto;
  }
}

.org-setting-api{
  margin-top: 24px;
  padding: 24px 0;
  border-top: 1px solid #dddfe4;
  &_title{
    color: rgba(4, 5, 8, 0.5);
    font-weight: 400;
    font-size: calcRem(16px);
    margin-bottom: 16px;
  }
  &_btn{
    background: #242f50;
    border-radius: calcRem(20px);
    padding:calcRem(12px) calcRem(24px);
    font: 600 calcRem(14px)/1.4 "Poppins", sans-serif;
  }
  &_mb{
    margin-bottom: 20px;
  }
}

.btn-row-setting{
  position: absolute;
  bottom: 31px;
  right: 0;
  @media(max-width:1500px){
    bottom: 10px;
  }
  @media(max-width:1179px){
    position: static;
  }
}

.checkbox-setting_list{
  padding: calcRem(16px) 0 0 74px;
  .rs-picker-countable{
    width: 100%;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 100%;
  }
  &_wrap{
    flex:0 0 100%;
    max-width: 100%;
  }
  .checkbox-wrapper{
    position: relative;
    padding-right: 40px;
    display: flex;
    flex-wrap: wrap;
    .tooltip{
      background-color: #8C919D;
      top:7px;
      width: calcRem(24px);
      flex:0 0 calcRem(24px);
      height: calcRem(24px);
      background-size: calcRem(24px);
      &:hover{
        background-color: transparent;
      }
    }
    .location-result{
      flex:0 0 100%;
      font-weight: 500;
      padding: 6px 0 0 26px;
      font-size: calcRem(16px);
      line-height: 140%;
      color: #242F4F;
      .row{
        align-items: center;
        .tooltip{
          top:0;
        }
      }
      > div{
        margin-bottom: 10px;
      }
      button{
        font-weight: 400;
        padding: 0;
        font-size: 16px;
        line-height: 1.5;
        text-decoration-line: underline;
        text-transform: capitalize;
        color: #325F97;
      }
    }
  }
  .rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before,
  .rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::before{
    background: #242F4F;
    border-color: #242F4F;
  }
  .rs-checkbox-checker:hover .rs-checkbox-wrapper .rs-checkbox-inner::before{
    border-color:#242F4F;
  }
  li{
    display: flex;
    .rs-checkbox-checker{
      padding-left: 26px;
      color:#8C919D;
      font-size: calcRem(16px);
    }
    .rs-checkbox-wrapper{
      left: 0;
      @media(min-width: $macBookPro){
        zoom: 1.12;
      }
    }
  }
}

.location-dropdown{
  width: 288px;
  height: 436px;
  background: #F8F9FA;
  border: 1px solid #DDDFE4;
  box-shadow: 0px 40px 40px -30px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding-right: 10px;
  transform: translate(0, -12px);
  .rs-check-item:not(.rs-checkbox-disabled):hover, .rs-check-item-focus{
    background: none;
  }
  .rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before, .rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::before{
    background: #242F4F;
    border: 1px solid #D3D5DC;
  }
  .rs-checkbox-checker:hover .rs-checkbox-wrapper .rs-checkbox-inner::before{
    border: 1px solid #DDDFE4;
  }
  .rs-checkbox-wrapper .rs-checkbox-inner::before {
    background: #FEFEFE;
  }
  .rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::after {
    left: 1px;
    top: 1px;
  }
  .rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::after{
    top:1px;
    left: 1px;
  }
  .rs-checkbox{
    color:#1C243C;
  }
  .check-sticky-footer{
    padding: 0 10px 12px;
    display: flex;
    flex-direction: column;
    margin-right: -10px;
    .rs-checkbox-checker{
      padding-left: 38px;
    }
    .rs-checkbox{
      padding: 4px 0;
      margin: 0 -10px;
      margin-bottom: 12px;
      box-shadow: inset 0px -1px 0px #E1E3E7;
      font-size: calcRem(16px);
      .rs-checkbox-wrapper{
        top:10px;
        left: 12px;
      }
    }
    .btn-model{
      margin-left: auto;
      min-height: 56px;
      min-width: 130px;
      font-size: calcRem(16px);
    }
  }
  .rs-picker-check-menu-items{
    max-height: 245px!important;
    overflow-y: auto;
    padding-right: 8px;
    &::-webkit-scrollbar {
      width: 4px;
      margin-right: 10px;
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #E9EAED;
      border-radius: 3px;
    }
  }
  .rs-check-item .rs-checkbox-checker > label{
    padding: 14px 6px 14px 38px;
    line-height: 21px;
    font-size: 16px;
    font-weight: 400;
  }
  .rs-picker-check-menu-items .rs-check-item.rs-checkbox-checked .rs-checkbox-checker > label{
    font-weight: 400;
  }
  .rs-checkbox-wrapper{
    width: 18px;
    height: 18px;
    top:16px;
    left: 8px;
    @media(min-width:$macBookPro){
      top:12px;
      left: 6px;
    }
  }
  .rs-checkbox-wrapper::before,.rs-checkbox-wrapper::after,
  .rs-checkbox-wrapper .rs-checkbox-inner::before{
    width: 18px;
    height: 18px;
  }
  .rs-picker-search-bar{
    padding: 8px;
    margin-right: -10px;
    &::after{
      width: 20px;
      height: 20px;
      font-size: calcRem(20px);
      top: 12px;
      right: 18px;
    }
    .rs-picker-search-bar-input{
      padding: 8px;
      background: #FEFEFE;
      border: 1px solid #DDDFE4;
    }
  }
}

.page-comments-content{
  .dashboard-task_table_nav .number{
    padding: 8px 32px;
  }
  .state.state-team{
    padding: 1px 16px;
    font-size: 16px;
    color:#1C243C;
    border-radius: 5px;
  }
  .nav-top_title{
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.4;
    color: #1C243C;
    b{
      font-weight: 700;
    }
  }
  .rs-panel-body{
    background: #fff;
  }
  .rs-btn-link{
    padding: 0;
    color:#325F97;
    font-weight: 400;
  }
  .rs-table-cell-header{
    .rs-table-cell-content{
      padding: 8px 24px;
    }
  }
  .rs-table-cell-content{
    padding: 16px 24px;
    align-items: flex-start;
  }
}
