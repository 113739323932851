.choose-account {
  padding: 77px 55px;

  .title {
    font-family: Poppins;
    font-weight: 700;
    font-size: 35px;
    line-height: 140%;
    color: #242F50;
    margin-bottom: 32px;
  }

  .account-card-container {
    display: flex;
    flex-wrap:wrap;
    gap: 24px;
  }

  .account-card {
    display: flex;
    align-items: center;
    gap: 16px;

    width: 399.3333435058594;
    height: 80px;
    border-radius: 8px;
    padding: 16px 24px 16px 16px;
    cursor: pointer;

    flex: 1 1 calc(33.33% - 24px);
    max-width: 400px;
    min-width: 200px;

    transition: all .3s linear;
    box-shadow: 0px 2px 10px 0px #242F501A;
    background: #FCFDFE;

    &:hover{
      box-shadow: 0 2px 14px 0 rgba(#242F50,0.3);
    }

    &__name {
      font-family: Poppins;
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__chevron {
      margin-left: auto;

      &::before {
        content: "";
        display: inline-block;
        position: relative;
        top: 3px;
        width: 12px;
        height: 24px;
        background-image: url('data:image/svg+xml,<svg width="12" height="24" viewBox="0 0 12 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.4168 12.6875L2.41684 22.6875C1.9585 23.1042 1.47934 23.125 0.979338 22.75C0.562671 22.2917 0.541838 21.8125 0.916838 21.3125L9.35434 12L0.916838 2.6875C0.541838 2.1875 0.562671 1.70833 0.979338 1.25C1.47934 0.874998 1.9585 0.895832 2.41684 1.3125L11.4168 11.25C11.7502 11.75 11.7502 12.2292 11.4168 12.6875Z" fill="%238C909D"/></svg>');
      }
    }
  }
}