* {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body,
figure {
  margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

*:focus {
  outline: none;
}

a {
  text-decoration: none;
}

a:active,
a:hover {
  outline: 0;
}

small {
  font-size: 80%;
}

img {
  max-width: 100%;
  border: 0;
}

address {
  font-style: normal;
}

button,
input,
optgroup,
textarea {
  border: 0;
  background: none;
  color: inherit;
  font: inherit;
  margin: 0;
  -webkit-appearance: none;
}

textarea {
  overflow: auto;
  resize: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

ul,
ol {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
}

td,
th {
  padding: 0;
}

p {
  margin: 0;
}

input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

input::-ms-clear {
  display: none;
}

body.ovh {
  overflow: hidden;
}

input[type='date']::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: 'color 9999s ease-out, background-color 9999s ease-out';
  -webkit-transition-delay: 9999s;
}

a:focus,
.button:focus {
  text-decoration: none;
  outline: none;
  box-shadow: none;
}

.btn:focus,
button:focus {
  box-shadow: none;
  outline: none;
}

input[type='data']::-webkit-clear-button {
  display: none;
}

a:focus,
a:hover {
  text-decoration: none;
}

*[hidden] {
  display: none !important;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
  margin-bottom: 0;
  margin-top: 0;
  display: block;
}

.pr1 {
  padding-right: 6px;
}
.pl1 {
  padding-left: 6px;
}
.pt0 {
  padding-top: 0px !important;
}
.pt1 {
  padding-top: 15px !important;
}
.pt2 {
  padding-top: 20px !important;
}
.pt3 {
  padding-top: 30px !important;
}
.pb1 {
  padding-bottom: 15px !important;
}
.pb2 {
  padding-bottom: 20px !important;
}
.pb3 {
  padding-bottom: 30px !important;
}
.mb0 {
  margin-bottom: 0px !important;
}
.mb1 {
  margin-bottom: 15px !important;
}
.mb2 {
  margin-bottom: 24px !important;
}
.mb3 {
  margin-bottom: 34px !important;
}
.mb4 {
  margin-bottom: 40px !important;
}
.mb5 {
  margin-bottom: 56px !important;
}
.mr1 {
  margin-right: 10px;
}
.mr4 {
  margin-right: 40px;
}
.mt2 {
  margin-top: 20px !important;
}
.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.row-wrap {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.row-column {
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.justify-content-between {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.align-items-end {
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.align-items-center {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.align-items-baseline {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}

.justify-content-end {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.justify-content-between_align-center {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.justify-content-center {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.flex-1 {
  flex: 1;
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: left !important;
}

:root {
  --app-height: 100%;
}

@import '_variables.scss';

html{
  font-size: $browser-size;
}
@media (min-width: $macBookPro) {
  html{
    font-size: 22.5px;
  }
}
h1,
.h1 {
  line-height: 1.3;
  font-weight: 600;
  font-size: calcRem(20px);
  margin-bottom: 35px;
}

.container {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  @media (min-width: $desktop) {
    padding: 0 25px;
  }
}

a,
.button,
.btn {
  transition: color 0.35s cubic-bezier(0.51, 0.92, 0.24, 1.15) 0s;
}

.btn {
  &-back {
    font-weight: 500;
    font-size: 20px;
    line-height: calc(28 / 20);
    color: #1c243c;

    i {
      margin-right: 21px;
      display: inline-block;
      vertical-align: middle;
      font-size: 24px;
    }
  }

  &-next {
    font-weight: 500;
    font-size: 20px;
    line-height: calc(28 / 20);
    color: #1c243c;

    i {
      margin-left: 21px;
      display: inline-block;
      vertical-align: middle;
      font-size: 24px;
    }
  }

  &-inactive {
    display: none;
  }
}

select::-ms-expand {
  display: none;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $baseColor;
  background: #fff;
  font: 400 18px/1.4 'Poppins', sans-serif;
  @media (min-width: $macBookPro) {
    font: 400 20px/1.4 'Poppins', sans-serif;
  }
}
.center {
  text-align: center;
}
.login-section {
  min-height: var(--app-height);
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  height: -webkit-fill-available;
  padding: 60px 30px;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 98vh;
  width: 1200px;
  background: #f2f6fe;
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, #f2f6fe),
    color-stop(55%, #f2f6fe),
    color-stop(55%, white),
    color-stop(100%, white)
  );
  background: linear-gradient(to bottom, #f2f6fe 0%, #f2f6fe 55%, white 55%, white 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f2f6fe', endColorstr='#ffffff', GradientType=0 );
}

@media (min-width: 1200px) {
  .login-section {
    width: auto;
    background: #f2f6fe;
    background: -webkit-gradient(
      left top,
      left bottom,
      color-stop(0%, #f2f6fe),
      color-stop(65%, #f2f6fe),
      color-stop(65%, white),
      color-stop(100%, white)
    );
    background: linear-gradient(to bottom, #f2f6fe 0%, #f2f6fe 65%, white 65%, white 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f2f6fe', endColorstr='#ffffff', GradientType=0 );
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .login-section {
    height: 100vh;
  }
}

.login-figure {
  padding: 25px;
  -webkit-flex: 0 0 39%;
  -ms-flex: 0 0 39%;
  flex: 0 0 39%;
  max-width: 39%;
}

.login-img {
  text-align: center;
}

.login-logo {
  text-align: center;
  margin: 0 0 25px;
}

.login-logo-title {
  margin-bottom: 40px;
  text-align: center;
}

.login-logo img {
  display: inline-block;
}

.login-form-nav {
  margin-top: 95px;
}

.login-form-nav ul {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.login-form-nav ul a {
  font-size: 12px;
  color: #f2f6fe;
  text-decoration: underline;
}

.login-form-nav ul a:hover {
  text-decoration: none;
}

.login-form {
  -webkit-flex: 0 0 61%;
  -ms-flex: 0 0 61%;
  flex: 0 0 61%;
  max-width: 61%;
  padding: 65px 90px 15px;
  background: #242f50;
  color: #ffffff;
}

.login-form-error {
  min-height: 75px;
  text-align: center;
  color: $red;
  font-size: 12px;
  line-height: 1.6;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 15px 0;
}

.login-form form {
  margin: 0 auto;
  display: block;
  max-width: 324px;
}

.login-form-checkbox {
  font-size: 16px;
  letter-spacing: 0.04em;
  color: #1c243c;
}

.login-form-checkbox input {
  display: none;
}

.login-form-checkbox label {
  position: relative;
  padding-left: 32px;
  line-height: 18px;
  display: inline-block;
  cursor: pointer;
}

.login-form-checkbox label::before {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  left: 0;
  top: -1px;
  background-color: #e1e4e9;
  border: 1px solid rgba(#9297a8, 1);
  border-radius: 3px;
  background-repeat: no-repeat;
}

.login-form-checkbox input:checked + label::before,
.page-comment .form-checkbox input:checked + label::before,
.filter-model-comments .form-checkbox input:checked + label::before {
  content: '';
  background-image: url("data:image/svg+xml,%0A%3Csvg width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 6.21053L6 11L11 1' stroke='%233C8A65' stroke-width='2'/%3E%3C/svg%3E%0A");
  position: absolute;
  width: 18px;
  cursor: pointer;
  height: 18px;
  background-position: center;
}

.login-form-forgot {
  cursor: pointer;
  font-size: calcRem(16px);
  color: #325f97;
  text-decoration: underline;
  text-align: center;
  display: table;
  margin: 0 auto;
}

.login-form-input {
  position: relative;
  margin-bottom: 24px;
}

.login-form-input input {
  font-size: 18px;
  line-height: 28px;
  display: block;
  border-radius: 0;
  height: 28px;
  width: 100%;
  padding: 0 30px 0 0;
  box-sizing: border-box;
  color: #fefefe;
  box-shadow: 0 1px 0 0 #f2f6fe;
  transition: all 0.3s ease-out;
}

.login-form-input input:focus {
  box-shadow: 0 2.5px 0 0 #f2f6fe;
}

.login-form-input input[type='email'] {
  padding-right: 0;
}

.login-form-btn {
  background: #f2f6fe;
  box-sizing: border-box;
  border-radius: 51px;
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #242f50;
  min-width: 160px;
  text-transform: uppercase;
  height: 56px;
  line-height: 31px;
  padding: 14px 10px;
  margin: 0 auto 30px;
  border: 0;
  display: table;
  cursor: pointer;
  transition: opacity 0.3s linear;
}

.login-form-btn:hover {
  opacity: 0.8;
}

.login-form-btn:disabled {
  opacity: 0.45;
  cursor: not-allowed;
  pointer-events: all !important;
}

.login-form-btn:active {
  opacity: 0.8;
}

.login-container {
  position: relative;
  -webkit-flex: 0 0 892px;
  -ms-flex: 0 0 892px;
  flex: 0 0 892px;
  max-width: 892px;
}

.login-row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  z-index: 1;
  overflow: hidden;
  background: #fcfdfe;
  box-shadow: 0px 0px 8px rgba(35, 44, 81, 0.15);
  border-radius: 20px;
}

input[type='password'] + .password-view {
  .rs-icon::before {
    content: '\f070' !important;
  }
}

input[type='text'] + .password-view {
  .rs-icon::before {
    content: '\f06e' !important;
  }
}

.view-btn {
  width: 23px;
  right: 0;
  top: 50%;
  cursor: pointer;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 16px;
  transition: opacity 0.3s ease-out;
  position: absolute;
  opacity: 1;
  background-image: url("data:image/svg+xml,%0A%3Csvg width='23' height='16' viewBox='0 0 23 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22.9278 8C20.9373 11.8765 17.0136 16 11.4762 16C5.93882 16 1.76935 11.679 0 8C2.06424 4 5.93882 0 11.4762 0C17.0136 0 20.839 3.87654 22.9278 8Z' fill='%23F2F6FE'/%3E%3Ccircle cx='11.75' cy='8.25' r='3.75' fill='%23F2F6FE' stroke='%23242F50' stroke-width='3'/%3E%3C/svg%3E%0A");
}

.view-btn:hover {
  opacity: 0.8;
}

.view-btn.show {
  background-image: url("data:image/svg+xml,%0A%3Csvg width='26' height='18' viewBox='0 0 26 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24.9278 9C22.9373 12.8765 19.0136 17 13.4762 17C7.93882 17 3.76935 12.679 2 9C4.06424 5 7.93882 1 13.4762 1C19.0136 1 22.839 4.87654 24.9278 9Z' fill='%23F2F6FE'/%3E%3Ccircle cx='13.75' cy='9.25' r='3.75' fill='%23F2F6FE' stroke='%23242F50' stroke-width='3'/%3E%3Cpath d='M25 1L1 17' stroke='%23F2F6FE'/%3E%3C/svg%3E%0A");
  height: 18px;
  width: 26px;
}

.login-form-input input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(254, 254, 254, 0.3);
  opacity: 1;
}

.login-form-input input::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(254, 254, 254, 0.3);
  opacity: 1;
}

.login-form-input input:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(254, 254, 254, 0.3);
  opacity: 1;
  line-height: 28px;
  height: 28px;
}

.login-form-input input:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(254, 254, 254, 0.3);
  opacity: 1;
  line-height: 28px;
  height: 28px;
}

.login-form-input input:-webkit-autofill,
.login-form-input input:-webkit-autofill:hover,
.login-form-input input:-webkit-autofill:focus,
.login-form-input input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px #242f50 inset !important;
  color: #fff;
}

.profile-icon {
  position: absolute;
  top: 13px;
  right: 6px;
  display: block;
  width:  calcRem(40px);
  height:  calcRem(40px);
  background: #fcfdfe;
  border: 2px solid #242f50;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 4px rgba(36, 47, 80, 0.06);
  font-weight: 500;
  font-size: calcRem(12px);
  color: #1c243c;
  text-align: center;
  line-height:  calcRem(36px);
  .rs-dropdown-toggle-caret {
    display: none;
  }
  .rs-ripple-pond {
    width: calcRem(40px);
  }
  .rs-btn {
    border-radius: 50%;
    overflow: hidden;
    top:-1px;
  }
  .rs-dropdown-menu {
    right: -8px;
    left: auto !important;
    top: 130% !important;
    min-width: 288px;
    border:0;
    height: auto;
    padding: 0;
    background: #fcfdfe;
    border:1px solid #E9EAED;
    box-shadow: 0px 40px 42px -25px rgba(#000, 0.20);
    border-radius: 2px;
    li{
      display: block;
      line-height:24px;
      font-size: calcRem(16px);
      text-decoration: none;
      color: #1C243C;
      text-align: left;
      padding: 16px 16px;
      font-weight: 600;
      [class^="icon-"], [class*=" icon-"] {
        margin-right: 8px;
        display: inline-block;
        vertical-align: middle;
        width: 32px;
        height: 32px;
      }
      .icon-logout{
        background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 25.375C12.9375 24.6875 12.5625 24.3125 11.875 24.25H8.5C7.875 24.2188 7.34375 24 6.90625 23.5938C6.5 23.1563 6.28125 22.625 6.25 22V10C6.28125 9.37504 6.5 8.84379 6.90625 8.40629C7.34375 8.00004 7.875 7.78129 8.5 7.75004H11.875C12.5625 7.68754 12.9375 7.31254 13 6.62504C12.9375 5.93754 12.5625 5.56254 11.875 5.50004H8.5C7.21875 5.53129 6.15625 5.96879 5.3125 6.81254C4.46875 7.65629 4.03125 8.71879 4 10V22C4.03125 23.2813 4.46875 24.3438 5.3125 25.1875C6.15625 26.0313 7.21875 26.4688 8.5 26.5H11.875C12.5625 26.4375 12.9375 26.0625 13 25.375ZM27.7188 15.25C28.125 15.75 28.0938 16.25 27.625 16.75L21.6719 23.125C21.4219 23.375 21.1406 23.5 20.8281 23.5C20.5781 23.5 20.3281 23.3907 20.0781 23.1719C19.6094 22.6719 19.5938 22.1563 20.0312 21.625L24.25 17.125H12.5781C11.9219 17.0625 11.5625 16.6875 11.5 16C11.5625 15.3125 11.9219 14.9375 12.5781 14.875H24.25L20.0781 10.375C19.6719 9.84379 19.6875 9.32816 20.125 8.82816C20.6875 8.39066 21.2188 8.40629 21.7188 8.87504L27.7188 15.25Z' fill='%23325F97'/%3E%3C/svg%3E%0A");
      }
      .icon-switch-acc{
        background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M27.145 4.855C26.605 4.315 25.93 4.03 25.12 4H10.72C9.91 4.03 9.235 4.315 8.695 4.855C8.155 5.395 7.87 6.07 7.84 6.88V21.28C7.87 22.09 8.155 22.765 8.695 23.305C9.235 23.845 9.91 24.13 10.72 24.16H25.12C25.93 24.13 26.605 23.845 27.145 23.305C27.685 22.765 27.97 22.09 28 21.28V6.88C27.97 6.07 27.685 5.395 27.145 4.855ZM15.625 9.265C16.225 8.665 16.99 8.35 17.92 8.32C18.85 8.35 19.615 8.665 20.215 9.265C20.815 9.865 21.13 10.63 21.16 11.56C21.13 12.49 20.815 13.255 20.215 13.855C19.615 14.455 18.85 14.77 17.92 14.8C16.99 14.77 16.225 14.455 15.625 13.855C15.025 13.255 14.71 12.49 14.68 11.56C14.71 10.63 15.025 9.865 15.625 9.265ZM22.645 18.22C23.575 19 24.13 20.02 24.31 21.28H11.53C11.71 20.02 12.265 19 13.195 18.22C14.095 17.41 15.19 16.99 16.48 16.96H19.36C20.65 16.99 21.745 17.41 22.645 18.22ZM6.97 25.84H18V28H6.88C6.07 27.97 5.395 27.685 4.855 27.145C4.315 26.605 4.03 25.93 4 25.12V13.76H6.16V25.12C6.19 25.57 6.43 25.81 6.88 25.84H6.97Z' fill='%23325F97'/%3E%3C/svg%3E%0A");
      }
      a{
        font-weight: 600;
        padding: 0;
        color: #1C243C;
      }
      & + li{
        border-top: 0.5px solid #E9EAED;
      }
      .rs-dropdown-item-focus, &.rs-dropdown-item:focus-visible {
        background: none!important;
        color: #325f97!important;
      }
    }
  }
  button {
    color: #1c243c;
    font-size: calcRem(12px);
    font-weight: 600;
    width: 100%;
    padding: 0 3px!important;
    overflow: hidden;
    justify-content: center;
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 auto;
    text-transform: uppercase;
    &:hover{
      font-size: calcRem(12px);
    }
  }
  @media (min-width:$macBookPro){
    top:5px;
    right: 0;
  }
}

.profile-icon img {
  width: 100%;
  height: 100%;
}

.nav-top {
  padding-top: 15px;
  min-height: 48px;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  &-title {
    flex-grow: 2;
    font-size: calcRem(18px);
  }
}

.nav-top .h1,
.nav-top h1 {
  margin-bottom: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(100vw - 280px);
  @media (max-width: 1023px) {
    max-width: calc(100vw - 260px);
  }
}

.page-section {
  min-height: var(--app-height);
  min-height: 100vh;
  height: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  background: #f2f6fe;
  padding: 0 0 25px 0;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .page-section {
    height: 100vh;
  }
}

.page-section > .container {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  flex-direction: column;
  width: 100%;
  display: flex;
  padding-top: 25px;
}

.page-section > .container > .row {
  height: 100%;
  flex: 1;
  position: relative;
}

.page-content {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.page-content > .page-content-wrap {
  display: flex;
  flex-direction: column;
  flex: 1;
  & > p {
    margin-bottom: 10px;
  }
  & > h2 {
    margin-bottom: 20px;
    font-size: 22px;
    line-height: 28px;
  }
  & > h3 {
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 23px;
  }
  & > h1,
  h2,
  h3 {
    font-weight: 500;
    color: #242f4f;
  }
  & > h1 {
    font-size: 32px;
    line-height: 40px;
  }
}

.page-all-scans-content {
  .input-search {
    max-width: 733px;
    margin-left: 0;
    @media (max-width: 1279px) {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }
  .nav-top_wrap {
    @media (max-width: 1279px) {
      .filter-btn {
        margin-left: 0;
      }
    }
  }
  @media (max-width: 1179px) {
    .pannel-wrap {
      flex-wrap: wrap;
    }
    .table-options  {
      width: 100%;
    }
  }
}

.page-tasks-content {
  .input-search {
    max-width: 733px;
    margin-left: 0;
    @media (max-width: 1279px) {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }
}

.page-content-wrap {
  .nav-top_wrap {
    @media (max-width: 1279px) {
      .row {
        flex-wrap: wrap;
      }
      .input-search {
        margin-bottom: 20px;
      }
    }
  }
}

.page-content-main {
  height: 100%;
  background: #fcfdfe;
  border-radius: 0 0 10px 10px;
  display: flex;
  flex-direction: column;
  .tab-content {
    flex: 1;
    > .rs-panel {
      height: 100%;
      display: flex;
      flex-direction: column;
      .rs-panel-body {
        display: flex;
        flex-direction: column;
        flex: 1;
      }
    }
  }
}

.sidebar {
  background: #fcfdfe;
  -webkit-flex: 0 0 120px;
  -ms-flex: 0 0 120px;
  flex: 0 0 120px;
  max-width: 120px;
  padding: 22px 12px 10px 12px;
  margin-right: 34px;
  box-shadow: 0px 0px 10px 4px rgba(36, 47, 80, 0.03);
  border-radius: 10px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-height: 100vh;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 800px;
  @media (max-width: 1023px) {
    margin-right: 24px;
    -webkit-flex: 0 0 105px;
    -ms-flex: 0 0 105px;
    flex: 0 0 105px;
    max-width: 105px;
  }
  &-logo {
    margin-bottom: 34px;
    text-align: center;
  }
  &-po-id {
    margin-left: auto;
    margin-right: auto;
    font: 400 14px 'Poppins';
    line-height: 16.8px;
    color: #1c243c;
    opacity: 0.5;
  }
  @media (min-width: $macBookPro) {
    flex: 0 0 150px;
    max-width: 150px;
    min-height: 965px;
    &-logo {
      img{
        width: 110px;
      }
    }
  }
}

.sidebar-nav {
  padding: 57px 0 20px;
}

.sidebar-nav ul > li {
  text-align: center;
  padding: 0 10px;
  @media (max-width: 1023px) {
    padding: 0;
  }
}

.sidebar-nav ul > li + li {
  margin-top: 32px;
  @media(min-width:$macBookPro){
    margin-top: 40px;
  }
}

.sidebar-nav a {
  font-weight: 600;
  display: block;
  font-size: 12px;
  cursor: pointer;
  color: #242f50;
  @media (min-width: $macBookPro) {
    font-size: 16px;
  }
}

.sidebar-nav .icon {
  width: 41px;
  height: 41px;
  display: table;
  margin: 0 auto 10px;
  &.billing-icon {
    width: 40px;
    height: 40px;
  }
  &.comments-icon {
    width: 40px;
    height: 40px;
  }
  @media (min-width: $macBookPro) {
    width: 51px;
    height: 51px;
    &.billing-icon {
      width: 50px;
      height: 50px;
    }
    &.comments-icon {
      width: 50px;
      height: 50px;
    }
  }
}

.dashboard-icon {
  background: url('../assets/svg/sprite-dashboard.svg') no-repeat 0 0;
  @media (min-width: $macBookPro) {
    background-size: 51px auto;
  }
}

a.active .dashboard-icon,
a:hover .dashboard-icon {
  background: url('../assets/svg/sprite-dashboard.svg') no-repeat 0 -41px;
  @media (min-width: $macBookPro) {
    background: url('../assets/svg/sprite-dashboard.svg') no-repeat 0 -51px;
    background-size: 51px auto;
  }
}
.scans-icon {
  background: url('../assets/svg/sprite-dashboard.svg') no-repeat 0 -82px;
  @media (min-width: $macBookPro) {
    background: url('../assets/svg/sprite-dashboard.svg') no-repeat 0 -101px;
    background-size: 51px auto;
  }
}
.help-icon {
  background: url('../assets/svg/sprite-dashboard.svg') no-repeat 0 -325px;
  @media (min-width: $macBookPro) {
    background: url('../assets/svg/sprite-dashboard.svg') no-repeat 0 -404px;
    background-size: 51px auto;
  }
}
.patients-icon {
  background: url('../assets/svg/sprite-dashboard.svg') no-repeat 0 -123px;
  @media (min-width: $macBookPro) {
    background: url('../assets/svg/sprite-dashboard.svg') no-repeat 0 -152px;
    background-size: 51px auto;
  }
}
.preferences-icon {
  background: url('../assets/svg/sprite-dashboard.svg') no-repeat 0 -164px;
  @media (min-width: $macBookPro) {
    background: url('../assets/svg/sprite-dashboard.svg') no-repeat 0 -203px;
    background-size: 51px auto;
  }
}
.billing-icon {
  background: url('../assets/svg/sprite-dashboard.svg') no-repeat 0 -365px;
  @media (min-width: $macBookPro) {
    background: url('../assets/svg/sprite-dashboard.svg') no-repeat 0 -454px;
    background-size: 51px auto;
  }
}
.tasks-icon {
  height: 40px!important;
  background: url('../assets/svg/sprite-dashboard.svg') no-repeat 0 -485px;
  @media (min-width: $macBookPro) {
    height: 50px!important;
    background: url('../assets/svg/sprite-dashboard.svg') no-repeat 0 -604px;
    background-size: 51px auto;
  }
}
.comments-icon {
  background: url('../assets/svg/sprite-dashboard.svg') no-repeat 0 -565px;
  @media (min-width: $macBookPro) {
    background: url('../assets/svg/sprite-dashboard.svg') no-repeat 0 -702px;
    background-size: 51px auto;
  }
}
a.active .preferences-icon,
a:hover .preferences-icon {
  background: url('../assets/svg/sprite-dashboard.svg') no-repeat 0 -204px;
  @media (min-width: $macBookPro) {
    background: url('../assets/svg/sprite-dashboard.svg') no-repeat 0 -254px;
    background-size: 51px auto;
  }
}

a.active .scans-icon,
a:hover .scans-icon {
  background: url('../assets/svg/sprite-dashboard.svg') no-repeat 0 -285px;
  @media (min-width: $macBookPro) {
    background: url('../assets/svg/sprite-dashboard.svg') no-repeat 0 -354px;
    background-size: 51px auto;
  }
}

a.active .patients-icon,
a:hover .patients-icon {
  background: url('../assets/svg/sprite-dashboard.svg') no-repeat 0 -244px;
  @media (min-width: $macBookPro) {
    background: url('../assets/svg/sprite-dashboard.svg') no-repeat 0 -304px;
    background-size: 51px auto;
  }
}

a.active .billing-icon,
a:hover .billing-icon {
  background: url('../assets/svg/sprite-dashboard.svg') no-repeat 0 -405px;
  @media (min-width: $macBookPro) {
    background: url('../assets/svg/sprite-dashboard.svg') no-repeat 0 -504px;
    background-size: 51px auto;
  }
}
a.active .tasks-icon,
a:hover .tasks-icon {
  background: url('../assets/svg/sprite-dashboard.svg') no-repeat 0 -445px;
  @media (min-width: $macBookPro) {
    background: url('../assets/svg/sprite-dashboard.svg') no-repeat 0 -553px;
    background-size: 51px auto;
  }
}
a.active .comments-icon,
a:hover .comments-icon {
  background: url('../assets/svg/sprite-dashboard.svg') no-repeat 0 -525px;
  @media (min-width: $macBookPro) {
    background: url('../assets/svg/sprite-dashboard.svg') no-repeat 0 -653px;
    background-size: 51px auto;
  }
}
.modelInfo-hint {
  width: 100%;
  font-size: 24px;
  color: #fff;
  text-align: center;
  padding: 10px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  line-height: 1.1;
  background: #242f50;
  * {
    margin: 0 5px;
  }
  a {
    font-weight: 700;
    text-decoration: underline;
    color: #fff;
    margin-right: 0;
    &:hover {
      text-decoration: none;
    }
  }
  & + .container .page-content .dashboard-list {
    height: calc(100vh - 389px);
  }
  &-big {
    font-size: 20px;
    @media (max-width: 1600px) {
      font-size: 18px;
    }
    @media (max-width: 1400px) {
      font-size: 16px;
    }
  }
  @media (max-width: 1200px) {
    font-size: 20px;
    &-big {
      font-size: 15px;
    }
  }
  @media (max-width: 1023px) {
    font-size: 18px;
    padding: 5px;
    &-big {
      font-size: 13px;
    }
  }
  @media (max-width: 800px) {
    font-size: 16px;
    padding: 5px;
    &-big {
      font-size: 12px;
    }
  }
}

.system-error {
  &_wrap {
    padding: 48px 0;
    margin: 0 auto;
    max-width: 640px;
    color: #040508;
  }
  &_figure {
    display: table;
    text-align: center;
    margin: 0 auto calcRem(24px);
    figcaption {
      line-height: 1.2;
      font-weight: 500;
      font-size: calcRem(18px);
    }
    img {
      width: calcRem(120px);
      margin-bottom: 40px;
    }
  }
  &_num {
    font-weight: 600;
    display: block;
    margin-bottom: calcRem(10px);
  }
  &_text {
    font-size: calcRem(16px);
    color: #040508;
    a {
      color: #325f97;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    p {
      margin-bottom: 22px;
    }
  }
}

.custom-menu {
  height: 0px;
  a {
    visibility: hidden;
    height: 0px;
  }
}

.bluring {
  position: absolute;
  opacity: 0.3;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: lightgrey;
  top: 0px;
}

@import 'rsuite/dist/rsuite.min.css';
@import 'ui.scss';
@import 'modal.scss';
@import 'tabs.scss';
@import 'dashboard.scss';
@import 'scan.scss';
@import 'onboarding.scss';
@import 'all-scan.scss';
@import 'payment.scss';
@import 'tasks.scss';
@import 'file-viewer.scss';
@import 'page-content.scss';
@import 'choose-account.scss';
