.tasks-filter-body {
  width: 100%;
  .col-6 {
    width: 50%;
    padding: 0px 12px;
  }
  .row {
    margin: 0 -12px;
    max-width: none;
    flex-wrap: wrap;
    .form-new-scan-group {
      flex: 0 0 50%;
      padding: 0 12px;
      max-width: 50%;
    }
    .comments-field-datePicker {
      .error-border{
        margin-bottom: 66px;
      }
      & + .rs-form-error-message-wrapper {
        position: relative;
        top: -55px;
        padding: 0 12px;
       .rs-form-error-message {
          color: $red;
          font-size: 13px;
          height: auto;
          letter-spacing: -0.2px;
          filter: none;
          border: 0;
          padding: 0 12px;
          border-radius: 0;
        }
      }
    }
  }
  .input-wrap {
    margin-bottom: 40px;
  }
  .form-new-scan-group {
    margin-bottom: 0;
    margin: 0;
    display: block;

    .rs-picker-toggle-wrapper .rs-picker-toggle {
      height: 56px;
      @media(min-width:$macBookPro){
        height: 64px;
      }
    }
    .rs-picker-toggle-wrapper .rs-picker-toggle .rs-picker-toggle-caret {
      background: none;
    }
    .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
      opacity: 0.5;
      font-size: calcRem(20px);
      width: calcRem(20px);
    }
    .rs-picker-date .rs-picker-toggle.rs-btn {
      padding-right: 11px;
      .rs-picker-toggle-caret {
        display: none;
      }
    }
    .anim-placeholder.show .tasks-filter-label,
    .anim-placeholder.focus .tasks-filter-label,
    .anim-placeholder .tasks-filter-label {
      font-size: calcRem(12px);
      top: -23px;
      color: #1c243c;
      font-weight: 600;
    }
    .rs-form-group {
      display: block;
    }
  }
}
.page-tasks-content .patient-panel.rs-panel {
  .rs-table-cell-content .rs-dropdown {
    width: 100%;
    .rs-dropdown-toggle {
      min-width: 100%;
      width: 100%;
    }
  }
}

.anim-placeholder.show .tasks-filter-label,
.anim-placeholder.focus .tasks-filter-label,
.anim-placeholder .tasks-filter-label {
  top: -16px;
  font-size: calcRem(12px);
  left: 0px;
}

.anim-placeholder.show .task-label,
.anim-placeholder.focus .task-label,
.anim-placeholder .task-label {
  font-size: calcRem(16px);
  color: #325f97;
  font-weight: 500;
  top: -16px;
}

.anim-placeholder .task-label {
  color: #7c8295;
  left: 0;
}
.content.disabled {
  pointer-events: none;
  opacity: 0.5 !important;
}

.task {
  &-wrapper {
    display: flex;
    flex-direction: column;
  }
  &-content {
    flex: 1;
    padding: 24px;
    background: #fcfdfe;
    border-radius: 8px;
    justify-content: space-between;
    align-items: flex-start;
    .text-info-input {
      padding-top: 8px;
      font-size: calcRem(14px);
      color: rgba(#040508, 0.5);
    }
    .content-data {
      .row {
        margin: 0;
        &.rs-radio-group{
          flex-direction: row;
          .content-data-filed.rs-radio{
            width: auto;
            margin-bottom: 32px;
          }
        }
      }

      &-pdf_content {
        margin-bottom: 40px;
        .item {
          display: flex;
          align-items: center;
          margin-top: 24px;
        }
        span {
          font-size: 16px;
          line-height: 1.2;
          letter-spacing: -0.01em;
          text-decoration-line: underline;
          color: #325f97;
        }
        .preview {
          background: #e9eaed;
          box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.2);
          width: 64px;
          height: 82px;
          flex: 0 0 64px;
          text-align: center;
          margin-right: 25px;
          overflow: hidden;
          img {
            max-width: 54px;
          }
        }
      }
      &-filed {
        margin-bottom: 24px;
        &_content {
          color: #1c243c;
          min-height: 48px;
          font-size: 16px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        }
        .label {
          display: block;
          font-size: 16px;
          margin-bottom: 8px;
          color: #040508;
          font-weight: 600;
        }
      }
    }
    &_form {
      padding: 0 48px 0 0;
      flex: 1;
      .text{
        font-weight: 400;
        font-size: calcRem(16px);
        margin-bottom: 16px;
        line-height: 1.4;
        color: $blackBlue;
      }
      .title {
        margin-bottom: calcRem(8px);
        font-size: calcRem(16px);
        line-height: 1.4;
        text-align: left;
        font-weight: 600;
        color: #242F4F;
      }
      .col-12 {
        &.error {
          .rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn {
            border-color: #ff3554;
          }
          .title {
            color: rgba($red, 0.5);
          }
        }
      }
      .error-text {
        color: $red;
        flex: 0 0 100%;
        max-width: 100%;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.4;
      }
      .form-new-scan-divider {
        text-align: center;
        margin: 20px 0 28px;
        display: block;
      }
      .form-new-scan-uploader {
        margin: 0 0 64px;
        text-align: left;
        button.rs-uploader-trigger-btn{
          .icon-upload{
            width: 19px;
            height: 18px;
            background-image: url("data:image/svg+xml,%0A%3Csvg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.5 12H12.5V13.5H6.5V12ZM6.5 9H12.5V10.5H6.5V9ZM11 1.5H5C4.175 1.5 3.5 2.175 3.5 3V15C3.5 15.825 4.1675 16.5 4.9925 16.5H14C14.825 16.5 15.5 15.825 15.5 15V6L11 1.5ZM14 15H5V3H10.25V6.75H14V15Z' fill='%23FEFEFE'/%3E%3C/svg%3E%0A");  
          }
        }
        .rs-uploader-file-item-content {
          .dots__list {
            top: 110%;
            position: absolute;
            right: 0;
          }
          .dots {
            position: absolute;
            right: -7px;
            top: 21px;
            z-index: 999;
            height: 40px;
            width: 40px;
          }
          .dots__text {
            top: 0;
            height: 40px;
            width: 40px;
            line-height: 40px;
            font-size: 34px;
            text-align: center;
            right: -12px;
          }
        }
        .rs-uploader-file-item-content > span {
          flex: 1;
          max-width: none;
          &:nth-child(2) {
            color: #0F2ED9;
            text-decoration: underline;
          }
        }
        .rs-uploader-file-item {
          padding: 25px 0;
          margin: 0 !important;
          .preview {
            background: #e9eaed;
            box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.2);
            width: calcRem(64px);
            height: calcRem(82px);
            flex: 0 0 calcRem(64px);
            text-align: center;
            margin-right: 25px;
            overflow: hidden;
            img {
              max-width: calcRem(54px);
            }
          }
        }
        .uploader-delete-btn {
          text-decoration: underline;
          margin-right: 0px;
          color: #0F2ED9;
          .rs-icon {
            display: inline-block;
            margin-right: 6px;
            vertical-align: middle;
            width: 24px;
            height: 24px;
            position: relative;
            top:-3px;
            &::before{
              content: "";
            }
          }
        }
        .title {
          margin-bottom: calcRem(8px);
          font-size: calcRem(16px);
          line-height: 1.4;
          font-weight: 500;
          text-align: left;
          color: $blackBlue;
        }
      }
      button.rs-uploader-trigger {
        &-btn {
          width: 100%;
        }
      }
      .or {
        color: #8c919d;
        font-size: calcRem(20px);
        font-weight: 500;
        text-align: center;
        margin: 16px 0 24px;
      }
      .row {
        margin: 0 -8px;
        max-width: none;
        div.content-data-filed {
          width: 45%;
        }
      }
      .form-new-scan-group {
        margin: 0 8px 24px;
        // margin-bottom: 24px !important;
        .anim-placeholder.error label {
          opacity: 1;
        }
        .input-wrap .rs-form-group .rs-form-control-wrapper .rs-form-error-message {
          padding-top: 5px;
          top: 105%;
          bottom: auto;
        }
        .input-wrap {
          margin-bottom: 0;
          .rs-input {
            height: 56px;
            @media(min-width:2400px){
              height: 64px;
            }
          }
        }
        .textarea {
          margin-top: 0;
          .task-label {
            top: -26px;
            display: block;
            font-size: calcRem(16px);
            margin-bottom: 8px;
            color: #242F4F;
            font-weight: 600;
          }
        }
        &.rs-form-group textarea.rs-input {
          height: 152px;
          margin: 0;
        }
      }
      .rs-radio-group {
        margin-bottom: 10px;
        .title {
          color: #7c8295;
          font-size: calcRem(16px);
          font-weight: 500;
          margin-bottom: 18px;
        }
        .rs-radio-wrapper::before,
        .rs-radio-wrapper .rs-radio-inner::before {
          width: 20px;
          height: 20px;
          background: #f6f7f8;
        }
        .rs-checkbox-wrapper::before,
        .rs-checkbox-wrapper .rs-checkbox-inner::before {
          width: 18px;
          height: 18px;
          background: #f6f7f8;
        }
        .rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before,
        .rs-radio-checked .rs-radio-wrapper .rs-radio-inner::before {
          border: 1px solid #d9d9d9;
        }
        .rs-radio-wrapper .rs-radio-inner::after {
          background: #7C8295;
          width: 8px;
          height: 8px;
          margin-top: 6px;
          margin-left: 6px;
        }
        .rs-radio-disabled.rs-radio-checked .rs-radio-wrapper .rs-radio-inner::after {
          opacity: 0.3;
        }
        .rs-checkbox-wrapper .rs-checkbox-inner::after {
          border: solid #242f4f;
          border-width: 0 3px 3px 0;
          width: 9px;
          height: 16px;
          margin-top: -3px;
          margin-left: 7px;
        }
        .rs-radio-wrapper {
          width: 20px;
          height: 20px;
        }
        .rs-radio + .rs-radio {
          margin-left: 24px;
        }
        .rs-radio-checker {
          padding-left: 40px;
        }
      }
      .form-group-radio{
        margin-bottom: 54px!important;
        .title{
          margin-bottom: 16px;
          font-weight: 500;
        }
        &-content{
          textarea.rs-input{
            height: 56px!important;
          }
        }
      }
    }
    @media (max-width:1023px){
      flex-wrap: wrap;
      .task-content_form{
        padding-right: 0;
      }
      .task-details{
        margin-top: 40px;
        flex:0 0 100%;
        max-width: 100%;
      }
    }
  }
  &-details {
    flex: 0 0 600px;
    max-width: 600px;
    border: 1px solid #dddfe4;
    border-radius: 8px;
    padding-bottom: 16px;
    @media (max-width: 1479px) {
      flex: 0 0 450px;
      max-width: 450px;
    }
    @media (max-width: 1379px) {
      flex: 0 0 400px;
      max-width: 400px;
    }
    &_tabs {
      height: 100%;
      .tabs-panel {
        margin: -1px -1px 0 0;
      }
      &_item {
        font-size: calcRem(14px);
        padding: 28px 24px 20px;
        .text-wrap {
          padding-bottom: 16px;
          border-bottom: 1px solid #dddfe4;
          margin-bottom: 20px;
        }
        .wrap-scroll {
          max-height: 202px;
          font-size: 14px;
          padding-right: 12px;
          overflow-y: scroll;
        }
        .item {
          & + .item {
            margin-top: 22px;
          }
        }
        .title {
          margin-bottom: 10px;
          font-weight: 600;
          font-size: calcRem(16px);
          line-height: 1.2;
          letter-spacing: -0.01em;
          color: #040508;
        }
        .row {
          & + .row {
            margin-top: 10px;
          }
        }
        .th {
          min-width: 160px;
          margin-right: 12px;
          color: rgba(#040508, 0.5);
          text-transform: capitalize;
        }
        .td{
          color:#040508;
          word-break: break-word;
        }
        a{
          color:#325f97;
          text-decoration: underline;
          &:hover{
            text-decoration: none;
          }
        }
        .status {
          padding: 2px 4px;
          background: #e4ebf7;
          border-radius: 4px;
          color: #7c8295;
          &.close {
            background: #f2f2f4;
            color: #505972;
          }
          &.completed {
            background: #e4ede8;
            color: #244f33;
          }
        }
      }
    }
    &_preview{
      margin: 0 -8px;
      &_pdf{
        overflow: hidden;
        max-width: 132px;
        box-shadow: 0px 0px 10px 4px rgba(36, 47, 80, 0.03);
        border-radius: 4px;
        border:1px solid #69757c;
        img{
          margin: -1px;
          max-width: calc(100% + 2px);
          display: block;
        }
      }
      .col{
        min-width: 160px;
        padding: 0 8px;
      }
      .th{
        min-width: 155px;
        margin: 0 0 16px;
      }
    }
    .rs-nav-tabs {
      ul {
        width: 100%;
      }
      .rs-nav-item {
        flex: 1;
        max-width: none;
        a {
          font-size: 18px;
        }
        .rs-nav-item-content {
          padding: 17px 10px;
        }
      }
    }
  }
  &-form {
    &_reviewer,
    &_pending {
      padding-top: 30px;
    }
    &_report {
      .form-new-scan-group,
      .form-new-scan-group.form-new-task-textarea {
        margin-bottom: 44px !important;
      }
    }
    &_prior{
      padding-bottom: 16px;
      padding-top: 8px;
      .title{
        margin-bottom: 24px;
      }
      .form-new-scan-group{
        margin-left: 0;
        margin-right: 0;
        .anim-placeholder.focus.error label,
        .anim-placeholder.error label{
          color:#C2002B;
          opacity: 1!important;
        }
        .task-label{
          color: #242F4F;
        }
        &.form-new-task-textarea{
          margin-bottom: 40px!important;
          textarea.rs-input{
            height: 80px;
          }
        }
      }
      .form-new-scan-divider{
        font-size: calcRem(20px);
        margin: 10px 0 24px;
      }
      .form-new-scan-uploader{
        button.rs-uploader-trigger-btn .icon-upload{
          display: none;
        }
        .title{
          margin-bottom: 16px;
          color:#242F4F;
        }
      }
    }
  }
  &-status-button{
    color:#383D46;
  }
}

.imaging-info{
  font-size: calcRem(16px);
  margin-bottom: 54px;
  .title{
    margin-bottom: 16px;
    font-weight: 500;
    font-size: calcRem(16px);
    line-height: 140%;
    color: #242F4F;
  }
  .row{
    margin: 0 0 8px;
  }
  .td{
    color:#040508;
  }
  .th{
    min-width: calcRem(224px);
    font-size: calcRem(16px);
    color: rgba(#040508,0.5);
  }
  & + .form-new-scan-group{
    margin-bottom: 46px!important;
  }
}

.preview-pdf {
  // max-height: 60vh;
  &_nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &_name {
      color: #325f97;
      text-decoration: underline;
      margin-right: 12px;
      word-break: break-all;
    }
    .arrow-nav {
      width: 32px;
      height: 32px;
      flex: 0 0 32px;
      &:hover {
        opacity: 0.7;
      }
      &.disabled {
        opacity: 0.5;
      }
    }
    .arrow-left {
      background-image: url("data:image/svg+xml,%0A%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.4393 10.5607C10.8536 9.97494 10.8536 9.02519 11.4393 8.4394C12.0251 7.85362 12.9749 7.85362 13.5607 8.4394L11.4393 10.5607ZM19 16.0001L20.0607 14.9394C20.342 15.2207 20.5 15.6022 20.5 16.0001C20.5 16.3979 20.342 16.7794 20.0607 17.0607L19 16.0001ZM13.5607 23.5607C12.9749 24.1465 12.0251 24.1465 11.4393 23.5607C10.8536 22.9749 10.8536 22.0252 11.4393 21.4394L13.5607 23.5607ZM13.5607 8.4394L20.0607 14.9394L17.9393 17.0607L11.4393 10.5607L13.5607 8.4394ZM20.0607 17.0607L13.5607 23.5607L11.4393 21.4394L17.9393 14.9394L20.0607 17.0607Z' fill='%23242F50'/%3E%3C/svg%3E%0A");
      transform: rotate(180deg);
    }
    .arrow-right {
      background-image: url("data:image/svg+xml,%0A%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.4393 10.5607C10.8536 9.97494 10.8536 9.02519 11.4393 8.4394C12.0251 7.85362 12.9749 7.85362 13.5607 8.4394L11.4393 10.5607ZM19 16.0001L20.0607 14.9394C20.342 15.2207 20.5 15.6022 20.5 16.0001C20.5 16.3979 20.342 16.7794 20.0607 17.0607L19 16.0001ZM13.5607 23.5607C12.9749 24.1465 12.0251 24.1465 11.4393 23.5607C10.8536 22.9749 10.8536 22.0252 11.4393 21.4394L13.5607 23.5607ZM13.5607 8.4394L20.0607 14.9394L17.9393 17.0607L11.4393 10.5607L13.5607 8.4394ZM20.0607 17.0607L13.5607 23.5607L11.4393 21.4394L17.9393 14.9394L20.0607 17.0607Z' fill='%23242F50'/%3E%3C/svg%3E%0A");
    }
  }
  .preview-content {
    max-height: 53vh;
    overflow: auto;
  }
}
