.scan-info-header {
  height: 100%;
  max-height: 112px;
  & span.sub-title {
    margin-left: 25px;
  }
  .rs-dropdown-menu{
    max-height: 400px;
    overflow-y: auto;
  }
  .justify-content-between {
    > div {
      flex: 1;
      max-width: 50%;
      padding-right: 15px;
    }
  }
  @media (max-width: 1023px) {
    max-height: none;
    flex: 0 0 auto;
    height: auto;
    .justify-content-between {
      flex-wrap: wrap;
      > div {
        flex: 0 0 100%;
        max-width: 100%;
        & + div{
          margin-top: 20px;
        }
        &:nth-child(1) {
          padding-right: 150px;
          margin-bottom: 0;
        }
        .rs-dropdown{
          margin-top: 0!important;
        }
      }
      .rs-btn-state {
        position: absolute;
        top: 20px;
        right: 20px;
      }
    }
    .pannel-wrap{
      padding: 20px 20px 16px;
    }
  }
  .rs-panel-body {
    padding-top: 20px;
  }
  &,
  .rs-panel-body {
    .pannel-wrap {
      margin: -24px;
      .btn-add {
        flex: 0 0 auto;
      }
    }
    .btn-state {
      font-size: calcRem(12px);
      padding: 8px 8px 8px 30px;
      min-width: 108px;
      &.sent {
        text-align: right;
      }
    }
    .h1 {
      margin-bottom: 14px;
      @media (max-width: 1023px) {
        margin-bottom: 10px;
      }
    }
  }
  .sub-title {
    font-size: calcRem(16px);
    color: #8c919d;
    font-weight: 400;
  }
}

.page-scan-tab {
  &_title {
    margin-bottom: 30px;
    font-weight: 500;
    font-size: calcRem(24px);
    color: $baseColor;
  }
  &_nodata {
    padding: 10px 15px;
    color: #8c919d;
    font-size: calcRem(24px);
    line-height: 1.4;
    @media (max-width: 1279px) {
      font-size: 18px;
    }
  }
}
.no-comment {
  color: #1c243c;
  margin: 5rem auto 6rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  p {
    opacity: 0.5;
  }
  img {
    margin-bottom: 16px;
  }
}

.scan-info-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .tab-content {
    height: 100%;
    position: relative;
    .rs-table-cell-content {
      padding: 5px 15px;
    }
    .rs-dropdown {
      position: absolute;
      right: 16px;
      font-size: 14px;
      top: 15px;
      .rs-dropdown-menu {
        width: 100%;
      }
      .rs-btn-subtle {
        text-decoration: underline;
        color: #325f97;
      }
      &:hover .rs-btn-subtle {
        text-decoration: none;
      }
    }
    .rs-panel {
      margin-bottom: 0;
      padding-top: 16px;
      height: 100%;
    }
  }
  .tabs-panel .rs-nav-item {
    font-size: calcRem(20px);
    font-weight: 500;
    @media (max-width: 1479px) {
      font-size: 16px;
    }
    @media (max-width: 1279px) {
      font-size: 14px;
    }
    @media(max-width:1090px){
      min-width: 175px;
    }
    @media (max-width: 860px) {
      font-size: 13px;
    }
  }
  .tabs-panel{
    @media(max-width:1090px){
      .rs-nav{
        overflow-x: scroll;
        max-width: calc(100vw - 159px);
        &::-webkit-scrollbar {
          height: 5px;
          background: rgba(140, 145, 157, 0.3);
        }
      }
    }
  }
  .tab-content > .rs-panel > .rs-panel-header {
    display: none;
  }
  .rs-panel .table-component {
    margin: 0;
    padding: 0 20px;
  }
}

.page-comment {
  padding-top: 26px;
  .input-wrap,
  .rs-form {
    margin-bottom: 16px;
  }
  .form-checkbox {
    font-size: calcRem(14px);
    color: #1c243c;
    margin-right: 32px;
    label {
      position: relative;
      padding-left: 30px;
      line-height: 18px;
      display: inline-block;
      cursor: pointer;
      &::before {
        content: '';
        position: absolute;
        width: 18px;
        height: 18px;
        left: 0;
        top: -1px;
        background-color: #fff;
        border: 1px solid rgba(#242f50, 0.5);
        border-radius: 3px;
        background-repeat: no-repeat;
      }
    }
  }
  .btn-list {
    align-items: center;
    justify-content: flex-end;
    .btn-edit {
      margin-right: 20px;
    }
    .rs-btn {
      font-weight: 600;
      min-width: calcRem(166px);
    }
    &.disabled {
      opacity: 0.3;
      pointer-events: none;
    }
  }
  .textarea {
    margin-top: 0;
  }
  .rs-form-control-wrapper {
    display: block !important;
    textarea {
      resize: none;
      display: block;
      width: 100%;
      min-height: 70px;
      height: 100%;
    }
  }
  @media(min-width: 2400px){
    padding-top: 40px;
  }
}

.comments-list {
  position: relative;
  &::before {
    content: '';
    left: -25px;
    right: -25px;
    height: 1px;
    background: rgba(#8c919d, 0.2);
    position: absolute;
    top: 0;
  }
  &_item {
    font-size: calcRem(16px);
    padding: 18px 0;
    border-bottom: 1px solid rgba(#8c919d, 0.25);
    .rs-form-control-wrapper textarea {
      height: 54px;
      padding: 12px 11px;
      min-height: auto;
    }
    .rs-form {
      margin-bottom: 0;
    }
    .input-wrap {
      margin-top: 20px;
    }
    &:last-child {
      border-bottom: 0;
    }
    .name {
      margin-right: 10px;
      font-size: calcRem(14px);
      font-weight: 700;
    }
    .state {
      background: #f7edd2;
      padding: calcRem(4px) calcRem(8px);
      font-size: calcRem(12px);
      margin-right: 16px;
      border-radius: 5px;
      line-height: 18px;
      &-team {
        background: #fde0e6;
      }
    }
    &.admin .state {
      background: #e6eff7;
    }
    .head {
      justify-content: space-between;
    }
    .box {
      display: flex;
      margin-bottom: 10px;
      align-items: center;
    }
    time {
      opacity: 0.75;
      font-size: calcRem(12px);
    }
  }
}

.comments-list{
  .rs-btn-edit, .rs-btn-remove, .btn-edit, .btn-remove{
    font-size: calcRem(15px);
  }
}

.page-content-data {
  &_item {
    .h2 + .scan-popup-confirmation-prescription {
      margin: -8px 16px 8px;
    }
    .scan-popup-confirmation-label,
    .scan-popup-confirmation-area {
      font-size: calcRem(16px);
      width: auto;
      margin: 0 0 32px;
    }
    .scan-popup-confirmation-content {
      padding: 0 16px;
    }
    ul {
      margin-bottom: 16px;
      display: flex;
      flex-wrap: wrap;
      b {
        white-space: nowrap;
      }
      li {
        flex: 0 0 33.333%;
        margin-bottom: 16px;
        display: flex;
        padding: 8px 16px 16px;
        font-size: calcRem(16px);
        @media (max-width: 1279px) {
          flex: 0 0 50%;
          font-size: 14px;
          max-width: 50%;
          margin-right: 0 !important;
        }
        @media(max-width:1023px){
          flex:0 0 100%;
          max-width: 100%;
        }
      }
    }
    .h2 {
      margin-bottom: 16px;
      padding: 16px;
      font-size: calcRem(16px);
      color: $baseColor;
      line-height: 1.4;
      background: #f2f6fe;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      @media(min-width: 2400px){
        font-size: calcRem(18px);
      }
    }
  }
}

.edit-scan_details {
  width: calc(100% - 50px);
  top: 4%;
  @media (min-width: 1300px) {
    width: calc(100% - 100px);
  }
  @media (min-width: 1600px) {
    width: calc(100% - 300px);
  }
  @media (min-height: 800px) {
    .rs-modal-body {
      max-height: 80vh !important;
    }
  }
  .rs-modal-footer {
    padding-right: 40px;
  }
  .page-content-data_item .h2 {
    margin-bottom: 26px;
  }
  .rs-modal-body {
    padding-top: 8px;
  }
  .rs-modal-header {
    margin: 0 16px;
  }
  .textarea {
    margin-top: 35px;
  }
  .input-wrap {
    margin-bottom: 15px;
  }
  .rs-form-control-wrapper {
    display: block !important;
    & > .rs-input {
      height: 160px;
      width: 100%;
    }
  }
  .rs-uploader-trigger-btn {
    width: 360px;
    margin: 0 auto;
  }
  .rs-form {
    overflow-x: hidden !important;
  }
}

.page-content-data_item{
  .rs-btn-edit, .rs-btn-remove, .btn-edit, .btn-remove{
    font-size: calcRem(14px);
  }
}

.file-viewer .rs-modal-body {
  max-height: 80vh !important;
}

.page-scan-details .scan-popup-confirmation-uploader-content {
  margin: 0;
  padding: 0;
}
.filter-btn {
  height: 58px;
  background: transparent;
  min-width: 251px;
  border-radius: 30px;
  font-size: calcRem(18px);
  line-height: 27px;
  position: relative;
  margin-left: 26px;
  display: flex;
  color: #1c243c;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-left: calcRem(40px);
  &::before {
    content: '';
    width: calcRem(28px);
    height: calcRem(24px);
    position: absolute;
    left: 7px;
    top: 50%;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='28' height='24' viewBox='0 0 28 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='4.29498' y1='24' x2='4.29498' stroke='%231C243C'/%3E%3Ccircle cx='4' cy='12' r='3.5' fill='%23FCFDFE' stroke='%231C243C'/%3E%3Cline x1='14.295' y1='24' x2='14.295' stroke='%231C243C'/%3E%3Ccircle cx='14' cy='4' r='3.5' fill='%23FCFDFE' stroke='%231C243C'/%3E%3Cline x1='24.295' y1='24' x2='24.295' stroke='%231C243C'/%3E%3Ccircle cx='24' cy='20' r='3.5' fill='%23FCFDFE' stroke='%231C243C'/%3E%3C/svg%3E%0A");
    transform: translateY(-50%);
    background-size: 100% 100%;
  }
  &.active {
    color: #ffffff;
    background: #242f4f;
    &::before {
      background-image: url("data:image/svg+xml,%0A%3Csvg width='28' height='24' viewBox='0 0 28 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='4.29498' y1='24' x2='4.29498' stroke='%23FCFDFE'/%3E%3Ccircle cx='4' cy='12' r='3.5' fill='%23FCFDFE' stroke='%23DDDFE4'/%3E%3Cline x1='14.295' y1='24' x2='14.295' stroke='%23FCFDFE'/%3E%3Ccircle cx='14' cy='4' r='3.5' fill='%23FCFDFE' stroke='%23DDDFE4'/%3E%3Cline x1='24.295' y1='24' x2='24.295' stroke='%23FCFDFE'/%3E%3Ccircle cx='24' cy='20' r='3.5' fill='%23FCFDFE' stroke='%23DDDFE4'/%3E%3C/svg%3E%0A");
    }
  }
  @media(min-width:$macBookPro){
    &::before {

    }
  }
  span {
    padding: 0 6px;
    display: inline-block;
  }
  @media (max-width: 1379px) {
    font-size: 16px;
  }
  @media (max-width: 1279px) {
    font-size: 14px;
  }
}

.clear-filter-btn {
  color: #325f97;
  font-size:calcRem(16px);
  transition: opacity 0.3s ease-in;
  text-decoration: underline;
  opacity: 1;
  margin: 0 auto 0 10px;
  &:enabled:hover {
    opacity: 0.6;
  }
  @media(min-width:$macBookPro){
    font-size: calcRem(18px);
  }
  @media (max-width: 1279px) {
    margin-right: 0;
  }
}

.number-pages {
  margin: 0 32px;
  font-size: calcRem(16px);
  span:nth-child(1) {
    margin-right: 5px;
  }
  span:nth-child(2) {
    margin-left: 5px;
  }
  @media(min-width:$macBookPro){
    font-size: calcRem(17px);
  }
  @media (max-width: 1279px) {
    margin: 0 20px;
  }
  @media (max-width: 1023px) {
    margin: 0 15px;
    font-size: 14px;
  }
}
.filter-modal{
  .full-row{
    margin-bottom: 5px;
  }
  .rs-modal-footer{
    padding: 10px 16px 12px;
  }
  .filter-model-body {
    .comments-field-datePicker {
      margin-bottom: 0px!important;
    }
  }
}
.filter-model {
  position: fixed;
  top: 50%;
  width: 100%;
  z-index: 6;
  max-width: 800px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px -8px 8px 4px rgba(0, 0, 0, 0.05);
  padding: 29px 24px 45px;
  .rs-checkbox {
    &-wrapper {
      width: 18px;
      height: 18px;
      left: 2px;
      top: 11px;
    }
    &-checked {
      .rs-checkbox-checker .rs-checkbox-wrapper .rs-checkbox-inner::before {
        background: #ebecef;
      }
    }
    &-checker {
      padding-left: 32px;
      .rs-checkbox-wrapper .rs-checkbox-inner::before {
        background: none;
        width: 18px;
        background: none;
        border: 1px solid #96a3bc;
        height: 18px;
      }
      .rs-checkbox-wrapper .rs-checkbox-inner::after {
        background: none;
        border-color: #244f33;
        border-width: 0 3px 3px 0;
        transform: rotate(40deg) scale(1);
        width: 9px;
        height: 17px;
        margin-top: -5px;
        margin-left: 6px;
      }
    }
  }
  .rs-radio {
    &-checked .rs-radio-wrapper .rs-radio-inner::before {
      background: none;
    }
    &-wrapper .rs-radio-inner::before {
      border-color: #242f50;
    }
    &-checked .rs-radio-wrapper .rs-radio-inner::after {
      background: #242f50;
      width: 10px;
      height: 10px;
      margin-top: 4px;
      margin-left: 4px;
    }
    &-wrapper::before,
    &-wrapper .rs-radio-inner::before,
    &-wrapper .rs-radio-inner::after {
      width: 18px;
      height: 18px;
    }
    .rs-radio-checker:hover .rs-radio-wrapper .rs-radio-inner::before {
      border-color: #242f50;
    }
  }
  &-body {
    .input-wrap{
      margin-bottom: 18px;
    }
    .comments-field-datePicker {
      .error-border{
        margin-bottom: 50px;
      }
      .input-wrap{
        margin-bottom: 18px;
      }
      & + .rs-form-error-message-wrapper {
        position: relative;
        top: -40px;
        padding: 0 12px;
       .rs-form-error-message {
          color: $red;
          font-size: 14px;
          height: auto;
          letter-spacing: -0.2px;
          filter: none;
          border: 0;
          padding: 0 12px;
          border-radius: 0;
        }
      }
    }
    .col-6 {
      flex: 0 0 50%;
      max-width: 50%;
      margin: 0;
      padding: 0 12px 0 0;
      .title {
        margin-bottom: 24px;
      }
      .rs-checkbox-group,
      .rs-radio-group {
        display: flex;
        flex-wrap: wrap;
        .title {
          flex: 0 0 100%;
          max-width: 100%;
        }
        .rs-checkbox,
        .rs-radio {
          flex: 0 0 50%;
          max-width: 50%;
          label {
            font-size: 16px;
            @media (max-width: 1023px) {
              font-size: 14px;
            }
          }
        }
        .rs-radio:last-child {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }
    .row {
      &:last-child {
        margin: 0 -12px;
        max-width: none;
        .rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn {
          border: 1px solid #dddfe4;
          border-radius: 4px;
        }
        .rs-picker-toggle {
          padding: 8px 16px;
          font-size: 16px;
          line-height: 39px;
          &-caret {
            display: none;
          }
        }
        .rs-picker-toggle .rs-picker-toggle-placeholder {
          font-size: 16px;
        }
        .error-text {
          color: $red;
          flex: 0 0 100%;
          padding: 15px 0 0;
          max-width: 100%;
          font-size: 12px;
          font-weight: 400;
          line-height: 1.4;
        }
        .col-6 {
          padding: 0 12px;
          &.error {
            .rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn {
              border-color: #ff3554;
            }
            .title {
              color: #ff3554;
            }
          }
          .title {
            margin-bottom: 7px;
            color: #325f97;
            font-weight: 600;
          }
        }
      }
    }
  }
  &-header {
    margin-bottom: 28px;
  }
  &-footer {
    padding-top: 44px;
    display: flex;
    justify-content: flex-end;
    button {
      padding: 16px 24px;
      text-align: center;
      background: #242f50;
      border-radius: 40px;
      min-width: 180px;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      letter-spacing: 0.04em;
      color: #fefefe;
      transition: all 0.24s ease-in;
      border: 2px solid #242f50;
      &:hover {
        background: transparent;
        color: #242f50;
      }
    }
  }
  .title {
    color: $baseColor;
    font-size: 16px;
    margin-bottom: 34px;
    font-weight: 600;
    display: block;
    i {
      font-weight: 400;
      font-style: normal;
    }
  }
  .btn-close {
    background: none;
    color: $baseColor;
    padding: 0 0 0 30px;
    font-size: 16px;
    position: relative;
    transition: opacity 0.24s ease-in;
    &:hover {
      opacity: 0.6;
    }
    .rs-icon {
      font-size: 24px;
      position: absolute;
      left: 0;
      top: 0;
      color: $darkBlue;
    }
  }
  .btn-reset {
    color: #325f97;
    font-size: 16px;
    transition: opacity 0.3s ease-in;
    text-decoration: underline;
    opacity: 1;
    &:hover {
      opacity: 0.6;
      text-decoration: none;
    }
  }
}

.edit-scan_details {
  .form-new-scan {
    &:first-of-type {
      h2 {
        justify-content: flex-start;
      }
    }
  }
}

.page404 {
  width: 100%;
  height: calc(100vh - 100px);
  padding: 10% 30px 20px;
  .title {
    text-align: center;
    color: #242f4f;
    margin-bottom: 25px;
    font-weight: 700;
    font-size: 136px;
  }
  .text {
    text-align: center;
    font-size: 34px;
    color: #325f97;
    margin: 0 0 60px;
    font-weight: 500;
  }
  .rs-btn {
    background: #242f50;
    border-radius: 40px;
    height: 52px;
    font-size: 24px;
    margin: 0 auto;
    color: #fcfdfe;
    text-decoration: none;
    font-weight: 500;
    display: table;
    text-transform: uppercase;
    padding: 4px 45px;
    line-height: 1.7;
    transition: all 0.2s ease-in;
    border: 2px solid #242f50;
    &:hover {
      color: #242f50;
      background: #fcfdfe;
    }
    @media (max-width: 1600px) {
      font-size: 20px;
      padding: 6px 25px;
      overflow: initial;
    }
    @media (max-width: 1279px) {
      font-size: 16px;
      padding: 6px 16px;
    }
  }
}
