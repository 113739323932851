.rs-modal {
  top: 14%;
  @media (min-height: 760px) {
    top: 8%;
  }
  &-backdrop.rs-anim-in{
    background: rgba(36, 47, 80, 0.15);
    opacity: 1;
    backdrop-filter: blur(2px);
  }
  &-wrapper + .rs-modal-backdrop{
    z-index: 1050;
    & + .rs-modal-wrapper{
      z-index: 1051;
    }
  }
  &-sm {
    width: calcRem(640px);
  }
  &-xs {
    width: calcRem(437px);
  }
  &-md {
    width: calcRem(804px);
  }
  &-content {
    background: #fcfdfe;
    padding: 0;
    box-shadow: 0px 0px 8px rgba(35, 44, 81, 0.15);
    border-radius: 2px;
  }
  &-header {
    padding: 16px;
    border-bottom: 1px solid rgba(36, 47, 80, 0.3);
  }
  &-xs .rs-form {
    margin-bottom: 40px;
  }
  &-xs &-body .model-desk {
    font-size: calcRem(12px);
  }
  &-body {
    margin-top: 0;
    padding: 30px 16px 15px;
  }
  &-body .model-desk {
    font-size: calcRem(16px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1.6;
    max-width: calcRem(425px);
    min-height: 150px;
    padding-left: 22px;
    margin-bottom: 30px;
    color: rgba($baseColor, 0.5);
  }
  &-header &-title {
    font-weight: 600;
    font-size: calcRem(20px);
    color: $baseColor;
    line-height: 1.2;
  }
  &-footer {
    padding: 16px 16px 24px;
  }
  &-header-close {
    display: none;
  }
  &.panel-modal &-content {
    padding: 24px;
  }
  &.panel-modal .rs-panel-body {
    border: 1px solid #dddfe4;
    border-radius: 10px;
    padding: 16px 16px 10px 24px;
  }
  &.panel-modal .rs-panel {
    margin-bottom: 0;
    box-shadow: none;
  }
  &.panel-modal &-header {
    padding: 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 0;
  }
  &.panel-modal &-header .required {
    color: $baseColor;
    white-space: nowrap;
    display: inline-block;
    font: 400 12px/1.4 'Montserrat', sans-serif;
  }
  &.panel-modal &-body {
    padding: 0;
    margin-top: 20px;
  }
  &.panel-modal &-body .row {
    flex-wrap: wrap;
  }
  .rs-form-control-wrapper > .rs-input {
    border: 1px solid #dddfe4;
    background: #fcfdfe;
    padding: 8px;
    height: 56px;
    color: $blackBlue;
    font: 400 calcRem(16px)/1.5 'Poppins', sans-serif;
    @media(min-width:$macBookPro){
      height: 64px;
    }
  }
  .error-text {
    display: none;
  }
  .rs-form-group.error {
    position: relative;
    z-index: 3;
  }
  .rs-form-group.error .rs-input,
  .rs-form-group.error .rs-picker-select .rs-picker-toggle {
    border: 1px solid $red;
  }
  .rs-form-group.error .error-text {
    position: absolute;
    left: 0%;
    top: 50%;
    font-size: 12px;
    color: $red;
    display: block;
    text-align: center;
    font-weight: 600;
  }
  .rs-form-group.error .birthdate + .error-text {
    left: 0;
    top: -16px;
    font-weight: 400;
  }
  .rs-form-control-wrapper > .rs-input:focus {
    border-color: #325f97;
  }
  .rs-form-control-wrapper > .rs-input {
    @include placeholder {
      color: $baseColor;
      opacity: 0.5;
    }
  }
  
  .rs-form-group.error > .rs-input,
  &.rs-form-group.error .rs-input {
    @include placeholder {
      color: $red;
      opacity: 0.5;
    }
  }
  &.panel-modal .rs-form-control-wrapper > .rs-input-number,
  &.panel-modal .rs-form-control-wrapper > .rs-input {
    width: 239px;
    height: 39px;
    font: 400 14px/1.63 'Montserrat', sans-serif;
  }
  &.panel-modal .rs-picker-date .rs-picker-toggle.rs-btn {
    width: 239px;
    font-size: 14px;
    height: 39px;
    border: 1px solid $darkBlue;
    border-radius: 4px;
    font: 400 14px/1.63 'Montserrat', sans-serif;
  }
  .rs-form:not(.rs-form-inline) .rs-form-group:not(:last-child) {
    margin-bottom: 20px;
  }
  &.panel-modal .rs-form-control-label {
    color: $baseColor;
    font: 400 12px/1.4 'Montserrat', sans-serif;
    opacity: 0.5;
    display: block;
    margin-bottom: 12px;
  }
  &.panel-modal .rs-btn-toolbar {
    width: 100%;
    display: flex;
    margin-top: 20px;
    align-items: center;
    justify-content: space-between;
  }
  &.edit-panel-modal .rs-btn-toolbar {
    top: 100%;
    left: -24px;
    margin-top: 26px;
    right: -16px;
    width: auto;
    position: absolute;
  }
  &.edit-panel-modal &-body {
    padding-bottom: 140px;
  }
  .input-wrap{
    margin-bottom: 38px;
    .rs-form-group {
      .rs-form-error-message-wrapper .rs-form-error-message{
        height: 17px;
      }
    }
  }
  &-subtitle {
    display: block;
    color: $baseColor;
    font-size: calcRem(12px);
    line-height: 17px;
    font-weight: 600;
    margin: -15px 0 30px;
  }

  &.comment-modal {
    max-width: 1100px;
    width: 100%;
    padding-top: 54px;
    padding-bottom: 0;
    @media(min-width:2400px) {
      max-width: 1300px;
    }
    .model-comments_info{
      .item{
        font-size: 15px;
        @media(min-width: 2400px){
          font-size: 17px;
        }
      }
      @media(max-width:1279px) {
        flex-wrap: wrap;
        .item{
          padding-bottom: 10px;
        }
      }
    }
    @media(max-width:1279px) {
      max-width: 800px;
      padding-top: 35px;
      .model-comments_title{
        b{
          min-width: 75px;
        }
      }
      .model-comments_info{
        .item{
          min-width: 70px;
          padding-right: 14px;
          font-size: 14px;
        }
      }
      .comments-list{
        max-height: 200px;
      }
    }
    .rs-modal-header {
      padding: 0;
      border: none;
      margin-bottom: 10px;
    }
    .rs-modal-body {
      padding: 0 24px 0;
    }
    .rs-input:valid {
      border: 1px solid #325f97;
    }
    .rs-input textarea{
      height: 56px!important;
    }
    .btn-close {
      display: block;
      margin: 20px 30px 0 auto;
      width: 18px;
      padding: 0;
      height: 18px;
      transition-duration: 100ms;
      &:hover {
        opacity: 0.6;
      }
      @media(max-width:1279px) {
        margin: 5px 10px 0 auto;
      }
      .rs-icon{
        color:#1C243C;
        font-size: calcRem(18px);
      }
    }
    .state.state-team {
      font-size: calcRem(12px);
      padding: 4px 8px;
    }
    .model-comments_title {
      display: flex;
      font-size: calcRem(16px);
      color:#1C243C;
      margin-bottom: 24px;
      font-weight: 400;
      b{
        min-width: 104px;
      }
    }
    .input-wrap {
      margin-bottom: 20px;
    }
    .comments-list{
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 280px;
    }
    .form-checkbox {
      font-size: calcRem(14px);
      color: #1c243c;
      margin-right: 32px;
      label {
        position: relative;
        padding-left: 30px;
        line-height: 18px;
        display: inline-block;
        cursor: pointer;
        &::before {
          content: '';
          position: absolute;
          width: 18px;
          height: 18px;
          left: 0;
          top: -1px;
          background-color: #fff;
          border: 1px solid rgba(#242f50, 0.5);
          border-radius: 3px;
          background-repeat: no-repeat;
        }
      }
    }
    .btn-list {
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 20px;
      .btn-edit {
        margin-right: 20px;
      }
      .rs-btn {
        font-weight: 600;
        min-width: calcRem(166px);
      }
      .rs-btn-model{
        min-width: 130px;
        &.btn-close{
          margin: 0;
          height: auto;
          padding: 6px 16px;
        }
      }
      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
    .textarea {
      margin-top: 40px;
      .rs-form-control-wrapper{
        display: block;
        & > .rs-input{
          width: 100%;
          height: 70px;
        }
      }
    }
    .model-comments_info{
      display: flex;
      @media(min-width:2400px){
        a.rs-btn-link, .rs-btn-link{
          font-size: 16px;
        }
      }
      .item{
        margin-right: 8px;
        min-width: 110px;
        color:#1C243C;
        font-weight: 400;
        padding-right: 25px;
      }
      .title {
        font-size: calcRem(16px);
        font-weight: 700;
        margin-bottom: 8px;
        color:#1C243C;
      }
    }
  }

  &.patient-model {
    top: 20px;
    padding-bottom: 30px;
    .rs-form {
      width: 100%;
    }
    .rs-modal-footer {
      padding: 0 24px 24px;
      .rs-btn-toolbar {
        justify-content: space-between;
      }
    }
    .rs-form-group .rs-form-control-wrapper {
      display: block;
    }
    .input-wrap {
      margin-bottom: 54px;
      .rs-form-error-message-wrapper .rs-form-error-message {
        top: 102%;
        color: #c2002b;
        font-size: 14px;
      }
    }
    .rs-picker-toggle-wrapper {
      display: block;
      .rs-picker-select {
        width: 100% !important;
      }
      .rs-picker-toggle {
        background: #fefefe;
        width: 100%;
        border-radius: 4px;
        padding-right: 35px;
        border-color: #dddfe4;
        height: 56px;
        line-height: 40px;
        @media(min-width:$macBookPro){
          height: 64px;
          line-height: 50px;
        }
        .rs-btn .rs-picker-toggle-value,
        .rs-picker-toggle-value {
          color: #040508;
        }
        .rs-picker-toggle-placeholder {
          font-size: 16px;
        }
        .rs-picker-toggle-caret {
          top: 50%;
          transform: translate(0, -50%);
          background: none;
          width: 20px;
          height: 20px;
          right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: rgba(75, 82, 94, 0.5);
        }
      }
    }
    .rs-modal-header {
      border: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 32px 24px 64px;
      // .btn-close {
      //   width: 24px;
      //   padding: 0;
      //   line-height: 24px;
      //   height: 24px;
      //   opacity: 0.5;
      //   background: none;
      //   color: #1c243c;
      //   font-size: 16px;
      //   position: relative;
      //   transition: opacity 0.24s ease-in;
      //   .rs-icon {
      //     font-weight: 500;
      //     color: #242f50;
      //     font-size: 18px;
      //   }
      // }
    }
    .rs-form-group.anim-placeholder.show input.rs-input {
      border-color: #dddfe4;
    }
    .rs-form-group.anim-placeholder.focus input.rs-input {
      border: 1px solid #dddfe4;
    }
    .rs-form-control-label.title {
      font-size: 16px;
      color: #7c8295;
      font-weight: 500;
    }
    .input-wrap {
      max-width: 50%;
      flex: 0 0 50%;
      padding: 0 8px;
      margin: 0 0 34px;
      &.birthdate{
        .error{
          margin-bottom: 40px;
          .rs-error-message{
            height: auto;
            max-width: 250px;
          }
        }
      }
      .rs-control-label {
        font-size: 16px;
        transform: translateY(-12px);
        opacity: 1 !important;
        color: #7c8295;
        font-weight: 500;
        cursor: pointer;
        top: -19px;
        margin: 0;
        left: 0px;
        height: 24px;
      }
      .error {
        .rs-form-control-label {
          color: #c2002b;
        }
      }
    }
    .row {
      margin: 0 -8px;
      flex: 0 0 auto;
      max-width: none;
      .input-wrap {
        &.mri,
        &.zip-code {
          max-width: 180px;
        }
        &.zip-code {
          margin-bottom: 40px;
        }
        &.state {
          margin-bottom: 40px;
          flex: 1;
          max-width: none;
          .rs-btn {
            background: #fefefe;
            width: 100%;
            border-radius: 4px;
            padding-right: 35px;
            border-color: #dddfe4;
          }
        }
        input {
          width: 100%;
          display: block;
        }
        & + .input-wrap {
          margin-left: 0 !important;
        }
      }
    }
    .rs-modal-body {
      max-height: none !important;
      padding: 0 24px;
      display: flex;
      flex-wrap: wrap;
      .rs-form-group {
        width: 100%;
      }
      .rs-form-control-wrapper {
        display: block !important;
      }
      .rs-radio label {
        font-size: 16px;
        color: $blackBlue;
      }
      .rs-radio-checker {
        padding-left: 40px;
      }
      .rs-radio-wrapper {
        width: 20px;
        height: 20px;
        &::before {
          border: 1px solid #babbbe;
          width: 20px;
          background: #f6f7f8;
          height: 20px;
        }
        .rs-radio-inner {
          &::before {
            width: 20px;
            height: 20px;
          }
          &::after {
            width: 8px;
            height: 8px;
            background: #242f4f;
            margin-top: 6px;
            margin-left: 6px;
          }
        }
      }
      .rs-radio-checker:hover .rs-radio-wrapper .rs-radio-inner::before {
        border: 1px solid #babbbe;
      }
      .rs-radio-checked {
        .rs-radio-wrapper .rs-radio-inner::before {
          background: #edeef1;
          transform: scale(1);
          border: 1px solid #babbbe;
        }
        .rs-radio-wrapper [type='radio']:focus ~ .rs-radio-inner::before {
          box-shadow: none;
        }
      }
      .radio-group-controller{
        margin-bottom: 56px;
        &--error{
          margin-bottom: 56px;
          .rs-form-control-wrapper > .rs-radio-group{
            margin-bottom: 0;
          }
        }
        .rs-form-control-wrapper > .rs-radio-group{
          margin-bottom: 0;
        }
      }
      .rs-form-control-wrapper > .rs-radio-group {
        margin-bottom: 56px;
        .text-error {
          display: none;
        }
        &.error {
          .text-error {
            display: block;
            flex: 0 0 100%;
            font-size: 14px;
            line-height: 1.4;
            color: #c2002b;
          }
          .rs-radio-inner::before {
            background: #f9e6ea !important;
            border: 1px solid #c2002b !important;
          }
          .rs-radio label {
            color: #c2002b;
          }
        }
        .rs-radio-group {
          margin-right: 16px;
        }
        .rs-form-control-label {
          font-size: 16px;
          opacity: 1 !important;
          color: #7c8295;
          margin-bottom: 10px;
          font-weight: 500;
          cursor: pointer;
        }
      }
    }
  }

  &.invite-user-model {
    top: 20px;
    padding-bottom: 30px;
    .rs-form {
      width: 100%;
    }
    .rs-modal-footer {
      padding: 0 24px 24px;
      .rs-btn-toolbar {
        justify-content: space-between;
      }
    }
    .rs-form-group .rs-form-control-wrapper {
      display: block;
    }
    .input-wrap {
      margin-bottom: 54px;
      .rs-form-error-message-wrapper .rs-form-error-message {
        top: 102%;
        color: #c2002b;
        font-size: 14px;
      }
    }
    .rs-picker-toggle-wrapper {
      display: block;
      .rs-picker-select {
        width: 100% !important;
      }
      .rs-picker-toggle {
        background: #fefefe;
        width: 100%;
        border-radius: 4px;
        padding-right: 35px;
        border-color: #dddfe4;
        height: 56px;
        line-height: 40px;
        @media(min-width:$macBookPro){
          height: 64px;
          line-height: 50px;
        }
        .rs-btn .rs-picker-toggle-value,
        .rs-picker-toggle-value {
          color: #040508;
        }
        .rs-picker-toggle-placeholder {
          font-size: 16px;
        }
        .rs-picker-toggle-caret {
          top: 50%;
          transform: translate(0, -50%);
          background: none;
          width: 20px;
          height: 20px;
          right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: rgba(75, 82, 94, 0.5);
        }
      }
    }
    .rs-modal-header {
      border: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 32px 24px 64px;
    }
    .rs-form-group.anim-placeholder.show input.rs-input {
      border-color: #dddfe4;
      @include placeholder {
        color: #1c243c;
        opacity: 0.25 !important;
      }
    }
    .rs-form-group.anim-placeholder.error input.rs-input {
      border-color: rgb(194, 0, 43);
    }
    .rs-form-group.anim-placeholder.focus input.rs-input {
      border: 1px solid #dddfe4;
    }
    .rs-form-group.anim-placeholder.focus.error label {
      opacity: 1 !important;
    }
    .rs-form-control-label.title {
      font-size: 16px;
      color: #7c8295;
      font-weight: 500;
    }
    .input-wrap {
      max-width: calc(60% - 8px);
      flex: 0 0 60%;
      margin: 0 0 34px;
      input {
        width: 100%;
        display: block;
      }
      &.role {
        flex: 0 0 40%;
        max-width: calc(40% - 8px);
        .rs-btn {
          background: #fefefe;
          width: 100%;
          border-radius: 4px;
          padding-right: 35px;
          border-color: #dddfe4;
        }
      }
      .rs-control-label {
        font-size: 16px;
        transform: translateY(-12px);
        opacity: 1 !important;
        color: #7c8295;
        font-weight: 500;
        cursor: pointer;
        top: -19px;
        margin: 0;
        left: 0px;
        height: 24px;
      }
      .error {
        .rs-form-control-label {
          color: #c2002b;
        }
      }
    }
    .row {
      margin: 0;
      flex: 0 0 auto;
      max-width: none;
    }
  }

  .rs-form .row .input-wrap + .input-wrap {
    margin-left: 8px;
  }
  .rs-form-group.zip-code .rs-input,
  .rs-form-group.state .rs-input,
  .rs-form-group.role .rs-input,
  .rs-form-group.email .rs-input,
  .rs-form-group.mri .rs-input {
    width: 100%;
  }
  .rs-form .zip-code,
  .location-model.rs-modal .rs-form .state,
  .location-model.rs-modal .rs-form .role,
  .location-model.rs-modal .rs-form .email,
  .location-model.rs-modal .rs-form .zip-code {
    max-width: calcRem(112px);
  }
  .state .rs-form-control-wrapper > .rs-input,
  .zip-code .rs-form-control-wrapper > .rs-input {
    width: 100%;
  }
  &-sm.file-viewer {
    width: 90%;
  }
  
  &.file-viewer {
    top: 0;
  }

  &.filter-modal {
    .rs-modal-header {
      display: flex;
      align-items: center;
      padding-top: 32px;
      padding-bottom: 20px;
      border: none !important;
    }
    .rs-modal-footer {
      display: flex;
      justify-content: space-between;
      padding-bottom: 48px;
      &::after {
        display: none;
      }
      &::before {
        display: none;
      }
      // .btn-model {
      //   min-width: calcRem(162px);
      //   text-align: center;
      //   padding: 0;
      //   font-size: calcRem(16px);
      //   border: 1px solid #242f50;
      //   &.btn-close {
      //     color: #040508;
      //     background: #fefefe;
      //     font-weight: 500;
      //     border: 1px solid #babbbe;
      //     &:hover {
      //       color: #fefefe;
      //       background: #242f50;
      //       border-color: #242f50;
      //     }
      //   }
      // }
    }
    .rs-modal-header, .rs-modal-footer, .rs-modal-body {
      padding-left: 24px;
      padding-right: 24px;
    }
    .rs-modal-title {
      line-height: 50px;
    }
    form {
      position: relative;
    }
    .row:not(:last-child) {
      .title {
        font-size: calcRem(12px);
      }
    }
    .title {
      color: #1c243c;
      font-size: calcRem(16px);
      margin-bottom: 40px;
      font-weight: 600;
      display: block;
      i {
        font-weight: 400;
        font-style: normal;
      }
    }
    .btn-close {
      width: calcRem(24px);
      padding: 0;
      line-height: calcRem(24px);
      height: calcRem(24px);
      .rs-icon {
        font-weight: 700;
        color: #242f50;
        font-size: calcRem(20px);
        opacity: 0.5;
      }
    }
    .btn-reset {
      padding: 0;
      position: absolute;
      left: 0;
      bottom: -5px;
      color: #325f97;
      margin: 0;
      font-weight: 500;
      font-size: calcRem(16px);
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
      .rs-icon {
        width: calcRem(24px);
        height: calcRem(24px);
        text-align: center;
        line-height: calcRem(24px);
        margin-right: 8px;
        font-size: calcRem(16px);
        font-weight: 700;
        display: inline-block;
        margin-top: -1px;
        vertical-align: middle;
      }
    }
  }
}

.manage-physicians-model,.edit-profile-modal{
  .rs-form-control-wrapper > .rs-input,
  .department .rs-picker-toggle.rs-btn,
  .input-wrap{
    width: calcRem(379px);
  }
}