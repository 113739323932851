.content-new-scan {
  // background: #fcfdfe;
  // padding: 16px;
  // margin-top: 24px;
  // border-radius: $brs;

  h1 {
    padding: 8px;
    font-weight: $fontMedium;
    font-size: 24px;
    line-height: calc(34 / 24);
    margin-bottom: 16px;
  }

  .rs-form-group .radio-group-controller {
    margin-bottom: 10px;
    .rs-form-control-label {
      font-size: calcRem(16px);
      opacity: 1 !important;
      color: #7c8295;
      font-weight: $fontMedium;
      cursor: pointer;
      @media (max-width: 1379px) {
        font-size: 14px;
      }
    }
    &--error {
      .rs-form-control-label {
        font-size: calcRem(16px);
        opacity: 1 !important;
        color: $error;
        font-weight: $fontMedium;
        cursor: pointer;
        @media (max-width: 1379px) {
          font-size: 14px;
        }
      }
    }
  }

  .rs-form-group.anim-placeholder {
    .rs-form-control-label {
      font-size: calcRem(16px);
      transform: translateY(-12px);
      opacity: 1 !important;
      color: #7c8295;
      font-weight: $fontMedium;
      cursor: pointer;
      top: -19px;
      margin: 0;
      left: 0px;
      height: 24px;
      @media (max-width: 1379px) {
        font-size: 14px;
      }
    }
    &.show {
      input.rs-input {
        border: 1px solid #dddfe4;
      }
    }
    &.error {
      .rs-form-control-label {
        color: #c2002b;
      }
      input.rs-input {
        border: 1px solid #c2002b;
        color: #c2002b;
      }
    }
  }
  .rs-form-group.anim-placeholder.focus:not(.error) input.rs-input,
  .rs-form-group.anim-placeholder.focus:not(.error) textarea,
  .rs-form-group.anim-placeholder:focus:not(.error) input.rs-input,
  .rs-form-group.anim-placeholder:focus:not(.error) textarea {
    border: 1px solid #dddfe4;
  }

  .set-times {
    .scan-link {
      position: absolute;
      right: 0;
      color: #325f97;
      text-transform: capitalize;
      bottom: 100%;
      transform: translateY(100%);
      font-weight: 500;
      .rs-icon-calendar {
        left: -26px;
        width: 24px;
        font-size: 0;
        height: 24px;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='2' y='4' width='20' height='16' rx='2' fill='%23325F97'/%3E%3Cpath d='M3 10H21' stroke='white' stroke-width='2' stroke-linecap='square'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 2C8.55228 2 9 2.44772 9 3L9 5C9 5.55228 8.55228 6 8 6C7.44772 6 7 5.55228 7 5L7 3C7 2.44772 7.44772 2 8 2Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 2C8.55228 2 9 2.44772 9 3L9 4L7 4L7 3C7 2.44772 7.44772 2 8 2Z' fill='%23325F97'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16 2C16.5523 2 17 2.44772 17 3L17 5C17 5.55228 16.5523 6 16 6C15.4477 6 15 5.55228 15 5L15 3C15 2.44772 15.4477 2 16 2Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16 2C16.5523 2 17 2.44772 17 3L17 4L15 4L15 3C15 2.44772 15.4477 2 16 2Z' fill='%23325F97'/%3E%3C/svg%3E%0A");
      }
    }
    .row {
      flex: 0 0 auto;
    }
    &_item {
      color: $blackBlue;
      padding: 8px;
      background: #edeef1;
      border-radius: 4px;
      font-size: calcRem(14px);
      .times {
        margin-left: 8px;
        opacity: 0.5;
      }
      & + .set-times_item {
        margin-left: 8px;
      }
    }
  }
}

.form-new-scan {
  background: #fefefe;
  border-radius: 8px;
  margin-bottom: 24px;
  padding: 40px 34px 24px;
  position: relative;
  &-patientInfo {
    .form-new-scan-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .rs-form-group {
        margin-right: 16px;
        max-width: calcRem(420px);
        @media(max-width:859px){
          max-width: 360px;
        }
        @media(max-width:767px){
          max-width: 100%;
          margin-bottom: 40px;
        }
      }
      .form-new-scan-lang {
        max-width: 280px;
        flex: 1;
        margin-right: auto;
      }
      .form-new-scan-group{
        margin-bottom: 0 !important;
        @media(max-width:767px){
          &:nth-child(2){
            .rs-form-group {
              margin-bottom: 0!important;
            }
          }
        }
      }
    }
  }
  &:first-of-type {
    h2 {
      display: flex;
      margin-bottom: 54px;
      justify-content: space-between;

      b {
        padding-right: 30px;
      }
    }
  }

  &:last-of-type {
    margin-bottom: 24px;
  }

  &.new-scan-prescription {
    h2 {
      justify-content: flex-start;
    }
    .rs-btn {
      transition: background $trs ease;
      will-change: background;
      font-weight: 500;
      line-height: 1.375;
      padding: 8px 20px 8px 20px;
      text-transform: capitalize;
      box-shadow: 0 0 8px rgba(35, 44, 81, 0.15);
      color: #fcfdfe;
      background: $darkBlue;
      border-radius: 20px;
      min-height: 46px;

      .icon-receipts {
        width: 24px;
        height: 24px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 7px;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.21799 3.09202C4 3.51984 4 4.0799 4 5.2V22L6 20L8 22L10 20L12 22L13 21V18.6C13 14.9549 15.9549 12 19.6 12C19.7348 12 19.8683 12.0061 20 12.0179V5.2C20 4.0799 20 3.51984 19.782 3.09202C19.5903 2.71569 19.2843 2.40973 18.908 2.21799C18.4802 2 17.9201 2 16.8 2H7.2C6.0799 2 5.51984 2 5.09202 2.21799C4.71569 2.40973 4.40973 2.71569 4.21799 3.09202ZM17 8H7V6H17V8ZM7 12H17V10H7V12ZM13 16H7V14H13V16Z' fill='%23FEFEFE'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15 18.2C15 15.7979 17.101 14 19.5 14C21.899 14 24 15.7979 24 18.2V20H17.0107C17.1237 21.0551 18.1198 22 19.5 22C20.3529 22 21.2254 21.7058 21.7403 21H23.9467V21.0364C23.1708 23.3166 21.025 24 19.5 24C17.101 24 15 22.2021 15 19.8V18.2ZM17.0107 18H21.9893C21.8763 16.9449 20.8802 16 19.5 16C18.1198 16 17.1237 16.9449 17.0107 18Z' fill='%23FEFEFE'/%3E%3C/svg%3E%0A");
        background-position: center;
      }

      &:hover {
        background: $lightBlue;
        color: #fefefe;
      }
    }
  }

  .form-container {
    max-width: 1024px;
    margin: 0 auto;
    width: 100%;
    @media(min-width:$macBookPro){
      max-width: 1180px;
    }
  }

  .red-text {
    color: $red;
  }

  .sub-text {
    font-size: calcRem(16px);
    color: $blackBlue;
    margin-bottom: 32px;
  }

  &-group {
    flex: 1 1 auto;
    position: relative;

    .rs-picker-has-value {
      .rs-btn .rs-picker-toggle-value,
      .rs-picker-toggle .rs-picker-toggle-value {
        color: #040508;
      }
    }

    &.width {
      &-xl {
        width: calc(100% - 32px);

        @media (max-width: $desktop) {
          min-width: calc(100% - 16px);
        }
      }

      &-l {
        max-width: 33.3333%;
        flex: 0 0 33.3333%;
      }

      &-m {
        max-width: 20%;
        flex: 0 0 20%;
        @media (max-width: 1079px) {
          flex: 0 0 26%;
          max-width: 26%;
        }
      }

      &-s {
        width: calc(12% - 16px);
        min-width: 112px;

        @media (min-width: $desktop) {
          width: calc(12% - 32px);
        }
      }
    }
    &-zip.width-m {
      max-width: 244px;
      flex: 0 0 244px;
      margin-bottom: 32px !important;
    }
    &.rs-form-group {
      margin: 0 0 54px;
      .input-form-text {
        padding-top: 6px;
        line-height: 1.4;
        font-size: 14px;
        color: rgba($blackBlue, 0.5);
      }
      .rs-input {
        font-size: calcRem(16px);
        line-height: calc(22 / 16);
        color: $blackBlue;
        height: 46px;
        width: 100%;
        background: #fefefe;
        border-color: #dddfe4;

        @media (min-width: $desktop) {
          height: 56px;
        }

        @media (min-width: $macBookPro) {
          height: 64px;
        }
        + label {
          width: calc(100% - 50px);
          overflow: hidden;
        }
      }

      .rs-form-error-message-wrapper .rs-form-error-message {
        top: 105%;
        color: #c2002b;
        font-size: 14px;
      }

      textarea {
        &.rs-input {
          resize: none;
          height: 80px;
          @media(min-width:2400px){
            height: 120px;
          }
        }
      }

      .rs-form-control-wrapper {
        width: 100%;
      }

      .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
        display: none;
      }

      .rs-picker-toggle-wrapper {
        width: 100%;

        .rs-picker-toggle {
          height: 46px;
          background: #fefefe !important;
          width: auto;
          border-radius: $brs;
          display: flex;
          align-items: center;
          padding-right: 55px;
          border-color: #dddfe4;

          .rs-picker-toggle-caret {
            top: 50%;
            transform: translate(0, -50%);
            background: none;
            width: calcRem(20px);
            height: calcRem(20px);
            right: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgba(#4b525e, 0.5);
          }

          .rs-picker-toggle-placeholder {
            overflow: hidden;
            font: 400 calcRem(16px)/1.5 'Poppins', sans-serif;
            color: $blackBlue;
          }

          @media (min-width: $desktop) {
            height: 56px;
          }
          @media (min-width: $macBookPro) {
            height: 64px;
          }
        }
      }
    }
  }

  &-stripe {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    color: $baseColor;
    width: 100%;
    align-items: flex-end;

    iframe {
      height: 20px !important;
      transform: translate(0, 10px) !important;

      @media (min-width: $desktop) {
        transform: translate(0, 17px) !important;
      }
    }

    .rs-form-control-label {
      font-size: 16px;
      opacity: 1;
      color: #7c8295;
      margin-bottom: 5px;
      font-weight: 500;
      cursor: pointer;
    }

    > .form-new-scan-group {
      @media (max-width: $desktop) {
        min-width: calc(100% - 16px) !important;
      }

      @media (min-width: $desktop) {
        margin-bottom: 0 !important;
      }

      .form-new-scan-group-fields {
        background-color: $white;
      }
    }
  }

  &-block {
    display: flex;
    flex-wrap: wrap;
    .rs-form-group {
      width: 100%;
    }
    .rs-form-control-wrapper {
      display: block !important;
    }
    .rs-radio label {
      font-size: calcRem(16px);
      color: $blackBlue;
    }
    .rs-radio-checker {
      padding-left: calcRem(40px);
    }
    .rs-radio-wrapper {
      width: calcRem(20px);
      height: calcRem(20px);
      &::before {
        border: 1px solid #babbbe;
        width: calcRem(20px);
        background: #f6f7f8;
        height: calcRem(20px);
      }
      .rs-radio-inner {
        &::before {
          width: calcRem(20px);
          height: calcRem(20px);
        }
        &::after {
          width: 8px;
          height: 8px;
          background: #242f4f;
          margin-top: 6px;
          margin-left: 6px;
          @media(min-width:$macBookPro){
            width: 13px;
            height: 13px;
          }
        }
      }
    }
    .rs-radio-checker:hover .rs-radio-wrapper .rs-radio-inner::before {
      border: 1px solid #babbbe;
    }
    .rs-radio-checked {
      .rs-radio-wrapper .rs-radio-inner::before {
        background: #edeef1;
        transform: scale(1);
        border: 1px solid #babbbe;
      }
      .rs-radio-wrapper [type='radio']:focus ~ .rs-radio-inner::before {
        box-shadow: none;
      }
    }
    .rs-radio-group {
      .text-error {
        display: none;
      }
      &.error {
        .text-error {
          display: block;
          flex: 0 0 100%;
          font-size: 14px;
          line-height: 1.4;
          color: #c2002b;
        }
        .rs-radio-inner::before {
          background: #f9e6ea !important;
          border: 1px solid #c2002b !important;
        }
        .rs-radio label {
          color: #c2002b;
        }
      }
      .rs-radio-group {
        margin-right: 16px;
      }
      .rs-form-control-label {
        font-size: 16px;
        opacity: 1 !important;
        color: #7c8295;
        margin-bottom: 10px;
        font-weight: 500;
        cursor: pointer;
      }
    }

    &.scan--information {
      flex-direction: column;
    }

    &.scan-patient-preferences .form-new-scan-group {
      margin-bottom: 16px;
    }

    &.scan--preferences {
      padding: 62px 24px 34px 24px;
      z-index: 1;
      border: 1px solid #dddfe4;
      border-radius: 4px;
      .width-xl {
        margin-bottom: 0 !important;
        flex: 1;
        margin-right: 16px;
        width: auto;
        max-width: none;
        @media(max-width:1024px){
          min-width: max-content;
          margin-right: 0;
        }
      }
      .width-m {
        margin-bottom: 0 !important;
        max-width: 244px;
        flex: 0 0 224px;
        @media(max-width:1024px){
          flex:0 0 100%;
          margin-top: 60px;
          max-width: 100%;
        }
      }
      input {
        text-transform: capitalize;
      }
    }

    &.scan--prescription {
      .row.hideSomeChildren {
        flex-wrap: wrap;
        width: 100%;
        .form-new-scan-prescription {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
      .scan--prescription__text {
        display: block;
        font-size: 16px;
        line-height: calc(22 / 16);
        letter-spacing: 0.04em;
        color: rgba($baseColor, 0.5);
        text-align: center;
        padding: 0 40px;
        font-style: italic;

        @media (min-width: $desktop) {
          padding: 0 80px;
        }
      }

      &.invalid {
        .rs-uploader-trigger-btn {
          background-color: #ffe1e1;
        }

        .inner-wrapper {
          background-color: #ffe1e1;
        }
      }

      .form-new-scan-group {
        .inner-wrapper {
          padding: 0;

          button {
            margin: 0;
            width: 100%;

            &[disabled] {
              pointer-events: none;
            }
          }

          a {
            display: inline-block;
          }
        }
      }

      .form-new-scan-divider {
        margin-top: 12px;
      }

      .form-new-scan-uploader {
        border-top: none;
        input[type='file'] {
          display: none;
        }
        &.inactive {
          pointer-events: none;
          opacity: 0.5;
        }

        &.hideSomeChildren {
          width: 100%;
          opacity: 1;
          pointer-events: initial;

          .rs-uploader-trigger {
            display: none;
          }
        }

        button {
          width: 100%;
        }
      }

      > .error-text-new-scan {
        margin-top: 16px;
      }
    }

    &.scan--card {
      padding: 20px 24px 30px;
      z-index: 1;
      border: 1px solid #dddfe4;
      .rs-form-group {
        margin-bottom: 0;
      }
      &.invalid {
        &:before {
          background: #ffe1e1;
        }
      }

      .width-l {
        @media (min-width: $desktop) {
          width: calc(50% - 8px);
          flex: 0 0 calc(50% - 8px);
          max-width: none;
        }
      }
    }

    &-headings {
      width: 100%;
      padding-top: 10px;
      h3 {
        color: $blackBlue;
        font-size: 16px;
        line-height: 1.2;
        margin-bottom: 16px;
        padding-bottom: 16px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #dddfe4;
        b {
          font-weight: $fontBold;
          text-transform: uppercase;
        }

        span {
          font-weight: $fontNormal;
        }
      }

      p {
        margin-bottom: 16px;
        display: flex;
        justify-content: space-between;
        color: $blackBlue;
        font-size: 16px;
        line-height: calc(22 / 16);
        &:last-child {
          margin-bottom: 24px;
        }
        b {
          font-weight: $fontNormal;
        }
      }
    }
  }

  &-holder {
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    align-items: center;
    margin: 0 8px;

    @media (min-width: $desktop) {
      margin: 0 16px;
    }
  }

  &-inner-holder {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;

    .form-new-scan-uploader {
      .invalid {
        .rs-uploader-trigger-btn {
          background-color: #ffe1e1;
        }
      }

      .filled {
        .rs-uploader-trigger {
          pointer-events: none;
          cursor: none;
        }
      }
    }
  }
  &-loading{
    background: #fcfdfe;
    box-shadow: 0 0 8px rgba(35, 44, 81, 0.15);
    border-radius: $brs;
    text-align: center;
    padding: 5px 10px;
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 999;
    width: 100%;
  }
  &-list {
    background: #fcfdfe;
    box-shadow: 0 0 8px rgba(35, 44, 81, 0.15);
    border-radius: $brs;
    max-height: 625px;
    width: 100%;
    overflow-x: auto;
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 999;

    li {
      transition: background $trs ease;
      will-change: background;
      color: $baseColor;

      &:hover,
      &.active {
        background: #f2f6fe;
      }

      &.sticky {
        position: sticky;
        bottom: 0;
        z-index: 99;
        background: #fcfdfe;
        color: #325f97;

        &:hover {
          background: rgb(151, 173, 228);
        }

        button {
          justify-content: center;
          text-decoration: underline;
        }
      }

      button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 16px;
        font-size: calcRem(16px);
        line-height: calc(17/ 11);
        font-weight: 400;
        color: $blackBlue;
        span {
          opacity: 0.5;
        }
      }
    }
  }

  &-divider {
    text-transform: uppercase;
    padding: 0 18px;
    font-weight: $fontMedium;
    font-size: calcRem(16px);
    line-height: calc(28 / 20);
    color: #8c919d;
    margin-bottom: 12px;
    display: inline-block;
    vertical-align: middle;
  }

  &-uploader {
    text-align: center;
    width: 100%;
    margin: 0 0 24px;

    .rs-uploader-draggable .rs-uploader-trigger-customize .rs-uploader-trigger-btn {
      transition: background $trs ease;
      will-change: background;
      font-weight: $fontMedium;
      color: #fcfdfe;
      background: $darkBlue;
      border-radius: 20px;
      font-size: calcRem(16px);
      line-height: 1.375;
      padding: 11px 16px 11px 14px;
      text-transform: capitalize;
      box-shadow: 0 0 8px rgba(35, 44, 81, 0.15);

      .icon-upload {
        width: 24px;
        height: 24px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 4px;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4 5.2C4 4.0799 4 3.51984 4.21799 3.09202C4.40973 2.71569 4.71569 2.40973 5.09202 2.21799C5.51984 2 6.0799 2 7.2 2H15L20 7V18.8C20 19.9201 20 20.4802 19.782 20.908C19.5903 21.2843 19.2843 21.5903 18.908 21.782C18.4802 22 17.9201 22 16.8 22H7.2C6.0799 22 5.51984 22 5.09202 21.782C4.71569 21.5903 4.40973 21.2843 4.21799 20.908C4 20.4802 4 19.9201 4 18.8V5.2ZM11 7.48456C11.6461 6.83848 12.3539 6.83848 13 7.48456L17 11.4844L15.5 12.9844L13 10.4846L13 17H11V10.4846L8.48438 13.0002L6.98438 11.5002L11 7.48456Z' fill='%23FEFEFE'/%3E%3C/svg%3E%0A");
        background-position: center;
      }

      .icon-receipts {
        width: 24px;
        height: 24px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 4px;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.21799 3.09202C4 3.51984 4 4.0799 4 5.2V22L6 20L8 22L10 20L12 22L13 21V18.6C13 14.9549 15.9549 12 19.6 12C19.7348 12 19.8683 12.0061 20 12.0179V5.2C20 4.0799 20 3.51984 19.782 3.09202C19.5903 2.71569 19.2843 2.40973 18.908 2.21799C18.4802 2 17.9201 2 16.8 2H7.2C6.0799 2 5.51984 2 5.09202 2.21799C4.71569 2.40973 4.40973 2.71569 4.21799 3.09202ZM17 8H7V6H17V8ZM7 12H17V10H7V12ZM13 16H7V14H13V16Z' fill='%23FEFEFE'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15 18.2C15 15.7979 17.101 14 19.5 14C21.899 14 24 15.7979 24 18.2V20H17.0107C17.1237 21.0551 18.1198 22 19.5 22C20.3529 22 21.2254 21.7058 21.7403 21H23.9467V21.0364C23.1708 23.3166 21.025 24 19.5 24C17.101 24 15 22.2021 15 19.8V18.2ZM17.0107 18H21.9893C21.8763 16.9449 20.8802 16 19.5 16C18.1198 16 17.1237 16.9449 17.0107 18Z' fill='%23FEFEFE'/%3E%3C/svg%3E%0A");
        background-position: center;
      }

      &:hover {
        background: $lightBlue;
        color: #fefefe;
        border: 0;
      }
    }

    .rs-uploader-file-items {
      margin-top: 0;
    }

    .rs-uploader-file-item-size {
      display: none;
    }

    .rs-uploader-trigger-btn {
      padding: 15px;
      position: relative;
      font-size: 12px;
      line-height: calc(17/ 12);
      letter-spacing: $lts;
    }
  }

  .form-new-scan-group-fields {
    border: 1px solid #dddfe4;
    border-radius: $brs;
    color: $baseColor;
    padding: 0 11px;
  }

  h2 {
    color: $blackBlue;
    font-size: calcRem(20px);
    font-weight: $fontMedium;
    line-height: calc(22 / 16);
    border-radius: $brs;
    width: 100%;
    margin-bottom: 62px;
    b {
      font-weight: $fontMedium;
    }
  }

  .input-wrap {
    margin-bottom: 0;
  }

  .textarea {
    margin-top: 0;
  }

  .scan-link {
    text-decoration: underline;
    position: relative;
    color: #325f97;
    margin-left: 40px;
    font-size: calcRem(16px);
    cursor: pointer;
    @media (max-width: 1279px) {
      position: absolute;
      right: 20px;
    }
    @media (max-width: 1200px) {
      top:46px;
    }
    &:hover {
      text-decoration: none;
    }

    svg,i {
      position: absolute;
      left: -27px;
      top: 50%;
      font-size: 20px;
      transform: translate(0, -50%);
      &.rs-icon-plus {
        width: 24px;
        height: 24px;
        left: -34px;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13 4H11V11H4V13H11V20H13V13H20V11H13V4Z' fill='%23325F97'/%3E%3C/svg%3E%0A");
      }
      &.rs-icon-plus::before {
        font-size: 0;
      }
    }
  }

  &-owner {
    padding-bottom: 32px;
    .form-new-scan-block {
      .rs-form-group {
        width: auto;
      }
    }
    .form-container {
      border-bottom: 1px solid #dddfe4;
    }
    .rs-form-group {
      display: flex !important;
      align-items: center;
      margin-bottom: 0;
      &.anim-placeholder {
        padding-bottom: 32px;
        margin: 0 auto;
        &.error {
          .rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn {
            border: 1px solid #c2002b;
          }
          .rs-form-error-message-wrapper .rs-form-error-message {
            left: 128px;
            top: calc(100% - 28px);
          }
        }
        .rs-picker-select {
          min-width: 331px;
        }
        .rs-form-control-label {
          position: static;
          margin-right: 16px;
          transform: none !important;
        }
      }
    }
  }

  @media(max-width:767px){
    .rs-radio-group-inline{
      flex-wrap: wrap;
    }
    .rs-radio-inline{
      margin: 0 0 5px;
      flex:0 0 100%;
      max-width: 100%;
    }
  }
}

.scan-edit-modal {
  &.filter-modal{
    .rs-modal-footer{
      padding: 32px 24px;
    }
    .full-scan-type{
      margin-bottom: 42px;
    }
  }
  .anim-placeholder.show label, .anim-placeholder.focus label, .anim-placeholder input:focus + label, .anim-placeholder input:focus + * + label, .anim-placeholder input:not(:placeholder-shown) + label, .anim-placeholder input:not(:placeholder-shown) + * + label{
    top:-13px;
  }
  .form-new-scan-block:last-child{
    .form-new-scan-group{
      margin-bottom: 0;
    }
  }
  .form-new-scan{
    padding: 0;
  }
  @media (min-width: 1024px){
    .form-new-scan-group.rs-form-group .rs-picker-toggle-wrapper .rs-picker-toggle {
      height: 56px;
    }
  }
}

.scan-popup-confirmation {
  background: #ffffff;
  box-shadow: 0px 40px 40px -30px rgba(0, 0, 0, 0.2);
  top: 20px;
  max-width: 1024px;
  margin: 0 auto;

  .rs-modal-dialog {
    margin: 0;
  }

  &-content {
    &.content--preferencies {
      > ul {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        &.highlight {
          &:first-of-type {
            > li {
              &:first-child {
                width: 170px;

                @media (min-width: 1200px) {
                  width: 250px;
                }
              }

              &:last-child {
                width: calc(100% - 170px);

                @media (min-width: 1200px) {
                  width: calc(100% - 250px);
                }
              }
            }
          }
        }

        li {
          &:first-child {
            flex: none;
          }
        }

        .scan-popup-block {
          background: #f2f3f5;
          padding: 16px;
          width: 100%;

          ul {
            padding: 32px 0 0;
            display: flex;
            margin-bottom: 0;
            justify-content: space-between;
            flex-wrap: wrap;

            li {
              flex: none;
              margin-bottom: 8px;
              width: 100%;

              @media (min-width: 1200px) {
                width: calc(20% - 8px);
                margin-right: 8px;
                display: inline-block;
                vertical-align: middle;
              }

              &:last-child {
                margin-right: 0;
              }

              strong {
                font-weight: 700;
                color: $baseColor;
                font-size: 16px;
                line-height: calc(24/ 16);
                padding-right: 10px;

                @media (min-width: 1200px) {
                  padding-right: 0;
                  width: 100%;
                  display: block;
                }
              }

              span {
                display: block;
                padding-right: 5px;

                &:first-of-type {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }

  &-text {
    color: $blackBlue;
    font-size: 16px;
    margin-bottom: 16px;
    p + p {
      margin-top: 0;
    }
  }

  &-step-1 {
    &.inactive {
      display: none;
    }
  }

  &-step-2 {
    .scan-popup-confirmation-content {
      h2 {
        margin-bottom: 8px !important;
      }
    }
    &.inactive {
      display: none;
    }

    .rs-form-group {
      padding-top: 30px;

      @media (min-width: $desktop) {
        margin-left: auto;
        max-width: 50%;
      }
    }
  }

  &-holder {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 21px;

    .scan-popup-confirmation-area {
      @media (min-width: $desktop) {
        width: calc(50% - 115px);
      }

      &:only-child {
        width: 100%;
      }
    }
  }

  &-uploader {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 24px;
    &-content {
      padding: 0 8px;
      a {
        display: inline-block;
        vertical-align: middle;
        padding: 0;
        text-decoration: underline;
        color: #325f97;
        font-size: 16px;
        line-height: calc(22 / 16);
        font-weight: 500;
        &::before {
          content: '';
          width: 24px;
          height: 24px;
          background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.3412 5C14.7333 5 14.15 5.24193 13.7196 5.67325L6.15515 13.2533C5.41576 13.9943 5 14.9996 5 16.0483C5 17.097 5.41576 18.1024 6.15515 18.8433C6.89447 19.5842 7.89677 20 8.94144 20C9.98611 20 10.9884 19.5842 11.7277 18.8433L19.2922 11.2632C19.6823 10.8723 20.3154 10.8716 20.7064 11.2618C21.0973 11.6519 21.098 12.285 20.7078 12.676L13.1434 20.2561C12.0293 21.3724 10.5179 22 8.94144 22C7.365 22 5.85355 21.3724 4.73948 20.2561C3.62547 19.1398 3 17.6261 3 16.0483C3 14.4705 3.62547 12.9569 4.73948 11.8406L12.3039 4.2605C13.1091 3.45366 14.2016 3 15.3412 3C16.4808 3 17.5732 3.45366 18.3784 4.2605C19.1835 5.06727 19.6355 6.16106 19.6355 7.30111C19.6355 8.44117 19.1835 9.53496 18.3784 10.3417L10.8058 17.9218C10.8056 17.922 10.8059 17.9217 10.8058 17.9218C10.3095 18.4189 9.63576 18.6989 8.93321 18.6989C8.23047 18.6989 7.55694 18.4191 7.06066 17.9218C6.56446 17.4246 6.28606 16.7506 6.28606 16.0483C6.28606 15.346 6.56446 14.6721 7.06066 14.1748L14.0493 7.17994C14.4397 6.78925 15.0728 6.78896 15.4635 7.17931C15.8542 7.56966 15.8545 8.20283 15.4642 8.59353L8.47634 15.5876C8.47631 15.5876 8.47636 15.5876 8.47634 15.5876C8.35479 15.7094 8.28606 15.8752 8.28606 16.0483C8.28606 16.2215 8.35475 16.3872 8.47634 16.5091C8.59786 16.6308 8.76224 16.6989 8.93321 16.6989C9.10417 16.6989 9.26856 16.6308 9.39008 16.5091L16.9627 8.92898C16.9628 8.92895 16.9627 8.92901 16.9627 8.92898C17.3932 8.4976 17.6355 7.91203 17.6355 7.30111C17.6355 6.69015 17.3932 6.10463 16.9627 5.67325C16.5323 5.24193 15.949 5 15.3412 5Z' fill='%23A7ACB9'/%3E%3C/svg%3E%0A");
          display: inline-block;
          vertical-align: middle;
          margin-right: 8px;
        }
        &:hover {
          color: $baseColor;
        }
      }
    }

    strong {
      display: block;
      padding: 20px 10px;
      border-bottom: 8px solid #f2f6fe;
      font-size: 20px;
      line-height: 1.4;
      font-weight: 500;
    }
  }

  &-divider {
    text-align: center;
    color: #8c919d;
    font-size: 16px;
    line-height: calc(28 / 20);
    font-weight: $fontMedium;
    width: 100%;
  }

  .rs-btn-toolbar {
    padding: 42px 0 0;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .btn-model {
      min-width: 160px;
      font-size: 16px;
      &:hover {
        color: $white;
        background: $lightBlue;
        border: 1px solid #505972;
      }
      &.btn-white {
        color: $blackBlue;
        min-width: 160px;
        background: $white;
        border: 1px solid $darkBlue;
        &:hover {
          color: $white;
          background: $darkBlue;
        }
      }
    }
  }

  .rs-modal-header {
    padding: 24px;
    border-bottom: 0;
    position: relative;
    .btn-close {
      position: absolute;
      right: 24px;
      top: 26px;
      width: 24px;
      padding: 0;
      line-height: 24px;
      height: 24px;
      opacity: 0.5;
      background: none;
      color: #1c243c;
      font-size: 16px;
      transition: opacity 0.24s ease-in;
      span {
        font-weight: 500;
        color: #242f50;
        font-size: 18px;
      }
    }
    h2 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 32px;
      line-height: 1.4;
      color: #1c243c;
    }
    .rs-modal-title {
      color: $baseColor;
      font-size: 20px;
      line-height: calc(28 / 20);
      font-weight: $fontMedium;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      font-weight: $fontBold;
      font-size: 16px;
      line-height: calc(20 / 16);
      color: $lightGray;
      @media (min-width: 1023px) {
        font-size: 18px;
      }
      li {
        vertical-align: middle;
        position: relative;
        background-color: $darkBlue;
        flex: 0 0 auto;
        padding: 7.5px 15px 7.5px 38px;
        min-height: 38px;
        &:before,
        &:after {
          content: '';
          position: absolute;
          right: -11px;
          top: 50%;
          transform: translate(0, -50%);
          display: block;
          z-index: 2;
          border-top: 19px solid transparent;
          border-left: 11px solid $darkBlue;
          border-bottom: 19px solid transparent;
        }

        &:before {
          border-left-color: #fefefe;
          right: auto;
          left: 11px;
          border-left: 11px solid #fefefe;
          z-index: 0;
          border-top: 20px solid transparent;
          border-bottom: 20px solid transparent;
        }

        &:first-child {
          padding-left: 23px;
          &::before {
            content: '';
            background: #fefefe;
            height: 100%;
            left: auto;
            right: -11px;
            border: 0;
            width: 11px;
            position: absolute;
            z-index: 1;
          }
        }

        &.inactive {
          background-color: #bbbfca;
          color: #fefefe;

          &:after {
            border-left-color: #bbbfca;
          }
        }
        &.confirm {
          background-color: #244f33;
          color: #fefefe;

          &:after {
            border-left-color: #244f33;
          }
        }
      }
    }
  }

  .rs-modal-body {
    padding: 24px;
    h2 {
      color: $blackBlue;
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 20px;
      line-height: calc(22 / 16);

      + .scan-popup-confirmation-label {
        padding-top: 32px;
      }
    }

    ul {
      li {
        display: inline-flex;
        flex-direction: column;
        vertical-align: middle;
        padding: 0 0 16px;
        font-size: 16px;
        color: $blackBlue;
        width: 100%;
        @media (min-width: 859px) {
          flex: 1;
          width: 50%;
          word-break: break-word;
        }
        @media (min-width: 1279px) {
          flex: 1;
          width: 33.332%;
          word-break: break-word;
        }

        b {
          font-weight: 400;
          color: rgba($blackBlue, 0.5);
          margin-bottom: 7px;
        }
      }

      &:not(:first-child) {
        margin-bottom: 16px;
      }
    }
  }

  .rs-modal-footer{
    padding: 16px;
    .rs-btn-toolbar{
      padding-top: 0;
    }
  }

  .scan-popup-holder {
    + .scan-popup-confirmation-content {
      position: relative;
      padding: 20px 24px;
      z-index: 1;
      margin: 0;

      @media (min-width: $desktop) {
        padding: 41px 48px;
      }

      &:before {
        transition: background $trs ease;
        will-change: background;
        content: '';
        background: #fcfdfe;
        border-radius: $brs;
        border: 1px solid #f2f3f5;
        box-shadow: 0 0 10px 4px rgba(36, 47, 80, 0.03);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
      }

      ul {
        padding: 16px 16px 0;

        li {
          padding: 0 10px 0 0;
        }
      }
    }

    ul {
      padding: 16px 16px 0;

      li {
        padding: 0 10px 0 0;
      }
    }
  }

  &-prescription {
    margin: 0 0 32px;
    font-size: calcRem(16px);
    line-height: calc(23 / 16);
    font-weight: $fontNormal;

    a {
      transition: color $trs ease;
      will-change: color;
      padding: 0 8px;
      color: #325f97;
      display: inline-block;
      vertical-align: middle;
      font-weight: 500;
      border-radius: $brs;
      text-decoration: underline;
      position: relative;
      &::before {
        content: '';
        width: 24px;
        height: 24px;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.3412 5C14.7333 5 14.15 5.24193 13.7196 5.67325L6.15515 13.2533C5.41576 13.9943 5 14.9996 5 16.0483C5 17.097 5.41576 18.1024 6.15515 18.8433C6.89447 19.5842 7.89677 20 8.94144 20C9.98611 20 10.9884 19.5842 11.7277 18.8433L19.2922 11.2632C19.6823 10.8723 20.3154 10.8716 20.7064 11.2618C21.0973 11.6519 21.098 12.285 20.7078 12.676L13.1434 20.2561C12.0293 21.3724 10.5179 22 8.94144 22C7.365 22 5.85355 21.3724 4.73948 20.2561C3.62547 19.1398 3 17.6261 3 16.0483C3 14.4705 3.62547 12.9569 4.73948 11.8406L12.3039 4.2605C13.1091 3.45366 14.2016 3 15.3412 3C16.4808 3 17.5732 3.45366 18.3784 4.2605C19.1835 5.06727 19.6355 6.16106 19.6355 7.30111C19.6355 8.44117 19.1835 9.53496 18.3784 10.3417L10.8058 17.9218C10.8056 17.922 10.8059 17.9217 10.8058 17.9218C10.3095 18.4189 9.63576 18.6989 8.93321 18.6989C8.23047 18.6989 7.55694 18.4191 7.06066 17.9218C6.56446 17.4246 6.28606 16.7506 6.28606 16.0483C6.28606 15.346 6.56446 14.6721 7.06066 14.1748L14.0493 7.17994C14.4397 6.78925 15.0728 6.78896 15.4635 7.17931C15.8542 7.56966 15.8545 8.20283 15.4642 8.59353L8.47634 15.5876C8.47631 15.5876 8.47636 15.5876 8.47634 15.5876C8.35479 15.7094 8.28606 15.8752 8.28606 16.0483C8.28606 16.2215 8.35475 16.3872 8.47634 16.5091C8.59786 16.6308 8.76224 16.6989 8.93321 16.6989C9.10417 16.6989 9.26856 16.6308 9.39008 16.5091L16.9627 8.92898C16.9628 8.92895 16.9627 8.92901 16.9627 8.92898C17.3932 8.4976 17.6355 7.91203 17.6355 7.30111C17.6355 6.69015 17.3932 6.10463 16.9627 5.67325C16.5323 5.24193 15.949 5 15.3412 5Z' fill='%23A7ACB9'/%3E%3C/svg%3E%0A");
        display: inline-block;
        vertical-align: middle;
        margin-right: 8px;
      }
      &:hover {
        color: $baseColor;
      }
    }
  }

  &-label {
    color: rgba($blackBlue, 0.5);
    margin-bottom: 7px;
    display: block;

    b {
      font-weight: 400;
    }
  }

  &-area {
    color: $blackBlue;
    max-height: 160px;
    overflow-y: auto;
    margin-bottom: 32px;
  }

  &-headings {
    width: 100%;
    padding: 0 8px 16px;

    @media (min-width: $desktop) {
      padding: 16px;
    }

    h3 {
      color: $darkBlue;
      font-size: 20px;
      line-height: calc(28 / 20);
      margin-bottom: 25px;

      @media (min-width: $desktop) {
        margin-bottom: 50px;
      }

      b {
        font-weight: $fontBold;
      }

      span {
        font-weight: $fontNormal;
      }
    }

    p {
      margin-bottom: 8px;
      color: $baseColor;
      font-size: 16px;
      line-height: calc(22 / 16);

      &:last-child {
        margin-bottom: 24px;
      }

      b {
        font-weight: $fontBold;
      }
    }
  }
}

.scan-popup-patient {
  .rs-modal-title {
    font-size: 20px;
    font-weight: $fontMedium;
    line-height: calc(28 / 20);
  }

  .rs-modal-body {
    svg {
      font-size: 12px;
      font-weight: $fontBold;
      line-height: calc(17/ 12);
      display: block;
      margin-bottom: 40px;
    }

    .form-new-scan-group {
      max-width: 396px;
      width: 100%;
      margin-bottom: 40px;
    }
  }
}

.scan-popup-submitted {
  border-radius: 2px 2px 0 0;
  overflow: hidden;
  text-align: center;

  .rs-modal-content {
    padding-bottom: 36px;
  }

  .rs-modal-header {
    padding: 13px;
    background: $green;
    margin-bottom: 11px;

    .rs-modal-title {
      color: $white;
      font-size: 20px;
      line-height: calc(28 / 20);
      font-weight: $fontMedium;
      letter-spacing: $lts;
    }
  }

  .rs-modal-body {
    color: $baseColor;
    font-size: 16px;
    line-height: calc(23 / 16);
    letter-spacing: $lts;
    margin-bottom: 30px;
    padding: 1px 66px;

    svg {
      display: block;
      text-align: center;
      font-size: 42px;
      margin: 10px auto 19px;
      color: $green;
      letter-spacing: $lts;
    }

    p {
      margin-bottom: 40px;
      text-align: left;

      b {
        font-weight: $fontMedium;
      }
    }

    strong {
      display: block;
      font-weight: $fontMedium;
      font-size: 24px;
      line-height: calc(34 / 24);
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
        font-weight: $fontBold;
        font-size: 16px;
        line-height: calc(23 / 16);
        letter-spacing: $lts;
      }

      a {
        padding-left: 7px;
      }
    }

    a {
      color: $darkBlue;
      text-decoration: underline;

      &:hover {
        color: $black;
      }
    }
  }

  .rs-modal-footer {
    text-align: center;
    padding: 0 16px;
  }
}

.scan--prescription {
  & .tooltip {
    margin: 0 10px 0 0;
  }
  .form-new-scan-inner-holder {
    .row {
      align-items: center;
    }
  }
  .title {
    font-weight: 600;
    font-size: calcRem(16px);
    line-height: 1.2;
    white-space: nowrap;
    letter-spacing: -0.01em;
    text-transform: uppercase;
    margin-right: 16px;
    color: #7c8295;
  }

  .form-new-scan-prescription,
  .form-new-scan-uploader {
    margin-bottom: 0 !important;
  }
}

.datepicker-holder {
  transition: opacity $trs ease, visibility $trs ease, z-index $trs ease;
  will-change: opacity, visibility, z-index;
  position: fixed;
  background-color: rgba($black, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  overflow-y: auto;
  overflow-x: hidden;
  &.active {
    z-index: 9999;
    opacity: 1;
    visibility: visible;
  }
}

.datepicker {
  max-width: 426px;
  font-size: 16px;
  width: 100%;
  line-height: calc(20 / 16);
  position: absolute;
  z-index: 999;
  top: 30px;
  padding: 16px;
  left: 50%;
  transform: translate(-50%, 0%);
  background: #fefefe;
  box-shadow: 0px 8px 40px -8px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  margin-bottom: 20px;

  &__btn {
    position: absolute;
    right: -13px;
    top: -13px;
    width: 25px;
    height: 25px;

    &:before,
    &:after {
      transition: border-color $trs ease;
      content: '';
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(135deg);
      position: absolute;
      border: 1px solid $lightGray;
      height: 100%;
    }

    &:after {
      transform: translate(-50%, -50%) rotate(-135deg);
    }

    &:hover {
      &:before,
      &:after {
        border-color: $darkBlue;
      }
    }
  }

  &__header {
    background-color: #fc8ba3;
    padding: 15px 15px 20px;
    color: $white;
    margin-bottom: 5px;
    text-align: center;
  }

  &__content {
    position: relative;
    padding: 24px 10px 20px 24px;
    color: gray;
    font-weight: 300;
    z-index: 9;
    border-top: 1px solid #e9eaed;
    margin: 10px -16px 0;
    h3 {
      color: #040508;
      font-weight: 600;
      font-size: 16px;
      line-height: 1.2;
      margin-bottom: 16px;
    }
    em {
      color: rgba($blackBlue, 0.5);
      font-size: 16px;
    }

    > ul {
      max-height: 198px;
      overflow-y: auto;
      padding-right: 8px;

      > li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        & + li {
          margin-top: 12px;
        }
        > span {
          width: 52px;
          display: block;
          text-align: left;
          padding: 5px 0;
          font-size: 14px;
          overflow: hidden;
          white-space: nowrap;
          line-height: 1.4;
          color: $blackBlue;
        }

        > ul {
          display: flex;
          li {
            display: inline-block;
            vertical-align: middle;
            margin: 0 3px;
            white-space: nowrap;
            &:last-child {
              margin-right: 0;
            }
            button {
              border: 1px solid #babbbe;
              border-radius: 4px;
              padding: 4px 8px;
              min-width: 100px;
              text-align: center;
              font-size: 14px;
              line-height: 1.3;
              color: $blackBlue;
              text-transform: uppercase;

              &:hover,
              &.active {
                border: 1px solid #d7e0f3;
                background: #242F4F;
                color:#fff;
              }
            }
          }
        }
      }
      @media (max-height: 820px) {
        max-height: 160px;
      }
      @media (max-height: 700px) {
        max-height: 120px;
      }
    }
  }

  &__footer {
    padding: 0px;
    span {
      color: rgba($blackBlue, 0.5);
      display: block;
      font-size: 16px;
      text-align: left;
      font-style: italic;
      padding-left: 7px;
      margin-bottom: 24px;
    }

    button {
      transition: background $trs ease;
      will-change: background;
      display: block;
      color: $white;
      border-radius: 50px;
      padding: 17px 20px;
      font-weight: 600;
      background: #242f4f;
      border: 1px solid #242f4f;
      width: 100%;
      & + button {
        margin-top: 16px;
      }
      &.inActive {
        background-color: #bbbfca;
      }
      &.btn-white {
        color: $blackBlue;
        background: none;
        border: 2px solid #babbbe;
        &:hover {
          color: $white;
          background: #505972;
          border: 2px solid #505972;
        }
      }

      &:hover {
        color: $white;
        background: #505972;
        border: 1px solid #505972;
      }
    }
  }
}

.rs-modal-body {
  max-height: 70vh !important;
}

input[readonly] {
  color: rgba($baseColor, 0.5);
}

.rs-form-control-wrapper.anim-placeholder.textarea textarea:placeholder-shown + label {
  width: calc(100% - 35px);
  white-space: normal;
  top: 13px !important;
  transform: none !important;
}

.StripeElement {
  & > div {
    display: flex !important;
    align-items: center;
    height: 40px;

    @media (min-width: $desktop) {
      height: 52px;
    }
  }
}

.form-btns {
  background: #fefefe;
  padding: 48px 30px;
  border-radius: 0 0 $brs $brs;
  overflow: hidden;
  max-width: 1084px;
  margin: 0 auto;
  .form-container {
    max-width: 1024px;
    margin: 0 auto;
  }
  .form-new-scan-block-btns {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex: 1 1 auto;
    margin-left: 0 !important;

    @media (min-width: $desktop) {
      width: auto;
    }
  }

  .rs-btn-toolbar {
    justify-content: flex-start;
    flex-wrap: wrap;
    & > :not(:first-child):not(.rs-btn-block) {
      margin-left: 16px;
      @media (max-width: 1090px) {
        margin-left: 0;
        margin-top: 20px;
      }
    }
    .rs-btn-model {
      &.btn-white, &.btn-cleerly {
        margin-left: auto;
        @media (max-width: 1090px) {
          margin-left: 0;
        }
      }
      @media (max-width: 1090px) {
        & + .btn-model {
          margin: 15px 0 0 0;
        }
      }
    }
  }

  @media (max-width: 1090px) {
    padding: 20px 0;
    flex-direction: column;
    .rs-btn-toolbar {
      flex-direction: column;
    }
  }

  @media (max-width: 996px) {
    .btn-model:last-child {
      margin: 12px 0 0;
      flex: 0 0 100%;
    }
  }
}
.full-scan-type {
  flex-wrap: nowrap;
  margin: 0 -8px 54px;
  .form-new-scan-group {
    margin: 0 !important;
    padding: 0 8px;
    @media(max-width:767px){
      &.width-l{
        flex:0 0 100%;
        max-width: 100%;
        &:last-child{
          margin-bottom: 0!important;
        }
      }
    }
  }
  &.vertical {
    flex-wrap: wrap;
    padding-left: 10px;
    padding-right: 10px;
    .form-new-scan-group {
      flex: 0 0 100%;
      margin: 0 0 30px !important;
    }
  }
  @media (max-width: 1079px) {
    flex-wrap: wrap;
    &.full-scan-mob {
      .form-new-scan-group.width-l {
        flex: 0 0 66%;
        max-width: 66%;
        & + .form-new-scan-group.width-l {
          margin-top: 46px !important;
        }
      }
    }
  }
}

.full-scan-type-fileds {
  .form-new-scan-group {
    @media(max-width:767px){
      &.width-l{
        margin-bottom: 40px!important;
        &:last-child{
          margin-bottom: 0!important;
        }
      }
    }
  }
}

.full-row {
  flex-wrap: nowrap;
  width: 100%;
  display: flex;
  justify-content: space-between;
  .form-new-scan-group {
    margin: 0 !important;
    flex: 0 0 calc(33% - 15px);
    max-width: calc(33% - 15px);
  }
  @media (max-width: 1023px) {
    margin: 0 -16px 25px;
  }
}

.check-sticky-footer {
  padding: 10px 2px;
  border-top: 1px solid #e5e5e5;
}

.datepicker {
  .nice-dates-navigation_previous,
  .nice-dates-navigation_next {
    height: 48px;
    width: 48px;
    padding-bottom: 0;
    &:before {
      border-right: 2px solid $blackBlue;
      border-top: 2px solid $blackBlue;
      width: 10px;
      height: 10px;
    }
  }
  .nice-dates-navigation_current {
    flex: none;
    margin: 0 50px;
    font-weight: 700;
    text-align: center;
    color: $blackBlue;
  }

  .nice-dates-navigation {
    color: $blackBlue;
    justify-content: space-between;
  }

  [class*='-today']:not([class*='-selected']) {
    position: relative;
    border: none;
    color: #1c243c;
    border-radius: 50%;
  }

  .nice-dates-grid {
    height: 240px !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .nice-dates-day_month {
    display: none;
  }

  .nice-dates-week-header {
    margin: 0;
    padding: 6px 0;
    box-shadow: none;
    &_day {
      text-transform: uppercase;
      color: $blackBlue;
      font-size: 14px;
    }
  }

  .nice-dates-day {
    color: $blackBlue;
    width: 36px;
    height: 36px !important;
    margin: 2px 10px;
    border: none;
    &.-today {
      border: 1px solid #96a3bc;
    }
    &.-disabled {
      opacity: 0.5;
      cursor: default;
      color: $blackBlue;
      pointer-events: none;
    }
    &.-outside {
      opacity: 0.25;
    }
    &.-disabled * {
      color: $blackBlue;
    }
    &.-selected:hover:after {
      opacity: 0;
      color:#fff;
    }
    &:hover {
      &::after {
        background: #f2f5fb;
        color:#fff;
      }
    }
    &::after {
      pointer-events: none;
      background: transparent;
      position: absolute;
      background: #f2f5fb;
      content: '';
      left: -8px;
      right: -8px;
      top: -2px;
      bottom: -2px;
      border-radius: 0;
      border: 0;
    }

    &[class*='-selected'] {
      .nice-dates-day_date {
        color: #fff;
      }
      &:before {
        color: #fff;
        background: #242F4F;
      }
    }
  }
}

.form-new-scan-group-fields_stripe {
  display: flex;
  align-items: center;
  height: 54px;
  .StripeElement {
    flex: 1;
    > div {
      height: auto;
    }
  }
}

.rs-uploader-text .rs-uploader-file-item {
  background-color: transparent;
  border: none;
  padding: 25px 0;
  margin: 0;
  cursor: text !important;
  & + .rs-uploader-file-item {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 5px;
  }
  &:hover {
    background: none;
  }
}

.content-new-scan .rs-uploader-file-item-btn-remove,
.form-new-scan-uploader .rs-uploader-file-item-btn-remove {
  display: none;
}

.rs-uploader-file-item-content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  padding-right: 50px;

  .preview {
    background: #e9eaed;
    border: 1px solid rgba(0, 0, 0, 0.2);
    width: 64px;
    height: 82px;
    flex: 0 0 64px;
    text-align: center;
    margin-right: 25px;
    overflow: hidden;
    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &.epx {
    margin-top: 24px;
    align-items: center;
    @media(max-width:860px){
      .uploader-delete-btn{
        white-space: nowrap;
      }
    }
  }

  > span {
    text-align: left;
    font-size: calcRem(16px);
    line-height: calc(22 / 16);
    color: #575757;

    &:nth-child(2) {
      cursor: pointer;
      color: #325f97;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }

    &:not(:first-child) {
      text-decoration: underline;
    }
  }

  > button.uploader-delete-btn,
  > a.uploader-delete-btn {
    color: #325f97;
    margin-left: auto;
    font-size: calcRem(16px);
    line-height: calc(22 / 16);
    text-transform: capitalize;
    margin-right: 20px;
    text-decoration: underline;
    .rs-icon {
      font-size: calcRem(20px);
      display: inline-block;
      margin-right: calcRem(6px);
      vertical-align: top;
    }
    &:hover {
      text-decoration: none;
      color: $red;
    }
  }

  .dots {
    position: absolute;
    right: 0;
    top: 52px;
    z-index: 999;

    &__list {
      width: 293px;
      background: #fcfdfe;
      box-shadow: 0 4px 24px -4px rgba($baseColor, 0.2);
      border-radius: $brs;

      li {
        font-size: calcRem(12px);
        line-height: calc(17/ 12);
        text-transform: capitalize;
        text-align: left;

        &:not(:last-child) {
          border: 1px solid #f2f3f5;
        }

        a {
          transition: background $trs ease;
          will-change: background;
          padding: 16px;
          color: $baseColor;
          display: block;
          width: 100%;
          font-size: calcRem(12px);
          line-height: calc(17/ 12);

          &:hover {
            background: $lightGray;
          }
        }
      }
    }

    &__text {
      display: block;
      transition: color $trs ease;
      will-change: color;
      position: absolute;
      top: -39px;
      right: 0;
      color: $darkBlue;
      letter-spacing: 0.1em;
      font-size: 40px;
      writing-mode: vertical-lr;
      width: 50px;
      height: 50px;
      line-height: 1.85;
      cursor: pointer;

      &:hover {
        color: lighten($darkBlue, 25%);
      }
    }
  }
}

.error-border {
  border: 1px solid $error;
  border-radius: 4px;
  & .rs-btn.rs-btn-default.rs-picker-toggle {
    border: none;
  }
}

.error-text-new-scan {
  font: 400 calcRem(14px)/1.5 'Poppins', sans-serif;
  line-height: 19px;
  color: $error;
}

.error-text-label {
  color: $error !important;

  @media (max-width: 1379px) {
    font-size: 14px !important;
  }
}

.form-new-scan-stripe {
  & .rs-form-control-label {
    @media (max-width: 1379px) {
      font-size: 14px;
    }
  }

  & .rs-form-error-message-wrapper {
    bottom: auto;
    .rs-form-error-message-arrow{
      &::before,
      &::after{
        display: none;
      }
    }
    .rs-form-error-message{
      box-shadow: none;
      padding: 0;
      border:0;
      filter: none;
      background: none;
    }

    & > .rs-form-error-message {
      position: initial !important;
    }
  }
}


.scan-info-table{
  background: #FFFFFF;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
  border-radius: 0px 0px 4px 4px;
  .rs-panel-body{
    padding: 0;
    border-radius: 0;
    .table-component{
      margin: 0;
      max-width: none;
      .rs-table{
        min-height: 94px!important;
      }
    }
  }
  .rs-table-row{
    .rs-table-cell-content{
      font-size: calcRem(14px);
    }
    .rs-table-cell-first{
      .rs-table-cell-content{
        padding-left: 16px;
      }
    }
  }
  .rs-table-cell-header{
    .rs-table-cell{
      background: #F2F2F4;
      &-content{
        color:#181919;
        font-size: calcRem(14px);
        font-weight: 500;
      }
    }
  }
}
