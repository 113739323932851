.bg{
    background: #F2F6FE;
}
.rs-panel{
    position: relative;
}
.tabs-panel .rs-nav-tabs{
    display: flex;
    justify-content: flex-start;
    border-radius: 0 8px 0 0;
    overflow: hidden;
}
.tabs-panel .rs-nav-tabs .rs-nav-item {
    max-width: 33.333%;
    text-align: center;
    padding: 27px 10px;
    height: 100%;
    line-height: 1;
    font-weight: 500;
    overflow: hidden;
    justify-content: center;
    display: flex;
    flex:1;
    align-items: center;
    font-size: calcRem(20px);
    border-radius: 8px 8px 0 0;
    border:6px solid #F2F3F5;
    border-width: 5px 5px 0 0;
    color: rgba(#8C919D,0.5);
    background: #FCFDFE;
}
.tabs-panel .rs-table{
    margin: 0 -20px;
}

.rs-nav .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle,.rs-nav .rs-nav-item-active > .rs-nav-item-content,
.rs-nav .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:hover,
 .rs-nav .rs-nav-item-active > .rs-nav-item-content:hover, .rs-nav .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:focus, .rs-nav .rs-nav-item-active > .rs-nav-item-content:focus, .rs-nav .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:active, .rs-nav .rs-nav-item-active > .rs-nav-item-content:active,
.tabs-panel .rs-nav-tabs .rs-nav-item.rs-nav-item-active{
    color: #1C243C;
    font-weight: 600;
    background: #FCFDFE;
}
.tab-content .rs-panel{
    box-shadow: none;
}
.tab-content  .rs-panel-header {
    min-height: 91px;
}
.rs-nav-tabs .rs-nav-item.rs-nav-item-active{
    border:4px solid #325F97!important;
    border-width: 5px 5px 0 0!important;
    display: flex;
    background: #FCFDFE;
}
.rs-nav-tabs.rs-nav-horizontal .rs-nav-waterline{
    display: none;
}

@media(max-width:1500px){

    .tabs-panel .rs-nav-tabs .rs-nav-item{
        font-size: 18px;
    }
}
@media(max-width:1340px){

    .tabs-panel .rs-nav-tabs .rs-nav-item{
        font-size: 16px;
    }
}
@media(max-width:1150px){

    .tabs-panel .rs-nav-tabs{
        .rs-nav-item{
            font-size: 14px;
            min-width: 175px;
        }
    }
    .page-content-main > .tabs-panel .rs-nav-tabs{
        overflow-x: scroll;
        max-width: calc(100vw - 204px);
        &::-webkit-scrollbar {
            height: 5px;
            background: rgba(140, 145, 157, 0.3);
        }
    }
}
@media(max-width:1023px){
    .page-content-main > .tabs-panel .rs-nav-tabs{
        max-width: calc(100vw - 159px);
    }
}